import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import styled from 'styled-components';

//svg

// Styled components
import {
  OffWhiteSection,
  Container,
  HeadingTeal,
  HeadingTealSmall,
  ParagraphBlack,

} from "../../StyledComponents";

//import styled components
import {
  VideoContainer,
  PlayButton,
  Slide,
  SliderWrapper,
  SlideTitle,
  SlideSubtitle,
  SliderTrack,
  NavButton,
} from "./IwsEducationStyles";


//Buttons import 
import { PriButton } from "../../../../component/Buttons/Buttons";



// IwsEducationStyles.js
export const Section = styled.section`
  background: #FFF5F0;  // Light peachy background
  flex: 1;
  padding: 0.75rem 3rem;
  min-height: 100vh;
`;



interface SliderTrackProps {
  $currentIndex: number;
}

interface NavButtonProps {
  $position: 'left' | 'right';
}


// IwsEducation.tsx
const IwsEducation = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    {
      title: "Dakshina Sharma",
      subtitle: "Student",
      videoUrl: "#"
    },
    {
      title: "Ashika Shrestha",
      subtitle: "Student",
      videoUrl: "#"
    },
    {
      title: "Purnika Silwal",
      subtitle: "Student",
      videoUrl: "#"
    },
  ];

  return (
    <Section>
      <Container>
        <HeadingTealSmall>IWS EDUCATIONAL VIDEO</HeadingTealSmall>
        <HeadingTeal>IMPERIAL EDUCATIONAL VIDEOS</HeadingTeal>
        <ParagraphBlack>
          Imperial World School is committed to providing innovative and engaging experiential learning.
        </ParagraphBlack>
        <SliderWrapper>
          <SliderTrack $currentIndex={currentIndex}>
            {slides.map((slide, index) => (
              <Slide key={index}>
                <VideoContainer>
                  <PlayButton>
                    <svg xmlns="http://www.w3.org/2000/svg" width="66.622" height="66.622" viewBox="0 0 66.622 66.622">
                      <g id="Group_415" data-name="Group 415" transform="translate(-289.689 -7073.811)">
                        <g id="Group_233" data-name="Group 233" transform="translate(289.689 7073.811)">
                          <path id="Path_71" data-name="Path 71" d="M33.311,63.291a29.98,29.98,0,1,0-29.98-29.98A29.98,29.98,0,0,0,33.311,63.291Zm0,3.331A33.311,33.311,0,1,0,0,33.311,33.311,33.311,0,0,0,33.311,66.622Z" fill="#fff" fill-rule="evenodd" />
                        </g>
                        <g id="Group_234" data-name="Group 234" transform="translate(313.007 7092.707)">
                          <path id="Path_72" data-name="Path 72" d="M84.982,68.17,69.663,57.958V78.382Zm3.095-1.94a2.332,2.332,0,0,1,0,3.88L69.957,82.19a2.332,2.332,0,0,1-3.625-1.94V56.09a2.332,2.332,0,0,1,3.625-1.94Z" transform="translate(-66.332 -53.755)" fill="#fff" fill-rule="evenodd" />
                        </g>
                      </g>
                    </svg>

                  </PlayButton>
                </VideoContainer>
                <SlideTitle>{slide.title}</SlideTitle>
                <SlideSubtitle>{slide.subtitle}</SlideSubtitle>
              </Slide>
            ))}
          </SliderTrack>

          <NavButton
            onClick={() => setCurrentIndex(prev => prev === 0 ? slides.length - 1 : prev - 1)}
            $position="left"
          >
            <FaChevronLeft />
          </NavButton>

          <NavButton
            onClick={() => setCurrentIndex(prev => prev === slides.length - 1 ? 0 : prev + 1)}
            $position="right"
          >
            <FaChevronRight />
          </NavButton>
        </SliderWrapper>
        <PriButton>WATCH MORE</PriButton>
      </Container>
    </Section>
  );
};

export default IwsEducation;
