import React from 'react';

//styles components
import {
  PrimaryButton,
  SecondaryButton,
  TealWhiteButton,
  MonocromaticButtons,
  DarkTealButtons, 
} from './ButtonsStyles';

// Type definitions
type ButtonProps = {
  children: React.ReactNode;
  href?: string; // Use href for links, optional since not every button needs it
};



export const PriButton = ({ children, href }: ButtonProps) => {
  return href ? (
    <a href={href}>
      <PrimaryButton>{children}</PrimaryButton>
    </a>
  ) : (
    <PrimaryButton>{children}</PrimaryButton>
  );
};

export const SecButton = ({ children, href }: ButtonProps) => {
  return href ? (
    <a href={href}>
      <SecondaryButton>{children}</SecondaryButton>
    </a>
  ) : (
    <SecondaryButton>{children}</SecondaryButton>
  );
};

export const TWButton = ({ children, href }: ButtonProps) => {
  return href ? (
    <a href={href}>
      <TealWhiteButton>{children}</TealWhiteButton>
    </a>
  ) : (
    <TealWhiteButton>{children}</TealWhiteButton>
  );
};

// #F9F0E7
export const MonocromaticButton = ({ children, href }: ButtonProps) => {
  return href ? (
    <a href={href}>
      <MonocromaticButtons>{children}</MonocromaticButtons>
    </a>
  ) : (
    <MonocromaticButtons>{children}</MonocromaticButtons>
  );
};

export const DarkTealButton = ({ children, href }: ButtonProps) => {
  return href ? (
    <a href={href}>
      <DarkTealButtons>{children}</DarkTealButtons>
    </a>
  ) : (
    <DarkTealButtons>{children}</DarkTealButtons>
  );
};