
//styled components
import styled from 'styled-components';


//style components css
export const Section = styled.section`
  flex: 1;
  padding: 0.75rem 3rem;
  min-height: 100vh;
  background-color: white;
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 0rem;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  align-items: center;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const ContentWrapper = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Heading = styled.h1`
  font-size: 2.25rem;
  font-family: 'Luxenta', sans-serif;
  font-weight: bold;
  color: #2B7C85;
  line-height: 1.2;

  @media (min-width: 768px) {
    font-size: 3rem;
  }

  @media (min-width: 1024px) {
    font-size: 3.75rem;
  }
`;

export const HighlightedSpan = styled.span`
  color:#3BBAAC;
  font-style: normal;
`;

export const UnderlinedSpan = styled.span`
  text-decoration: underline;
`;

export const Description = styled.p`
  color: #4b5563;
  max-width: 28rem;
  font-family: 'Manrope', sans-serif;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  button{
    display: flex;
    align-items: center;
  }
`;

export const IconWrapper = styled.span`
  display: inline-flex;
  padding-right: 0.5rem;
  padding:0 0%.5;
  align-items: center;
`;

export const ImageContainer = styled.div`
  position: relative;
  z-index: 0;
  
  @media (min-width: 768px) {
    height: 650px;
  }
`;

export const BackgroundSVG = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
`;

export const StudentImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
  z-index: 10;
`;


//Aboutus 

export const AboutUsSection = styled(Section)`
  background-color: #FFFCF2;
  display:flex;
  align-items:center;
  min-height:auto;
`;

export const HeadingTealSmall = styled.h1`
  color: #2B7C85;
  font-size: 18px;
  line-height: 45px;
  font-family: 'luxenta', sans-serif;
  font-weight: bold;
`;

export const LineBreak = styled.span`
margin:1rem 0;
display:block;
`

export const HeadingTeal = styled.h1`
  color: #2B7C85;
  font-size: 40px;
  line-height: 1.25;
  font-family: 'luxenta', sans-serif;
  font-weight: bold;
text-transform: uppercase;
  margin-bottom: 1rem;
`;

export const AboutUsParagraphBlack = styled.p`
  color: #080808;
  font-size: 17.5px;
  text-align: center;
  font-family: 'Manrope', sans-serif;
  max-width: 50rem;
  margin: 1rem auto;
`;

//student safety section
export const OffWhiteSection = styled(Section)`
  background-color: #FFFCF2;
  display:flex;
  align-items:center;
  min-height: 100vh;
  width:100%;
`;

export const ParagraphBlack = styled.p`
  color: #080808;
  font-size: 17.5px;
  text-align: center;
  font-family: 'Manrope', sans-serif;
  margin: 1rem auto 1rem auto;
  padding:0 3rem;
`;

export const YouTubeVideo = styled.p`
  position:relative;
  border-radius:0.5rem;
  overflow:hidden;
  border:4px solid #F8CB3D;
  width:100%;
  margin:2rem 0;

  iframe{
  width:100%;
  height:37.5rem;
  }
`;


//Global Citizen Education
export const TealSection = styled(Section)`
  background-color: #2B7C85;
  display:flex;
  align-items:center;
`;


export const HeadingWhiteSmall = styled.h1`
  color: #FFFFFF;
  font-size: 18px;
  font-family: 'luxenta', sans-serif;
  font-weight: bold;
`;

export const HeadingWhite = styled.h1`
  color: #FFFFFF;
  font-size: 40px;
  padding:0.5rem 0;
  font-family: 'luxenta', sans-serif;
  font-weight: bold;
  line-height:1.2;
  text-transform:uppercase;
`;


export const ParagraphWhite = styled.p`
  font-size: 17.5px;
  color: #FFFFFF;
  text-align: left;
  padding:1rem 0;
  font-family: 'Manrope', sans-serif;
`;


export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between; /* Space between the text and image */
  align-items: center;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items vertically on small screens */
  }
`;

export const LeftSection = styled.div`
  flex: 1.2; /* Take up proportional space */
  text-align: left;
  padding: 1rem 0;
`;


export const ImageWrapper = styled.div`
  flex: 2; /* Image takes up more space than text */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;


//International Curricula

export const HeadingManrope = styled.h1`
color: white;
  font-size: 1.6rem;
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
  text-align:center;
`

export const CardGrid = styled.div`
  display: grid;
  gap: 1.5rem;
  padding:1rem 0;
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Card = styled.div`
  position: relative;
  overflow: hidden;
  height: 500px;
  display: flex;
  flex-direction: column;
  border-radius:1rem;
`;

export const CardImage = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  position: relative;
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4), transparent);
    z-index: 1;
  }
`;

export const CardContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  z-index: 2;
  color: white;
`;


//Our team section styles
export const PhotoWrapper = styled.div`
  position: flex;
  width: 100%;
  padding: 1rem 0;
  flex-direction: row;
`;

export const SliderTrack = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  transition: transform 500ms ease-out;
`;

export const Slide = styled.div`
  flex:1;  // Makes each slide take up roughly 1/3 of the container
  text-align: center;
`;

export const PhotoContainer = styled.div`
  position: relative; /* Ensures that the ::before pseudo-element is positioned relative to this container */
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: transform 0.2s;
  overflow: hidden; /* Prevents child elements or ::before from overflowing the container */

  &::before {
    content: "";
    position: absolute;
    inset: 0; /* Fills the container completely */
   background: transparent linear-gradient(0deg, #F8CB3D 0%, #FFFFFF22 100%) 0% 0% no-repeat padding-box;
    z-index: 1; /* Ensures gradient overlay is above the image */
    opacity: 0.39;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative; /* Ensures image respects container's bounds */
    z-index: 0; /* Ensures the image is below the gradient */
  }

  &:hover {
    transform: scale(1.02); /* Adds a subtle scaling effect on hover */
  }
`;



export const SlideTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  text-align:left;
  margin-bottom: 0.5rem;
  font-family: 'Manrope', sans-serif;

`;

export const SlideSubtitle = styled.p`
  color: #666;
  text-align:left;
  font-family: 'Manrope', sans-serif;
  font-size: 1rem;
`;



//News and Event Sections
export const YellowSection = styled(Section)`
  background-color: #F8CB3C;
  display:flex;
  align-items:center;

`;

export const NewsEventWrapper = styled.div`
  display:flex;
  align-items:center;
  gap:1.5rem;
  padding:2rem 0;
  margin-bottom:2rem;
  flex-direction:row;

  @media (max-width: 768px) {
    flex-direction:column;
  
  }
`;

export const NewsHeadingContainer = styled.div`
 display:flex;
 justify-content:space-between;
`;



export const NewsCard = styled.div`
  width:38rem;
  background-color: white;
  border-radius: 0.5rem;
  padding:2rem 1.5rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  
`;

export const NewsCardContent = styled.div`
margin-bottom:2rem;
`;

export const CardTitle = styled.h5`
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  color: #080808;
  text-align:left;
  font-family: 'Manrope', sans-serif;
`;

export const CardDate = styled.p`
text-align:left;
  font-family: 'Manrope', sans-serif;
  margin-bottom: 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: #767676;
`;


export const NewsCardImage = styled.img`
  width: 100%;
  border-radius: 0.5rem;
  object-fit: contain;
`;

//Schedule a tour
export const ScheduleFlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
  gap: 2rem; /* Spacing between the sections */
  justify-content: space-between;
  align-items: center; /* Align items vertically */
`;

export const ScheduleLeftSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ScheduleImageWrapper = styled.div`
  width: 100%;
  max-width: 500px; /* Optional: Limit the image size */
  border-radius: 0.5rem;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 0.5rem;
  }
`;

export const RightSection = styled.div`
  flex: 1 1 50%; /* Takes 50% width of the container */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 2rem;
  background-color: #2B7C85; /* Dark background for contrast */
  border-radius: 0.5rem;
`;

export const Form = styled.form`
  margin: 2rem 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Spacing between form elements */

  input {
    padding: 0.75rem;
    border-radius: 0.375rem;
    border: 1px solid #ddd;
    font-size: 1rem;
    flex: 1; /* Ensures inputs take up equal width inside span */

    &:focus {
      outline: none;
      border-color: #1d4ed8;
      box-shadow: 0 0 0 2px rgba(29, 78, 216, 0.5);
    }
    textarea{
      font-family: inherit; 
       resize: none;
    }
  }
textarea{
 border-radius: 0.375rem;
    padding: 0.75rem;
    height:6.5rem;
    font-size: 1rem;
    resize: none; /* Prevents resizing for a cleaner design */

    &:focus {
      outline: none;
    }
  }
  span {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column; /* Stack inputs vertically on smaller screens */
      gap: 1rem; /* Adjust spacing for stacked inputs */
    }
  }

  button {
    padding: 0.75rem;
    border-radius: 0.375rem;
    color: black  ;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
`;

export const ScheduleHeadingManrope = styled.h1`
color: white;
  font-size: 1.7rem;
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
  text-align:left;
`

