

import styled from 'styled-components';
// Define interfaces for custom props

  interface NavButtonProps {
    $position: 'left' | 'right';
  }

// Styles for the component
export const Section = styled.section`
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 50%, #F8CB3C 100%);
  padding: 4rem 0;
  position: relative;
`;

export const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export const SliderTrack = styled.div`
  display: flex;
  transition: transform 500ms ease-out;
  gap: 2rem;
  
`;

export const Slide = styled.div`
  width: 50%;
  flex-shrink: 0;
  padding:2rem 1rem;
`;

export const ImageContainer = styled.div`
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: 441px;
  object-fit: cover;
`;

export const SlideTitle = styled.h1`
  font-family: 'Manrope', sans-serif;
  text-align: left;
  font-weight: bold;
  font-size: 25px;
  padding: 1.25rem 0;
`;


export const NavButton = styled.button<NavButtonProps>`
  position: block;
  transform: translateY(-100%);
  ${props => props.$position === 'left' ? 'left: 1rem;' : 'right: 1rem;'}
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0.3rem;
  margin:3rem 0.3rem 0rem 0.3rem;
  justify-content:space-between;
  border-radius: 9999px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;

  &:hover {
    background-color: white;
  }

  svg {
    height: 1.5rem;
    width: 1.5rem;
    color: #1F2937;
  }
`;
