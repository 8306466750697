import React from 'react'
import BannerComponent from '../../../component/Banner/Banner'
import IPBanner from '../../Assets/Photo/IPCurriculumBanner.jpg'
import { CurriculaSection, TextWrapper } from '../CurriculaStyles';
import { Container, HeadingTeal, HeadingTealSmall, OffWhiteSection } from '../../../global/global';
import { FlexWrapper, HeadingBlack, HeadingManrope, ImageWrapper, LeftSection, ParagraphBlack, RightSection } from '../../AboutUs/AboutusStyles';
import { ImperialBGSection, OffWhitePart, ImageWrapperIPC, SplitSection, WhitePart, FlexWrapperIPC, Capabilities } from './IPCurriculumStyles';
import { HeadingWhite, LineBreak } from '../../Homepage/StyledComponents';

import IPC1 from '../../../pages/Assets/Photo/IPC1.png' //banner photo
import IPC2 from '../../../pages/Assets/Photo/IPC2.png' //banner photo


const IPCurriculum = () => {
    //banner components BreadCrumbs
    const breadcrumbs = [
        { label: "Home", link: "/" },
        { label: "International Primary Curriculum" }
    ];
    const CheckBox = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g id="Group_312" data-name="Group 312" transform="translate(-400 -5219)">
                    <g id="Group_308" data-name="Group 308">
                        <rect id="Rectangle_493" data-name="Rectangle 493" width="24" height="24" rx="5" transform="translate(400 5219)" fill="#F8CB3C" />
                        <path id="check" d="M3.96,8.258A.554.554,0,0,1,3.567,8.1l-3.4-3.4a.556.556,0,0,1,.786-.786L3.96,6.917,10.375.5a.556.556,0,0,1,.786.786L4.353,8.1A.554.554,0,0,1,3.96,8.258Zm0,0" transform="translate(406.339 5226.701)" fill="#2B7C85" />
                    </g>
                </g>
            </svg>
        )
    }
    return (
        <>
            <BannerComponent
                image={IPBanner}
                title="International Primary Curriculum"
                breadcrumbs={breadcrumbs}
            />

            <CurriculaSection>
                <Container>
                    <HeadingTealSmall>CURRICULUM</HeadingTealSmall>
                    <HeadingTeal>International Primary Curriculum</HeadingTeal>
                    <ParagraphBlack>The IPC is now the curriculum of choice for over 1000 national and international schools spanning across more than 90 countries.</ParagraphBlack>
                </Container>
            </CurriculaSection>

            <ImperialBGSection>
                <Container>
                    <FlexWrapper>
                        {/* Left Section */}

                        <RightSection>
                            <HeadingWhite>The IPC is one of the fastest-growing curricula in the world today</HeadingWhite>


                            <ParagraphBlack style={{ color: "white", fontWeight: "bold " }}>This curriculum is a comprehensive, theme-based, creative curriculum that teaches 5 to 12-year-olds how to think with an international mindset.</ParagraphBlack>
                            <ParagraphBlack style={{ color: "white" }}>With a clear process of learning and specific learning goals for each subject, the IPC allows students to develop a personal sense of responsibility for their own learning journey.</ParagraphBlack>


                        </RightSection>

                        <LeftSection>
                            <ImageWrapper>
                                <img src={IPC1} alt="A girl reading a book" />
                            </ImageWrapper>
                        </LeftSection>
                    </FlexWrapper>

                </Container>
            </ImperialBGSection>

            <OffWhitePart>
                <Container>
                    <HeadingTeal>What makes the IPC so special?</HeadingTeal>
                    <HeadingManrope className='BlackManropeHeading'>
                        The IPC has been designed to make learning an interesting and interactive experience for children. It also helps teachers to make the classroom exciting, active and meaningful to each individual.
                    </HeadingManrope>
                    <ParagraphBlack>
                        Learning with the IPC takes a global approach. It helps children to make connections between what they learn at school and what happens in their own countries. The curriculum also encourages students to see things from the perspective of people in other parts of the world.
                    </ParagraphBlack>
                </Container>
                <ImageWrapperIPC>
                    <Container>
                    <img src={IPC2} alt='IPC' />
                    </Container>
                </ImageWrapperIPC>
                <WhitePart>
                    <Container>
                        <HeadingTeal className='HeadingCapabilities'>The IPC enhances 6 core capabilities</HeadingTeal>
                        <FlexWrapperIPC>
                            <Capabilities>
                               <CheckBox/>
                                <HeadingBlack>Independent thinking</HeadingBlack>
                            </Capabilities>
                            <Capabilities>
                               <CheckBox/>
                                <HeadingBlack>Teamwork</HeadingBlack>
                            </Capabilities>
                            <Capabilities>
                               <CheckBox/>
                                <HeadingBlack>Self reflection</HeadingBlack>
                            </Capabilities>
                            <Capabilities>
                               <CheckBox/>
                                <HeadingBlack>Predicting what happens next</HeadingBlack>
                            </Capabilities>
                            <Capabilities>
                               <CheckBox/>
                                <HeadingBlack>Communication</HeadingBlack>
                            </Capabilities>
                            <Capabilities>
                               <CheckBox/>
                                <HeadingBlack>Social engagement & care</HeadingBlack>
                            </Capabilities>

                        </FlexWrapperIPC>
                    </Container>
                </WhitePart>
            </OffWhitePart>

        </>
    )
}

export default IPCurriculum