import React from 'react'
import BannerComponent from '../../../component/Banner/Banner';

//photos
import IPBanner from '../../Assets/Photo/IMYC.jpg'
import IMYCPhoto from '../../Assets/Photo/IMYCSectionPhoto.png'

//styled components
import { CurriculaSection } from '../CurriculaStyles';
import {Container, HeadingTeal, HeadingTealSmall, Section } from '../../../global/global';
import { FlexWrapper, HeadingManrope, HeadingWhite, ImageWrapper, LeftSection, ParagraphBlack, RightSection } from '../../Homepage/StyledComponents';
import { ImperialBGSection } from '../IPCurriculum/IPCurriculumStyles';
import { CapabilitiesIMYC, FlexWrapperIMYC, Wrapper } from './IMYCStyles';
import { HeadingBlack } from '../../AboutUs/AboutusStyles';

const CheckBox = () => {
    return (
        <svg
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 26 26"
            style={{ minWidth: '24px', minHeight: '24px', maxWidth: '24px', maxHeight: '24px' }} // Enforces consistency
        >
            <rect id="Rectangle_493" data-name="Rectangle 493" width="26" height="26" rx="13" fill="#f8cb3c" />
            <path
                id="check"
                d="M4.46,9.26a.624.624,0,0,1-.443-.183L.184,5.242a.626.626,0,0,1,.885-.885L4.46,7.748,11.687.522a.626.626,0,0,1,.885.885L4.9,9.076A.625.625,0,0,1,4.46,9.26Zm0,0"
                transform="translate(7.139 8.718)"
                fill="#fff"
            />
        </svg>
    )
}

const IMYC: React.FC = () => {
    //banner components BreadCrumbs
    const breadcrumbs = [
        { label: "Home", link: "/" },
        { label: "International Middle Year Curriculum" }
    ];
    return (
        <>
            <BannerComponent
                image={IPBanner}
                title="International Middle Year Curriculum"
                breadcrumbs={breadcrumbs}
            />

<CurriculaSection>
                <Container>
                    <HeadingTealSmall>CURRICULUM</HeadingTealSmall>
                    <HeadingTeal>International Middle Years Curriculum</HeadingTeal>
                    <ParagraphBlack>Besides dealing with topics in a way that sparks curiosity, the curriculum coaches students to be both independent thinkers and interdependent learners who can work in a team.</ParagraphBlack>
                </Container>
            </CurriculaSection>


            <ImperialBGSection>
                <Container>
                    <FlexWrapper>
                        {/* Left Section */}

                        <RightSection style={{padding:"0" }}>
                            <HeadingWhite>The IPC is one of the fastest-growing curricula in the world today</HeadingWhite>
                            <ParagraphBlack style={{ color: "white", fontWeight: "bold ",textAlign:"left",padding:"0" }}>This curriculum is a comprehensive, theme-based, creative curriculum that teaches 5 to 12-year-olds how to think with an international mindset.</ParagraphBlack>
                            <ParagraphBlack style={{ color: "white",textAlign:"left",padding:"0"  }}>With a clear process of learning and specific learning goals for each subject, the IPC allows students to develop a personal sense of responsibility for their own learning journey.</ParagraphBlack>


                        </RightSection>

                        <LeftSection>
                            <ImageWrapper>
                                <img src={IMYCPhoto} alt="A girl reading a book" />
                            </ImageWrapper>
                        </LeftSection>
                    </FlexWrapper>

                </Container>
            </ImperialBGSection>

            <Section>
                    <Container>
                        <HeadingTeal className='HeadingCapabilities' style={{textAlign:"left"}}>The IMYC helps students to</HeadingTeal>
                        <FlexWrapperIMYC>
                        <CapabilitiesIMYC>
                                <HeadingManrope style={{color:"black",textAlign:"left",padding:"1rem 0"}}>Make meaning</HeadingManrope>
                                <Wrapper>
                                <CheckBox />
                                <ParagraphBlack style={{margin:"0",padding:"1rem"}}>Linking all subject matter to a conceptual theme</ParagraphBlack>
                                </Wrapper>
                                <Wrapper>
                                <CheckBox />
                                <ParagraphBlack style={{margin:"0",padding:"1rem"}}>Responding to the specific developmental needs of 11 to 14-year-oldsg</ParagraphBlack>
                                </Wrapper>
                                <Wrapper>
                                <CheckBox />
                                <ParagraphBlack style={{margin:"0",padding:"1rem"}}>Working towards understanding from a personal and global perspective</ParagraphBlack>
                                </Wrapper>
                            </CapabilitiesIMYC>

                            <CapabilitiesIMYC>
                                <HeadingManrope style={{color:"black",textAlign:"left",padding:"1rem 0"}}>Draw connections</HeadingManrope>
                                <Wrapper>
                                <CheckBox />
                                <ParagraphBlack style={{margin:"0",padding:"1rem"}}>Interlinking all school subjects according to a theme</ParagraphBlack>
                                </Wrapper>
                                <Wrapper>
                                <CheckBox />
                                <ParagraphBlack style={{margin:"0",padding:"1rem"}}>Preparing students for the next stage of learning</ParagraphBlack>
                                </Wrapper>
                                <Wrapper>
                                <CheckBox />
                                <ParagraphBlack style={{margin:"0",padding:"1rem"}}>Being part of a worldwide community that shares learning experiences, ideas and resources.</ParagraphBlack>
                                </Wrapper>
                            </CapabilitiesIMYC>
                            
                            <CapabilitiesIMYC>
                                <HeadingManrope style={{color:"black",textAlign:"left",padding:"1rem 0"}}>Develop Mentally</HeadingManrope>
                                <Wrapper>
                                <CheckBox />
                                <ParagraphBlack style={{margin:"0",padding:"1rem"}}>Delivering engaging and transformational information, skills and understanding</ParagraphBlack>
                                </Wrapper>
                                <Wrapper>
                                <CheckBox />
                                <ParagraphBlack style={{margin:"0",padding:"1rem"}}>Creating a challenging, student-led learning environment</ParagraphBlack>
                                </Wrapper>
                                <Wrapper>
                                <CheckBox />
                                <ParagraphBlack style={{margin:"0",padding:"1rem"}}>Providing assessment of learning for students and support for teachers</ParagraphBlack>
                                </Wrapper>
                            </CapabilitiesIMYC>
                        </FlexWrapperIMYC>
                    </Container>
               
            </Section>

        </>
    )
}

export default IMYC