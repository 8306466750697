import React from 'react'

import BannerComponent from '../../component/Banner/Banner'

//photos
import IPBanner from '../Assets/Photo/StudentBanner.jpg'

//global styles component
import { Container, HeadingTeal, HeadingTealSmall } from '../../global/global';

//todo 
import { ParagraphBlack, } from '../Homepage/StyledComponents';

//student styled components
import { CreativityContainer,SliderTrack,PhotoContainer, PhotoWrapper, Slide, SlideSubtitle, SlideTitle, CreativitySection  } from './StudentStyles';

//our data
import { StudentCreativityInfo } from '../../data';

const Student = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Student" }
  ];

  return (
    <>
      <BannerComponent
        image={IPBanner}
        title="Student"
        breadcrumbs={breadcrumbs}
      />
      <CreativitySection>
        <Container>
          <HeadingTealSmall>Art Work</HeadingTealSmall>
          <HeadingTeal>The Art Showcase</HeadingTeal>
          <ParagraphBlack>The art showcase at Imperial World School highlights the incredible creativity and talent of students, celebrating their artistic expressions through various mediums.</ParagraphBlack>
        </Container>

        <CreativityContainer>
          <PhotoWrapper>
            <SliderTrack>
              {StudentCreativityInfo.map((info, index) => (
                <Slide key={index}>
                  <PhotoContainer>
                    <img src={info.src} alt=""/>
                  </PhotoContainer>
                  <SlideTitle>{info.title}</SlideTitle>
                  <SlideSubtitle>{info.subtitle}</SlideSubtitle>
                </Slide>
              ))}
            </SliderTrack>

          </PhotoWrapper>
        </CreativityContainer>
      </CreativitySection>


    </>
  )
}

export default Student