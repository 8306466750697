import styled from "styled-components";

export const SectionYellow = styled.section`
    background-color: #F8CB3C;
    padding: 2rem 0;
    display: flex;
    align-items: center;
h1,p{
text-align: left;
margin: 0.5rem;
padding:0;
}
`;

export const TextWrapper = styled.div`
padding:2rem 0;
`;