import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//photos
import ExtraCurriculum from "../../../Assets/Photo/SliderPhoto/ExtraCurriculum.jpg";
import Facilities from "../../../Assets/Photo/SliderPhoto/Facilities.jpg";
import Pedagogy from "../../../Assets/Photo/SliderPhoto/Pedagogy.jpg";

//styles components
import { Container, HeadingTeal, HeadingTealSmall, ParagraphBlack } from '../../StyledComponents';
import { Section, SliderWrapper, SliderTrack, Slide, ImageContainer, Image, SlideTitle, NavButton } from './LifeAtIwsStyle'


// Slide data
const slides = [
  {
    title: "Extra-curriculum",
    image: ExtraCurriculum,
    alt: "Students performing dance"
  },
  {
    title: "Facilities",
    image: Facilities,
    alt: "Students using facilities"
  },
  {
    title: "Pedagogy",
    image: Pedagogy,
    alt: "Teaching methods"
  }
];


//custom arrow for the certificate arrow 
// Define types for custom arrow props
interface CustomArrowProps {
  onClick?: () => void;
}

interface CustomButtonGroupProps {
  next?: () => void;
  previous?: () => void;
}

// Custom Left Arrow Component
const CustomLeftArrow: React.FC<CustomArrowProps> = ({ onClick }) => (
  <button
    onClick={onClick}
    style={{
      position: "absolute",
      bottom: -40,
      left: "47%",
      cursor: "pointer",
      background: "transparent"

    }}
  >
    <svg id="next" xmlns="http://www.w3.org/2000/svg" width="30.931" height="30.931" viewBox="0 0 30.931 30.931">
      <g id="Group_93" data-name="Group 93">
        <g id="Group_92" data-name="Group 92">
          <path id="Path_69" data-name="Path 69" d="M15.466,0A15.466,15.466,0,1,1,0,15.466,15.466,15.466,0,0,1,15.466,0Zm0,28.354A12.888,12.888,0,1,0,2.578,15.466,12.888,12.888,0,0,0,15.466,28.354Z" fill="#2B7C85" />
          <path id="Path_70" data-name="Path 70" d="M198.821,128.377a1.289,1.289,0,0,1,1.823,1.823l-5.533,5.533,5.533,5.533a1.289,1.289,0,0,1-1.823,1.823l-6.444-6.444a1.289,1.289,0,0,1,0-1.823Z" transform="translate(-181.689 -120.267)" fill="#2B7C85" />
        </g>
      </g>
    </svg>
  </button>
);

// Custom Right Arrow Component
const CustomRightArrow: React.FC<CustomArrowProps> = ({ onClick }) => (
  <button
    onClick={onClick}
    style={{
      position: "absolute",
      bottom: -40,
      right: "47%",
      cursor: "pointer",
      background: "transparent"
    }}
  >
    <svg id="Group_93" data-name="Group 93" xmlns="http://www.w3.org/2000/svg" width="30.931" height="30.931" viewBox="0 0 30.931 30.931">
      <g id="Group_92" data-name="Group 92">
        <path id="Path_69" data-name="Path 69" d="M15.466,0A15.466,15.466,0,1,0,30.931,15.466,15.466,15.466,0,0,0,15.466,0Zm0,28.354A12.888,12.888,0,1,1,28.354,15.466,12.888,12.888,0,0,1,15.466,28.354Z" fill="#2B7C85" />
        <path id="Path_70" data-name="Path 70" d="M194.2,128.377a1.289,1.289,0,0,0-1.823,1.823l5.533,5.533-5.533,5.533a1.289,1.289,0,0,0,1.823,1.823l6.444-6.444a1.289,1.289,0,0,0,0-1.823Z" transform="translate(-180.4 -120.267)" fill="#2B7C85" />
      </g>
    </svg>
  </button>
);



// Custom Button Group Component
const CustomButtonGroup: React.FC<CustomButtonGroupProps> = ({ next, previous }) => (
  <div style={{ position: 'relative' }}>
    <CustomLeftArrow onClick={previous} />
    <CustomRightArrow onClick={next} />
  </div>
);

// Main component
const LifeAtIWS = () => {
  const [slider, setSlider] = useState<Slider | null>(null);

  const settings = {
    infinite:false,
    dots: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false, // Explicitly remove default arrows

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <Section>
      <Container>
        <HeadingTealSmall>LIFE AT IWS</HeadingTealSmall>
        <HeadingTeal>
          LEARNING AT IMPERIAL WORLD SCHOOL
        </HeadingTeal>
        <ParagraphBlack>
          A variety of fun activities helps bring out their love of learning and makes them genuinely happy to go to school.
        </ParagraphBlack>

        <SliderWrapper>
          <Slider
            ref={(slideRef) => setSlider(slideRef)}
            {...settings}
          >
            {slides.map((slide, index) => (
              <Slide key={index}>
                <ImageContainer>
                  <Image src={slide.image} alt={slide.alt} />
                </ImageContainer>
                <SlideTitle>{slide.title}</SlideTitle>
              </Slide>
            ))}
          </Slider>
        </SliderWrapper>

        {/* Move CustomButtonGroup outside of SliderWrapper */}
     
          <CustomButtonGroup
            previous={() => slider?.slickPrev()}
            next={() => slider?.slickNext()}
          />
        
      </Container>
    </Section>
  );
};

export default LifeAtIWS;
