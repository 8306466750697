import React from 'react'
import { FaCalendarDays } from 'react-icons/fa6';
import { RiBookletFill } from "react-icons/ri";

//slider exports section 
import ExploreOurSchool from './SliderSections/Explore/ExploreOurSchool';
import LifeAtIWS from './SliderSections/LifeAtIWS/LifeAtIWS';
import Testimonials from './SliderSections/Testimonials/Testimonial';
import IwsEducation from './SliderSections/IwsEducation/IwsEducation';

//import Buttons
import { DarkTealButton, MonocromaticButton, PriButton, SecButton, TWButton } from '../../component/Buttons/Buttons';

//images
import DancingStudent from "../Assets/Photo/GirlDancing.png" //Bright Future section (dancing student)
import Map from "../Assets/Photo/globalMap.png";   //global citizen section
import StudentSafety1 from '../Assets/Photo/StudentSafety1.png'; //student safety section
import StudentSafety2 from '../Assets/Photo/StudentSafety2.png';
import StudentSafety3 from '../Assets/Photo/StudentSafety3.png';
import ChairmanImage from "../Assets/Photo/Portrait_Chairman.png"; //teacher information
import PrincipalImage from "../Assets/Photo/Portrait_Principal.png";
import TeacherImage from "../Assets/Photo/Portrait_Teacher.png";
import NewsPhoto1 from "../Assets/Photo/NewsPhoto1.png";  //News and Events photos
import NewsPhoto2 from "../Assets/Photo/NewsPhoto2.png";
import ScheduleFormPhoto from "../Assets/Photo/ScheduleForm.png";


//import styled components css
import {
    Section,
    Container,
    Grid,
    ContentWrapper,
    Heading,
    HighlightedSpan,
    UnderlinedSpan,
    Description,
    ButtonGroup,
    IconWrapper,
    ImageContainer,
    BackgroundSVG,
    StudentImage,

    //about us 
    HeadingTeal,
    HeadingTealSmall,
    AboutUsParagraphBlack,
    LineBreak,
    YouTubeVideo,
    AboutUsSection,
    OffWhiteSection,

    //StudentSafety
    ParagraphBlack,

    //Global citizen css component
    FlexWrapper,
    TealSection,
    HeadingWhite,
    HeadingWhiteSmall,
    ParagraphWhite,
    LeftSection,
    ImageWrapper,
    Image,

    //International Curriucula 
    CardGrid,
    Card,
    CardImage,
    CardContent,
    HeadingManrope,

    //Our team styles
    PhotoWrapper,
    SliderTrack,
    Slide,
    PhotoContainer,
    SlideTitle,
    SlideSubtitle,



    //news and event section
    YellowSection,
    NewsEventWrapper,
    CardTitle,
    CardDate,
    NewsCard,
    NewsCardContent,
    NewsCardImage,
    NewsHeadingContainer,

    //Schedule a tour styles 
    ScheduleLeftSection,
    RightSection,
    Form,
    ScheduleFlexWrapper,
    ScheduleImageWrapper,
    ScheduleHeadingManrope,



} from './StyledComponents';

import FAQ from '../../component/FAQ/Faq';


const Home = () => {
    //array for cirricula section
    const curricula = [
        {
            title: "National\nCurriculum",
            image: StudentSafety1, // Ensure this import resolves correctly
            alt: "Student with pencil thinking",
        },
        {
            title: "International Primary\nCurriculum",
            image: StudentSafety2, // Ensure this import resolves correctly
            alt: "Smiling student",
        },
        {
            title: "International Middle\nYears Curriculum",
            image: StudentSafety3, // Ensure this import resolves correctly
            alt: "Student looking up",
        },
    ];


    //array for teacher info section
    const TeacherInfo = [
        {
            title: "Kumar Karki",
            subtitle: "Chairman",
            img: ChairmanImage,
        },
        {
            title: "Junita Karki",
            subtitle: "Principal",
            img: PrincipalImage,
        },
        {
            title: "Shanta Ghimire",
            subtitle: "Teacher (Science)",
            img: TeacherImage,
        },
    ];



    return (
        <>
            {/* section 1 aboutus page */}
            <Section>
                <Container>
                    <Grid>
                        <ContentWrapper>
                            <Heading>
                                <HighlightedSpan>
                                    We Create
                                </HighlightedSpan>
                                <br />
                                Bright Future
                                <br />
                                for <UnderlinedSpan>Children</UnderlinedSpan>
                            </Heading>
                            <Description>
                                Our mission is to provide children with a world-class academic
                                and social foundation, helping them grow at a pace appropriate
                                for their stage of development.
                            </Description>
                            <ButtonGroup>
                                <PriButton href="#">
                                    <IconWrapper>
                                        <svg id="schedule" xmlns="http://www.w3.org/2000/svg" width="25.141" height="25.141" viewBox="0 0 25.141 25.141">
                                            <path id="Path_28" data-name="Path 28" d="M22.1,32.7A2.7,2.7,0,0,0,19.4,30h-.54v2.21a.737.737,0,0,1-1.473,0V30H14.142v2.21a.737.737,0,0,1-1.473,0V30H9.428v2.21a.737.737,0,0,1-1.473,0V30H4.714v2.21a.737.737,0,0,1-1.473,0V30H2.7A2.7,2.7,0,0,0,0,32.7v2.21H22.1Z" transform="translate(0 -28.527)" fill="#2b7c85" />
                                            <path id="Path_29" data-name="Path 29" d="M67.473.737A.737.737,0,1,0,66,.737v.737h1.473Z" transform="translate(-62.759)" fill="#2b7c85" />
                                            <path id="Path_30" data-name="Path 30" d="M355.473.737a.737.737,0,1,0-1.473,0v.737h1.473Z" transform="translate(-336.617)" fill="#2b7c85" />
                                            <path id="Path_31" data-name="Path 31" d="M259.473.737a.737.737,0,1,0-1.473,0v.737h1.473Z" transform="translate(-245.331)" fill="#2b7c85" />
                                            <path id="Path_32" data-name="Path 32" d="M163.473.737a.737.737,0,1,0-1.473,0v.737h1.473Z" transform="translate(-154.045)" fill="#2b7c85" />
                                            <path id="Path_33" data-name="Path 33" d="M19.74,165.009a6.843,6.843,0,0,1,2.357.416V160H0v11.048a2.212,2.212,0,0,0,2.21,2.21H13a6.878,6.878,0,0,1,6.736-8.249Zm-2.75-2.431h1.228a.737.737,0,1,1,0,1.473H16.99a.737.737,0,1,1,0-1.473Zm-4.37,0h1.228a.737.737,0,1,1,0,1.473H12.62a.737.737,0,1,1,0-1.473Zm-7.513,8.1H3.879a.737.737,0,1,1,0-1.473H5.107a.737.737,0,1,1,0,1.473Zm0-3.315H3.879a.737.737,0,1,1,0-1.473H5.107a.737.737,0,1,1,0,1.473Zm4.37,3.315H8.249a.737.737,0,1,1,0-1.473H9.477a.737.737,0,1,1,0,1.473Zm0-3.315H8.249a.737.737,0,1,1,0-1.473H9.477a.737.737,0,1,1,0,1.473Zm0-3.315H8.249a.737.737,0,1,1,0-1.473H9.477a.737.737,0,1,1,0,1.473Zm2.406,2.578a.737.737,0,0,1,.737-.737h1.228a.737.737,0,1,1,0,1.473H12.62A.737.737,0,0,1,11.883,166.629Z" transform="translate(0 -152.143)" fill="#2b7c85" />
                                            <path id="Path_34" data-name="Path 34" d="M297.4,292a5.4,5.4,0,1,0,5.4,5.4A5.408,5.408,0,0,0,297.4,292Zm2.455,6.138H297.4a.737.737,0,0,1-.737-.737v-2.455a.737.737,0,1,1,1.473,0v1.719h1.719a.737.737,0,0,1,0,1.473Z" transform="translate(-277.662 -277.662)" fill="#2b7c85" />
                                        </svg>
                                    </IconWrapper>
                                    SCHEDULE A TOUR
                                </PriButton>

                                <SecButton href="#">
                                    <IconWrapper>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21.811" height="24.927" viewBox="0 0 21.811 24.927">
                                            <g id="brochure_1_" data-name="brochure (1)" transform="translate(-32)">
                                                <path id="Path_24" data-name="Path 24" d="M251.33,79h7.238v2.677H251.33Z" transform="translate(-208.652 -75.154)" fill="#f8cb3c" />
                                                <path id="Path_25" data-name="Path 25" d="M184.489,0H173.2a1.87,1.87,0,0,0-1.868,1.868V19.306a.73.73,0,0,0,.73.73h12.429a1.87,1.87,0,0,0,1.868-1.868V1.868A1.87,1.87,0,0,0,184.489,0Zm-7.824,18.208h-2.171a.73.73,0,0,1,0-1.461h2.171a.73.73,0,0,1,0,1.461Zm5.424-2.434h-7.6a.73.73,0,0,1,0-1.461h7.6a.73.73,0,1,1,0,1.461Zm-8.326-3.164a.73.73,0,0,1,.73-.73h6.751a.73.73,0,1,1,0,1.461h-6.751A.73.73,0,0,1,173.764,12.609Zm9.429-1.7h-8.7a.73.73,0,0,1,0-1.461h8.7a.73.73,0,0,1,0,1.461Zm.73-3.651a.73.73,0,0,1-.73.73h-8.7a.73.73,0,0,1-.73-.73V3.116a.73.73,0,0,1,.73-.73h8.7a.731.731,0,0,1,.73.73Z" transform="translate(-132.547)" fill="#f8cb3c" />
                                                <path id="Path_26" data-name="Path 26" d="M37.323,95.839V80.45H33.866A1.869,1.869,0,0,0,32,82.318V99.592a1.869,1.869,0,0,0,1.866,1.868H44.935l-6.4-3.663A2.193,2.193,0,0,1,37.323,95.839Z" transform="translate(0 -76.533)" fill="#f8cb3c" />
                                                <path id="Path_27" data-name="Path 27" d="M242.044,444.383a.753.753,0,0,0,.012-.133v-2.7H237.01Z" transform="translate(-195.029 -420.053)" fill="#f8cb3c" />
                                            </g>
                                        </svg>

                                    </IconWrapper>
                                    DOWNLOAD BROCHURE
                                </SecButton>


                            </ButtonGroup>
                        </ContentWrapper>
                        <ImageContainer>
                            <BackgroundSVG
                                xmlns="http://www.w3.org/2000/svg"
                                width="199.78"
                                height="659.26"
                                viewBox="0 0 199.78 659.26"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="199.78"
                                    height="659.26"
                                    viewBox="0 0 199.78 659.26"
                                    className="absolute top-0 left-0"
                                >
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect id="Rectangle_19" data-name="Rectangle 19" width="199.78" height="659.26" fill="none" />
                                        </clipPath>
                                    </defs>
                                    <g id="Group_73" data-name="Group 73" opacity="0.139">
                                        <g id="Group_72" data-name="Group 72" clipPath="url(#clip-path)">
                                            <path
                                                id="Path_65"
                                                data-name="Path 65"
                                                d="M118.715,455.025c14.076,16.107,29.939,30.581,46.561,44.1l1.2,2.208L132.8,582.4c-1.032,1.545-1.854-.06-2.643-.873-3.467-3.562-8.086-9.937-11.308-14.094C67.306,500.88,27.119,420.16,10.006,337.312-8.691,246.776-1.9,147.437,36.457,62.993c1.05.57.223,2.579.024,3.531C34.769,74.754,31.554,83.6,29.6,92.052c-31.092,134.54-1.246,259.6,89.113,362.973"
                                                transform="translate(0 76.21)"
                                                fill="#3ab9ad"
                                            />
                                            <path
                                                id="Path_66"
                                                data-name="Path 66"
                                                d="M143.072,79.073C56.4,155.539,19.013,274.413,35.991,389.18c1.308,9.745,4.141,20.326,5.056,29.985.093.979.811,3.145-.756,2.943-1.978-9.664-5.48-19.068-7.695-28.659C8.309,288.24,5.469,152.023,70.968,61.907,89.086,36.98,112.948,16.626,138.911,0l1.911,1.434q4.289,18.393,8.813,36.734c1.357,5.471,3.77,11.343,4.977,16.923.67,3.1,2.961,12.466,1.273,14.465-2.475,2.926-9.549,6.638-12.812,9.518"
                                                transform="translate(20.258 -0.001)"
                                                fill="#f7cb3c"
                                            />
                                            <path
                                                id="Path_67"
                                                data-name="Path 67"
                                                d="M146.917,43.574l14.874,60.675c-.342,1.626-7.314,5.235-8.441,6.181C80.212,171.794,34.97,265.451,38.274,362.879c.084,2.488.815,4.579.952,6.939.057.955.563,3.319-.9,3.087-.857-7.922-3.306-16.368-4.3-24.175-5.434-42.857-2.431-94.441,8.9-136.314C57.077,160.12,86.609,106.733,123.343,65.3c4.495-5.069,14.876-16.355,19.913-19.908,1.12-.791,2.135-1.934,3.662-1.819"
                                                transform="translate(37.989 52.707)"
                                                fill="#f7cb3c"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </BackgroundSVG>
                            <StudentImage
                                src={DancingStudent}
                                alt="Student dancing"
                            />
                        </ImageContainer>
                    </Grid>
                </Container>
            </Section>

            {/* section 2 aboutus page */}
            <AboutUsSection>
                <Container>
                    <HeadingTealSmall>ABOUT US</HeadingTealSmall>
                    <HeadingTeal>
                        WHAT MAKES US
                        <br />
                        UNIQUE?
                    </HeadingTeal>
                    <AboutUsParagraphBlack>

                        <LineBreak>
                            So what is one goal, one difference that IWS is marching towards? The goal of inculcating Global Citizenship in the young minds and hearts of our students. Why Global Citizenship Education? We believe it is the need of the hour, globally.
                        </LineBreak>
                        With disaster preparedness education becoming part of the co-curriculum, the Imperial World School stands poised to be a school of choice for all parents who want our children to live, to learn and thrive in a physical environment promising disaster preparedness and complete safety.

                    </AboutUsParagraphBlack>
                    <PriButton>EXPLORE MORE</PriButton>
                </Container>
            </AboutUsSection>

            {/* section 3 aboutus page */}
            <ExploreOurSchool />

            {/* section 4 aboutus page */}
            <OffWhiteSection>
                <Container>
                    <HeadingTealSmall >STUDENT SAFETY</HeadingTealSmall>
                    <HeadingTeal>A DISASTER PREPARED SCHOOL</HeadingTeal>
                    <ParagraphBlack>
                        With disaster preparedness education becoming part of the co-curriculum, the Imperial World School stands poised to
                        be a school of choice for all parents who want our children to live, to learn and thrive in a physical environment
                        promising disaster preparedness and complete safety.
                    </ParagraphBlack>

                    {/* youtube video  */}
                    <YouTubeVideo>
                        <iframe
                            src="https://www.youtube.com/embed/WUVzQHKXpcU"
                            title="Imperial World School Video"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                        ></iframe>
                    </YouTubeVideo>
                </Container>
            </OffWhiteSection >

            {/* section 4 Life at IWS page */}
            <LifeAtIWS />

            {/* section 5 GlobalCitizen */}
            <TealSection>
                <Container>
                    <FlexWrapper>
                        {/* Left Section */}
                        <LeftSection>
                            <HeadingWhiteSmall>GLOBAL CITIZENSHIP EDUCATION</HeadingWhiteSmall>
                            <HeadingWhite>
                                SO WHAT IS ONE GOAL, <br />
                                ONE DIFFERENCE THAT <br />
                                IWS IS MARCHING <br />
                                TOWARDS?
                            </HeadingWhite>
                            <ParagraphWhite>
                                Non-academic activities are widely considered to be a factor that influences a child's ability to excel. But
                                more than that, we at IWS feel that offering students a variety of fun activities helps bring out their love of
                                learning and makes them genuinely happy to go to school.
                            </ParagraphWhite>

                            <PriButton>EXPLORE MORE</PriButton>
                        </LeftSection>

                        {/* Right Section */}
                        <ImageWrapper>
                            <Image src={Map} alt="Global Map" />
                        </ImageWrapper>
                    </FlexWrapper>
                </Container>
            </TealSection>

            {/* International Curricula */}
            <Section>
                <Container>
                    <HeadingTealSmall>STUDENT SAFETY</HeadingTealSmall>
                    <HeadingTeal>INTERNATIONAL CURRICULA</HeadingTeal>
                    <ParagraphBlack>
                        IPC is one of the fastest-growing curricula in the world today
                    </ParagraphBlack>

                    <CardGrid>
                        {curricula.map((curriculum, index) => (
                            <Card key={index}>
                                <CardImage>
                                    <img src={curriculum.image} alt={curriculum.alt} />
                                </CardImage>
                                <CardContent>
                                    <HeadingManrope>{curriculum.title}</HeadingManrope>
                                    <TWButton>EXPLORE MORE</TWButton>
                                </CardContent>
                            </Card>
                        ))}
                    </CardGrid>
                </Container>
            </Section>

            {/* Testimonial Section */}
            <Testimonials />

            {/* IWS Education Section */}
            <IwsEducation />

            {/* Our team section */}
            <Section>
                <Container>
                    <HeadingTealSmall>IWS EDUCATIONAL VIDEO</HeadingTealSmall>
                    <HeadingTeal>IMPERIAL EDUCATIONAL VIDEOS</HeadingTeal>
                    <ParagraphBlack>
                        Imperial World School is committed to providing innovative and engaging experiential learning.
                    </ParagraphBlack>
                    <PhotoWrapper>
                        <SliderTrack>
                            {TeacherInfo.map((info, index) => (
                                <Slide key={index}>

                                    <PhotoContainer>
                                        <img src={info.img} alt="" />
                                    </PhotoContainer>
                                    <SlideTitle>{info.title}</SlideTitle>
                                    <SlideSubtitle>{info.subtitle}</SlideSubtitle>
                                </Slide>
                            ))}
                        </SliderTrack>

                    </PhotoWrapper>
                </Container>
            </Section>

            {/* FAQ Section */}
            <FAQ />


            {/* News And Event section */}
            <YellowSection>
                <Container>
                    <HeadingTeal>News & Events</HeadingTeal>
                    <ParagraphBlack>Get insights on all the news and events hosted by the IWS for the betterment of their students.</ParagraphBlack>

                    <NewsEventWrapper>

                        <NewsCard>
                            <NewsCardContent>
                                <NewsHeadingContainer>
                                    <a href="#">
                                        <CardTitle>Interschool Poem <br />Competition on Baaladhikar</CardTitle>
                                    </a>
                                    <MonocromaticButton>
                                        Learn More
                                    </MonocromaticButton>
                                </NewsHeadingContainer>
                                <CardDate>
                                    13 September 2024
                                </CardDate>
                            </NewsCardContent>
                            <NewsCardImage src={NewsPhoto1} alt="Noteworthy technology acquisitions" />
                        </NewsCard>

                        <NewsCard>
                            <NewsCardContent>
                                <NewsHeadingContainer>
                                    <a href="#">
                                        <CardTitle>QKS Art Exhibition at Art Council - <br />Babarmahal - Grade 4 to 10</CardTitle>
                                    </a>
                                    <MonocromaticButton>
                                        Learn More
                                    </MonocromaticButton>
                                </NewsHeadingContainer>
                                <CardDate>
                                    13 September 2024
                                </CardDate>
                            </NewsCardContent>
                            <NewsCardImage src={NewsPhoto2} alt="Noteworthy technology acquisitions" />
                        </NewsCard>
                    </NewsEventWrapper>
                    <DarkTealButton>View All Events</DarkTealButton>
                </Container>
            </YellowSection>

            {/* schedule a tour section  */}
            <Section>
                <Container>
                    <ScheduleFlexWrapper>
                        {/* Left Section */}
                        <ScheduleLeftSection>
                            <ScheduleImageWrapper>
                                <img src={ScheduleFormPhoto} alt="A boy reading a book" />
                            </ScheduleImageWrapper>
                        </ScheduleLeftSection>

                        {/* Right Section */}
                        <RightSection>
                            <ScheduleHeadingManrope>Schedule a Tour</ScheduleHeadingManrope>
                            <ParagraphWhite>
                                Visit the school perimeters and know everything that you want to know for your child.
                            </ParagraphWhite>
                            <Form>
                                <span>
                                    <input type="text" placeholder="Name" />
                                    <input type="text" placeholder="Name" />
                                </span>
                                <input type="email" placeholder="Email" />
                                <textarea placeholder="Message" />
                                <PriButton>Submit Now</PriButton>
                            </Form>
                        </RightSection>
                    </ScheduleFlexWrapper>
                </Container>
            </Section>
        </>
    )
}

export default Home