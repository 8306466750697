import styled from 'styled-components';


// Define interfaces for custom props
interface SliderTrackProps {
    $currentIndex: number;
  }
  
  interface NavButtonProps {
    $position: 'left' | 'right';
  }
  
  interface IndicatorProps {
    $active: boolean;
  }

  //styled component
export const HeadingYellow = styled.h1`
  color: #F8CB3C;
  font-size: 40px;
  padding: 0.5rem 0;
  font-family: 'luxenta', sans-serif;
  font-weight: bold;
  line-height: 1.2;
`;

export const TestimonialCard = styled.div`
  padding: 1rem 0.2rem;
  
`;

export const ParagraphWhite = styled.div`
  font-size: 17.5px;
  color: #FFFFFF;
  text-align: center;
  padding:1rem 0;
  font-family: 'Manrope', sans-serif;
`;

export const CardContainer = styled.div`
  padding:2rem 3rem;
  height:26rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  background-color:#3BBAAC;
  border-radius:0.6rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const ProfileImageWrapper = styled.div`
  width: 18rem;
  height: 18rem;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
`;

export const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Content = styled.div`
  flex: 1;
`;




export const Name = styled.h3`
  color: #FFFFFF;
  font-family: 'Manrope', sans-serif;
  font-size: 18.5px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  text-align:left;
`;

export const Position = styled.p`
  font-size: 16.5px;
  color: #FFFFFF;
  text-align: left;
  font-family: 'Manrope', sans-serif;

`;

export const Company = styled.p`
  font-size: 16.5px;
  color: #FFFFFF;
  text-align: left;
  padding:0 0 1rem 0;
  font-family: 'Manrope', sans-serif;

`;

export const TestimonialText = styled.p`
  font-size: 16.5px;
  color: #FFFFFF;
  text-align: left;
  padding:1rem 0;
  font-family: 'Manrope', sans-serif;

`;

export const NavButton = styled.button<NavButtonProps>`
  position: block;
  transform: translateY(-100%);
  ${props => props.$position === 'left' ? 'left: 1rem;' : 'right: 1rem;'}
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0.3rem;
  margin:2rem 0.3rem 0 0.3rem;
  justify-content:space-between;
  border-radius: 9999px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;

  &:hover {
    background-color: white;
  }

  svg {
    height: 1.5rem;
    width: 1.5rem;
    color: #1F2937;
  }
`;
