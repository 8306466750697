import styled from "styled-components";
import { Link } from "react-router-dom";
import Slider from "react-slick";

// Styled Components
export const FooterSection = styled.footer`
  background-color: #1e7b85; // Teal background
  padding: 0.75rem 3rem;
  color: white;
  height: 100%;
`;

export const FooterContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 6rem 0 0 0;
`;

export const PhotoSection = styled.div`
  text-align: center;
  margin-bottom: 3rem;

  p {
    text-align: center;
  }
`;

export const PhotoTitle = styled.h3`
  font-family: "Luxenta", sans-serif;
  color: #f8cb3c; // Yellow color
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
`;
export const PhotoGallery = styled(Slider)`
  display: flex;
  gap: 1rem;
  justify-content: center;
  padding: 1rem 0;

  .slick-slide {
    img {
      width: 11.5rem;
      height: 11.6rem;
      background-position: center;
      object-fit: cover;
      border-radius: 8px;
    }
  }
`;

export const FooterContent = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
`;

export const LogoSection = styled.div`
  svg {
    display: block;
    margin-bottom: 1rem;
  }

  p {
    text-align: left;
    margin: 0.5rem 0;
    font-size: 0.9rem;
  }
`;

export const LogoImageContainer = styled.div`
  display: flex;
  margin: 0 0 10px 0;
`;

export const FooterColumn = styled.div`
  h4 {
    color: #f8cb3c;
    margin-bottom: 1rem;
    font-size: 1.1rem;
  }
`;

export const FooterLink = styled(Link)`
  display: block;
  color: white;
  text-align: left;
  font-family: "Manrope";
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;

  &:hover {
    color: #f8cb3c;
  }
`;

export const Copyright = styled.div`
  text-align: center;
  margin-top: 3rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 0.9rem;
`;

export const ParagraphYellow = styled.p`
  font-weight: bold;
  font-family: "Luxenta", sans-serif;
  font-size: 1.2rem;
  text-align: left;
  color: #f8cb3c;
`;

export const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;

  a {
    color: white;
    &:hover {
      color: #f8cb3c;
    }
  }
`;

//phone group
export const PhoneGroup = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  gap: 1rem;
`;
export const Phone = styled.div`
  display: flex;
  flex-direction: column;

  p {
    padding: 0;
    margin: 0;
  }
`;

export const LocationGroup = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 0;

  gap: 1rem;
`;
export const Location = styled.div`
  display: flex;
  flex-direction: column;

  p {
    padding: 0;
    margin: 0;
  }
`;

export const EmailGroup = styled.div`
  display: flex;
  padding: 1rem 0;
  flex-direction: row;
  gap: 1rem;
`;
export const Email = styled.div`
  display: flex;
  flex-direction: column;

  p {
    padding: 0;
    margin: 0;
  }
`;

export const SocialIconsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 100%;
  padding-top: 2rem;
  align-items: right;
  justify-content: right;
`;

export const SocialIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem; /* Icon size */
  height: 2rem;
  background-color: #ffffff;
  border-radius: 50%;
  color: black;
  transition: all 0.3s ease;
`;
