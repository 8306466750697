import React, { useState } from 'react'
import BannerComponent from '../../component/Banner/Banner'
import IPBanner from '../Assets/Photo/MediaBanner.jpg'
import { ButtonTabHeaderContainer, ButtonTabHeaders, ContainerWrapper, MediaSection, PhotoContainer, PhotoWrapper, Slide, SliderTrack, SlideTitle, Content, MainTabWrapper, Tab, TabContent, TabHeaderContainer, TabHeaders, TabWrapper } from './MediaStyles';
import { Container, HeadingTeal, HeadingTealSmall, } from '../../global/global';
import { ParagraphBlack } from '../Homepage/StyledComponents';
import { MediaInfo } from '../../data';


const Media: React.FC = () => {

    //banner components BreadCrumbs
    const breadcrumbs = [
        { label: "Home", link: "/" },
        { label: "Curricula" }
    ];

    //tabs 
    const [activeTab, setActiveTab] = useState<number>(1);
    const handleTabClick = (index: number) => {
        setActiveTab(index);
    };

    return (
        <>
            <BannerComponent
                image={IPBanner}
                title="Admission"
                breadcrumbs={breadcrumbs}
            />
            <MediaSection>
                <Container>
                    <HeadingTealSmall>Media</HeadingTealSmall>
                    <HeadingTeal>Multimedia Moments</HeadingTeal>
                    <ParagraphBlack>Explore the vibrant moments at our school through multimedia—watch videos, browse photos, and get an inside look at the engaging and dynamic environment that awaits your child!</ParagraphBlack>

                </Container>
            </MediaSection>
            <MainTabWrapper>
                <Container>
                    <TabWrapper>
                        {/* Tab Headers */}

                        <ButtonTabHeaderContainer className='he'>
                            <ButtonTabHeaders className='he'>
                                <Tab className='ParentButton' active={activeTab === 1} onClick={() => handleTabClick(1)}>
                                    Media Coverage
                                </Tab>
                                <Tab className='ParentButton' active={activeTab === 2} onClick={() => handleTabClick(2)}>
                                    News Room
                                </Tab>
                                <Tab className='ParentButton' active={activeTab === 3} onClick={() => handleTabClick(3)}>
                                    Events
                                </Tab>
                                <Tab className='ParentButton' active={activeTab === 4} onClick={() => handleTabClick(4)}>
                                    Media Gallery
                                </Tab>
                                <Tab className='ParentButton' active={activeTab === 5} onClick={() => handleTabClick(5)}>
                                    Newsletter
                                </Tab>
                            </ButtonTabHeaders>

                        </ButtonTabHeaderContainer>
                        {/* <TabHeaderContainer>
                            <TabHeaders>
                                <Tab active={activeTab === 1} onClick={() => handleTabClick(1)}>
                                    Media Coverage
                                </Tab>
                                <Tab active={activeTab === 2} onClick={() => handleTabClick(2)}>
                                    News Room
                                </Tab>
                                <Tab active={activeTab === 3} onClick={() => handleTabClick(3)}>
                                    Events
                                </Tab>
                                <Tab active={activeTab === 4} onClick={() => handleTabClick(4)}>
                                    Media Gallery
                                </Tab>
                                <Tab active={activeTab === 5} onClick={() => handleTabClick(5)}>
                                    Newsletter
                                </Tab>

                            </TabHeaders>
                            {/* Dividers */}



                        {/* Tab Content */}
                        <TabContent>
                            <Content active={activeTab === 1}>
                                <ContainerWrapper>
                                    <PhotoWrapper>
                                        <SliderTrack>
                                            {MediaInfo.map((info, index) => (
                                                <Slide key={index}>
                                                    <PhotoContainer>
                                                        <img src={info.src} alt="" />
                                                    </PhotoContainer>
                                                    <SlideTitle>{info.title}</SlideTitle>
                                                </Slide>
                                            ))}
                                        </SliderTrack>

                                    </PhotoWrapper>
                                </ContainerWrapper>
                            </Content>
                        </TabContent>
                    </TabWrapper>
                </Container>
            </MainTabWrapper >
        </>
    )
}

export default Media