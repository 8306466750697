import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProfilePic1 from "../../../Assets/Photo/Profilepic1.png";
import ProfilePic2 from "../../../Assets/Photo/Profilepic2.png";
import { TealSection, Container, HeadingWhiteSmall } from '../../StyledComponents'
import { HeadingYellow, ParagraphWhite, TestimonialCard, CardContainer, ProfileImageWrapper, ProfileImage, Content, Name, Position, Company, TestimonialText, NavButton } from "./TestimonialStyles"

// Slide data
const testimonials = [
  {
    name: "Dr. Gunakar Bhatta",
    image: ProfilePic1,
    position: "Executive Director, and Spokesperson of ",
    company: "Nepal Rastra Bank.",
    text: "Over the past four years, Imperial World School has played an important role in shaping the intellectual and extracurricular development of my child, Shatakshi Bhatta. Right before COVID-19 started in 2020, my wife and I were searching for a school that would provide personal attention and care to students. We were looking for a school that would provide our daughter with a personalized learning platform, helping her to succeed. One day, Shatakshi came up to me and showed the website of Imperial World School. After watching videos of all the activities and events organized by the school, our daughter was eager to learn more about it. This prompted us to visit the school in- person and talk to the administration. While at the school premises, witnessing the earthquake-resistant building and a well-thought-out curriculum led us to immediately enroll our daughter.",
  },
  {
    name: "Asst. Prof. Binod Prasad Pant",
    image: ProfilePic2,
    position: "Head, Department of STEAM Education",
    company: "Kathmandu University School of Education.",
    text: "As a parent, one of my worries was finding the appropriate school in the Kathmandu Valley. Working in the field of education for several years, I had few expectations as a parent. I became satisfied when I enrolled my child in Imperial World School. The most important reason is the learning environment the school created, with enough open spaces, a resourceful library, labs, and a dedicated team of teachers and administrators. Next, the careful blend of academic activities and the exposure to several extracurricular activities while my child got enough exposure to sports and other activities. The incorporation of the International Primary Curriculum (IPC) added another value to integrated teaching and sustainable development. The frequent communication with parents and individual care for the students enhanced my child's learning. ",
  },
];

//custom arrow for the certificate arrow 
// Define types for custom arrow props
interface CustomArrowProps {
  onClick?: () => void;
}

interface CustomButtonGroupProps {
  next?: () => void;
  previous?: () => void;
}

// Custom Left Arrow Component
const CustomLeftArrow: React.FC<CustomArrowProps> = ({ onClick }) => (
  <button
    onClick={onClick}
    style={{
      position: "absolute",
      bottom: -40,
      left: "47%",
      cursor: "pointer",
      background: "transparent"

    }}
  >
    <svg id="next" xmlns="http://www.w3.org/2000/svg" width="30.931" height="30.931" viewBox="0 0 30.931 30.931">
      <g id="Group_93" data-name="Group 93">
        <g id="Group_92" data-name="Group 92">
          <path id="Path_69" data-name="Path 69" d="M15.466,0A15.466,15.466,0,1,1,0,15.466,15.466,15.466,0,0,1,15.466,0Zm0,28.354A12.888,12.888,0,1,0,2.578,15.466,12.888,12.888,0,0,0,15.466,28.354Z" fill="#3BBAAC" />
          <path id="Path_70" data-name="Path 70" d="M198.821,128.377a1.289,1.289,0,0,1,1.823,1.823l-5.533,5.533,5.533,5.533a1.289,1.289,0,0,1-1.823,1.823l-6.444-6.444a1.289,1.289,0,0,1,0-1.823Z" transform="translate(-181.689 -120.267)" fill="#3BBAAC" />
        </g>
      </g>
    </svg>
  </button>
);

// Custom Right Arrow Component
const CustomRightArrow: React.FC<CustomArrowProps> = ({ onClick }) => (
  <button
    onClick={onClick}
    style={{
      position: "absolute",
      bottom: -40,
      right: "47%",
      cursor: "pointer",
      background: "transparent"
    }}
  >
    <svg id="Group_93" data-name="Group 93" xmlns="http://www.w3.org/2000/svg" width="30.931" height="30.931" viewBox="0 0 30.931 30.931">
      <g id="Group_92" data-name="Group 92">
        <path id="Path_69" data-name="Path 69" d="M15.466,0A15.466,15.466,0,1,0,30.931,15.466,15.466,15.466,0,0,0,15.466,0Zm0,28.354A12.888,12.888,0,1,1,28.354,15.466,12.888,12.888,0,0,1,15.466,28.354Z" fill="#3BBAAC" />
        <path id="Path_70" data-name="Path 70" d="M194.2,128.377a1.289,1.289,0,0,0-1.823,1.823l5.533,5.533-5.533,5.533a1.289,1.289,0,0,0,1.823,1.823l6.444-6.444a1.289,1.289,0,0,0,0-1.823Z" transform="translate(-180.4 -120.267)" fill="#3BBAAC" />
      </g>
    </svg>
  </button>
);



// Custom Button Group Component
const  CustomButtonGroup: React.FC<CustomButtonGroupProps> = ({ next, previous }) => (
  <div style={{ position: 'relative' }}>
    <CustomLeftArrow onClick={previous} />
    <CustomRightArrow onClick={next} />
  </div>
);
// Main component
const Testimonials = () => {
  const [slider, setSlider] = useState<Slider | null>(null);

  const settings = {
    dots: false, 
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomRightArrow />,
    prevArrow: <CustomLeftArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <TealSection>
      <Container>
        <HeadingWhiteSmall>Testimonials</HeadingWhiteSmall>
        <HeadingYellow>LEARNING AT IMPERIAL WORLD SCHOOL</HeadingYellow>
        <ParagraphWhite>Testimonials of parents regarding their child's advancement seen after joining IWS</ParagraphWhite>

        <Slider
          ref={(slideRef) => setSlider(slideRef)}
          {...settings}
        >
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index}>
              <CardContainer>
                <ProfileImageWrapper>
                  <ProfileImage
                    src={testimonial.image}
                    alt={testimonial.name}
                  />
                </ProfileImageWrapper>
                <Content>
                  <Name>{testimonial.name}</Name>
                  <Position>{testimonial.position}</Position>
                  <Company>{testimonial.company}</Company>
                  <TestimonialText>{testimonial.text}</TestimonialText>
                </Content>
              </CardContainer>
               {/* Alternative navigation */}
              
            </TestimonialCard>
            
          ))}
        </Slider>
      </Container>
    </TealSection>
  );
};

export default Testimonials;  