
import styled from "styled-components";

export const CurriculaSection = styled.section`
display:flex;
flex-direction:column;
padding:2rem 0;
background-color: #FFFCF2;

p{
    text-align:center;
}
`

export const MaxSection=styled.section`
  flex: 1;
  padding: 0.75rem 3rem;
  max-height: 100vh;
  display: flex;
  align-items: center;
  background-color: white;
`

export const CurriculaDividerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 3px; // Add fixed height
  margin-top: 0.5rem;
`;

export const CurriculaBottomDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e5e5e5;
  position: absolute;
  bottom: 0;
`;

export const CurriculaGlowingDivider = styled.div<{ position: number }>`
  height: 3px;
  width: 33.33%; // Adjust for 3 tabs
  background-color: #f8cb3c;
  box-shadow: 0 0 4px rgba(248, 203, 60, 0.4);
  position: absolute;
  bottom: 0;
  left: ${({ position }) => `${position * 33.33}%`}; // Adjust for 3 tabs
  transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
`;

export const CurriculaTabContainer=styled.div`
  max-width: 1250px;
  margin: 0 auto;
`


export const CurriculaTabHeaderContainer = styled.div`
 padding: 3rem 4rem 0 4rem;
 
`;

//currcicula section 

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 0rem;
`;
export const CurriculaContainer = styled.div`
  display: flex;
  gap: 3rem;
  align-items: center;
  background-color: #FFFCF2;
  border-radius: 2rem;
`;

export const ImageContainer = styled.div`
  flex: 0 0 auto; // Don't allow image to grow or shrink
  position: relative;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: -3px;
  }
`;

export const TextWrapper = styled.div`
  flex: 1;
  text-align: left;
  
  h1 {
    color: #2B7C85;
    font-size: 1.5rem;
    font-family: "Manrope", sans-serif;
    margin-bottom: 1rem;
  }

  p{
    padding:0.5rem 0;
  }
  button{
    margin:2rem 0;
  }

`;
