import styled from "styled-components";

export const ScheduleFlexWrapper=styled.div`
display: flex;
flex-wrap: nowrap;
flex-direction: row;
`
export const LeftSection=styled.div`
display: block;
text-align: left;

.paragraph{
text-align: left;
}
`

export const ContactContainer=styled.div`
display: flex;
flex-direction: column;
padding:2rem 0;
`

export const Contact=styled.div`
display: flex;
align-items: center;
text-align: left;
gap:1rem;
`

export const ParagraphBlack=styled.div`
  font-size: 17.5px;
  color: black;
  text-align: left;
  padding:1rem 0;
  font-family: 'Manrope', sans-serif;
`

export const SocialIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-top:2rem;
  color:#2B7C85;
  
`;

export const SocialIcon = styled.a`
  background-color: #F8CB3C; /* Icon background color */
  border-radius: 50%; /* Circular design */
  text-decoration: none; /* Remove underline */
  transition: all 0.3s ease; /* Smooth hover effect */
  
  img {
    display: flex;
    margin:0.2rem;
    align-items: center;
    justify-content: center;
    width: 2rem; /* Standardized icon size */
    height: 2rem;
  }
`;
