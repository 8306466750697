import styled from "styled-components";
export const BannerContainer = styled.div`
  position: relative;
  height: 300px;
  width: 100%;
  overflow: hidden;

`;


export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Overlay = styled.div`
  position: absolute;
  inset: 0;
  background: rgb(43,124,133);
background: linear-gradient(90deg, rgba(43,124,133,1) 0%, rgba(9,9,121,0) 65%, rgba(0,212,255,0) 100%);
`;

export const ContentContainer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0.75rem 3rem;
  max-width: 1300px;
`;

export const BreadcrumbNav = styled.nav`
  margin: 1rem 0;

  ol {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: rgba(255, 255, 255, 0.9);
    font-size: 1rem;
    list-style: none;
    font-family:Manrope,sans-serif;
    a {
      text-decoration: none;
      color: rgba(255, 255, 255, 0.9);

      &:hover {
        color: #fff;
      }
    }
  }
`;

export const Heading = styled.h1`
  color: white;
  font-family: "Luxenta", serif; 
  font-weight: 700;
  text-align:left;

  @media (min-width: 768px) {
    font-size: 3rem;
  }

  @media (min-width: 1024px) {
    font-size: 3.75rem;
  }
`;