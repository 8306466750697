import styled from "styled-components";

import BGMissionVision from "../../Assets/Photo/BG_MissionAndVision.jpg";

export const ImperialBGSection = styled.section`
  background-image: url(${BGMissionVision});
  background-color: #2B7C85;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  flex: 1;
  display:flex;
  min-height: 60vh;
  padding: 0.75rem 3rem;
  align-items:center;
  text-align: left;

`

export const  SplitSection = styled.section`
  position: relative; /* Enables absolute positioning for children */
  display: flex;
  flex-direction: column;
`;

export const OffWhitePart = styled.div`
  flex: 1;
  background-color: #FFFCF2;
  align-items: center;
  min-height: 50vh;
  width: 100%;
  position: relative;
  z-index: 1; /* Add this to establish stacking context */

  .BlackManropeHeading {
    text-align: center;
    padding: 1rem 0;
  padding: 0.75rem 3rem;

    color: black;
    font-size: 25px;
  }
  
  p {
  padding: 0.75rem 3rem;

    text-align: center;
  }
`;

export const WhitePart = styled.div`
  background-color: white;
  flex: 1;
  padding: 0.75rem 3rem;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  margin-top: 50vh;
  width: 100%;

  .HeadingCapabilities{
    margin: 15rem 0 0 0;
    text-align: left;
  }
`;

export const ImageWrapperIPC = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 60%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 0rem;
  overflow: hidden;
  z-index: 2; /* Move z-index to the wrapper instead of the img */
  
  img {
    border-radius: 0.6rem;
    padding: 0.75rem 3rem;
    width: 100%;
    height: 80%;
    object-fit: cover;
  }
`;

//capabilities section
export const FlexWrapperIPC = styled.div`
  display: grid;
  grid-template-columns: auto auto auto; /* Three columns */
  gap: 3rem; /* Adjust spacing between items */
  padding:4rem 0;
`;
export const Capabilities= styled.div`
  display: flex;
  gap:2rem;
`;

