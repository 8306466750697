import React from 'react'

//logo
import LogoIws from '../../pages/Assets/Photo/Logo.png'

//svgs
import Twitter from '../../pages/Assets/Photo/Svg/twitter.svg'
import Facebook from '../../pages/Assets/Photo/Svg/facebook.svg'
import Instagram from '../../pages/Assets/Photo/Svg/instagram.svg'
import Linkedin from '../../pages/Assets/Photo/Svg/linkedin.svg'

//import styles from header
import {
  TopBarContainer,
  TopBarWrapper,
  TopBarContent,
  TopBarLink,
  Divider,
  SocialIconsWrapper,
  SocialIcon,
  VideoIcon,
  NavBar,
  NavContainer,
  Logo,
  NavLinks,
  NavLink,
  PhoneButton,
} from './HeaderStyles'
import { Link } from 'react-router-dom'

export const TopBar = () => {
  return (
    <TopBarContainer>
      <TopBarWrapper>
        <TopBarContent>
          <TopBarLink href="#">Youtube</TopBarLink>
          <Divider />
          <TopBarLink href="#">Facilites</TopBarLink>
          <Divider />
          <TopBarLink href="#">
            <svg xmlns="http://www.w3.org/2000/svg" width="21.818" height="12.819" viewBox="0 0 21.818 12.819">
              <g id="video-camera" transform="translate(0 -96.512)">
                <g id="Group_236" data-name="Group 236" transform="translate(0 96.512)">
                  <g id="Group_235" data-name="Group 235" transform="translate(0 0)">
                    <path id="Path_344" data-name="Path 344" d="M12.342,96.512H2.387A2.394,2.394,0,0,0,0,98.9v8.045a2.394,2.394,0,0,0,2.387,2.387h9.954a2.394,2.394,0,0,0,2.387-2.387V98.9A2.379,2.379,0,0,0,12.342,96.512Z" transform="translate(0 -96.512)" fill="#f8cb3c" />
                  </g>
                </g>
                <g id="Group_238" data-name="Group 238" transform="translate(15.922 97.786)">
                  <g id="Group_237" data-name="Group 237" transform="translate(0 0)">
                    <path id="Path_345" data-name="Path 345" d="M345.634,123.886a1.216,1.216,0,0,0-.406.167L341.5,126.2v5.538l3.748,2.148a1.419,1.419,0,0,0,1.957-.525,1.458,1.458,0,0,0,.191-.716V125.27A1.437,1.437,0,0,0,345.634,123.886Z" transform="translate(-341.504 -123.847)" fill="#f8cb3c" />
                  </g>
                </g>
              </g>
            </svg>
            Stories
          </TopBarLink>
          <Divider />
          <SocialIconsWrapper>
            <SocialIcon href="#">
              <img src={Linkedin} alt="LinkedIn" />
            </SocialIcon>
            <SocialIcon href="#">
              <img src={Twitter} alt="Twitter" />
            </SocialIcon>
            <SocialIcon href="#">
              <img src={Facebook} alt="Facebook" />
            </SocialIcon>
            <SocialIcon href="#">
              <img src={Instagram} alt="Instagram" />
            </SocialIcon>
          </SocialIconsWrapper>

        </TopBarContent>
      </TopBarWrapper>
    </TopBarContainer>
  )
}

export const HeaderBar = () => {
  return (
    <NavBar>
      <NavContainer>
        <a href="/">
          <Logo src={LogoIws} alt="Logo" />
        </a>
        <NavLinks>
          <Link to="/">Home</Link>
          <Link to="/aboutus">About Us</Link>
          <Link to="/curricula">Curricula</Link>
          <Link to="/Life-At-IWS">Life at IWS</Link>
          <Link to="/admission">Admission</Link>
          <Link to="/media">Media</Link>
          <Link to="/student">Student</Link>
          <Link to="/contactus">Contact</Link>
          <PhoneButton>
            01 - 4370331
          </PhoneButton>
        </NavLinks>
      </NavContainer>
    </NavBar>
  )
}