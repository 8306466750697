import React from 'react';

import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { TopBar, HeaderBar } from './component/Header/Header';

//pages 
import Home from './pages/Homepage/Home';
import Aboutus from './pages/AboutUs/Aboutus';
import Curricula from './pages/Curricula/Curricula';
import NationalCurriculum from './pages/Curricula/NationalCurriculum/NationalCurriculum';
import IPCurriculum from './pages/Curricula/IPCurriculum/IPCurriculum';
import Footer from './component/Footer/Footer';
import IMYC from './pages/Curricula/IMYCurriculum/IMYC';
import LIWS from './pages/LifeAtIWS/LIWS';
import Contactus from './pages/ContactUs/Contactus';
import Student from './pages/Student/Student';
import NotFound from './pages/NotFound/NotFound';
import Admission from './pages/Admission/Admission';
import Media from './pages/Media/Media';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <TopBar />
        <HeaderBar />
        <Routes>
          {/* home page routes */}
          <Route path='/' element={<Home />} />

          {/* about us page route */}
          <Route path='/aboutus' element={<Aboutus />} />

          {/* curriculum pages routes */}
          <Route path='/curricula' element={<Curricula />} />
          <Route path='/curricula/national-curriculum' element={<NationalCurriculum />} />
          <Route path='/curricula/ipc' element={<IPCurriculum />} />
          <Route path='/curricula/imyc' element={<IMYC />} />

          {/* life at iws */}
          <Route path='/Life-At-IWS' element={<LIWS />} />

          {/* Student */}
          <Route path='/student' element={<Student />} />

          {/* contact us page */}
          <Route path='/contactus' element={<Contactus />} />

          {/* Media page */}
          <Route path='/media' element={<Media />} />

          {/* Admission page */}
          <Route path='/admission' element={<Admission />} />

          {/* contact us page */}
          <Route path='*' element={<NotFound />} />

        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
