import React, { useState } from 'react'

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//banner component 
import BannerComponent from '../../component/Banner/Banner'

//global css
import { Section, Container, HeadingTeal, HeadingTealSmall, OffWhiteSection, LineBreak, ParagraphWhite, HeadingWhite, HeadingWhiteSmall, Image, PhotoContainer, PhotoWrapper, Slide, SlideSubtitle, SlideTitle, TealSection } from '../../global/global'

//styled component from about us section
import {
    FlexWrapper,
    LeftSection,
    ImageWrapper,
    RightSection,
    ParagraphBlack,

    //tab section
    TabHeaders,
    TabContent,
    Content,
    Tab,
    TabWrapper,
    BottomDivider,
    GlowingDivider,
    DividerWrapper,
    MainTabWrapper,
    TabHeaderContainer,
    MessageContainer,

    //messsage from chairman styles
    Message,
    PersonalDescriptionContainer,
    MessageParagraph,
    HeadingManrope,
    MessageDivider,

    //Mission and Vision Styles 
    MVSection,
    MVCard,
    MVContent,
    MVWrapper,
    MVHeadingContainer,

    //Our Values Section
    ValuesContainer,
    Values,
    ValueHeading,

    //tab section 2
    SS_Container,
    StudentSafetySection,
    SS_Card,
    StudentSafetyContainer,
    StudentSafetySection2,
    SectionTab3,
    GlobalCitizenFlexWrapper,
    GCLeftSection,
    GCImageWrapper,
    PhoneButton,
    HeadingBlack,
    DescriptionSection,
    LineBreakBig,
    SectionGradientYellow,
    HeadingManropeBig,
    RightSectionPromotion,
    RightSectionContainer,

    //testimonials tab section
    ButtonTabHeaderContainer,
    ButtonTabHeaders,
    //testimonial parents
    TestimonialCard,
    ProfileImage,
    Name,
    Title,
    Position,
    Bio,
    Testimonial,
    ProfileContainer,
    OurTeamContainer,
    OurTeamSliderTrack,
    AwardSection,


} from './AboutusStyles'

//photos
import BannerPhoto from '../../pages/Assets/Photo/AboutUsBanner.jpg' //banner photo
import AboutUsWelcome from '../Assets/Photo/AboutUsWelcome.png'
import ChairmanPhoto from '../Assets/Photo/ChairmanPhoto.png'//image of chariman and principal
import PrincipalPhoto from '../Assets/Photo/PrincipalPhoto.png'
import OurValues from '../Assets/Photo/OurValues.png' //our values section
import Certificate1 from '../Assets/Photo/Certificate1.png'//certificates
import Certificate2 from '../Assets/Photo/Certificate2.png'
import Certificate3 from '../Assets/Photo/Certificate3.png'
import Tab2_VISUS from '../Assets/Photo/VISUS.jpg' //tab 2 images 
import Tab2_UNDRR from '../Assets/Photo/UNDRR.jpg'
import Tab2_EdiKissling from '../Assets/Photo/EdiKissling.jpg'
import UNESCO from '../Assets/Photo/UNESCO.png'
import UN from '../Assets/Photo/UN.png'
import SafeHeaven from '../Assets/Photo/SafeHeaven.png'
import Map from "../Assets/Photo/Map2.png";   //global citizen section
import PromotionDay from "../Assets/Photo/PromotionDay.png";   //Testimonial section tab
import Testimonial1 from "../Assets/Photo/Testimonial1.png";
import Testimonial2 from "../Assets/Photo/Testimonial2.png";
import Testimonial3 from "../Assets/Photo/Testimonial3.png";
import Testimonial4 from "../Assets/Photo/Testimonial4.png";
import ChairmanImage from "../Assets/Photo/Portrait_Chairman.png"; //teacher information
import PrincipalImage from "../Assets/Photo/Portrait_Principal.png";
import TeacherImage from "../Assets/Photo/Portrait_Teacher.png";

//button components
import { PriButton } from '../../component/Buttons/Buttons'

//custom arrow for the certificate arrow 
// Define types for custom arrow props
interface CustomArrowProps {
    onClick?: () => void;
}

interface CustomButtonGroupProps {
    next?: () => void;
    previous?: () => void;
}

// Custom Left Arrow Component
const CustomLeftArrow: React.FC<CustomArrowProps> = ({ onClick }) => (
    <button
        onClick={onClick}
        style={{
            position: "absolute",
            bottom: -40,
            left: "47%",
            cursor: "pointer",
            background: "transparent"

        }}
    >
        <svg id="next" xmlns="http://www.w3.org/2000/svg" width="30.931" height="30.931" viewBox="0 0 30.931 30.931">
            <g id="Group_93" data-name="Group 93">
                <g id="Group_92" data-name="Group 92">
                    <path id="Path_69" data-name="Path 69" d="M15.466,0A15.466,15.466,0,1,1,0,15.466,15.466,15.466,0,0,1,15.466,0Zm0,28.354A12.888,12.888,0,1,0,2.578,15.466,12.888,12.888,0,0,0,15.466,28.354Z" fill="#2b7c85" />
                    <path id="Path_70" data-name="Path 70" d="M198.821,128.377a1.289,1.289,0,0,1,1.823,1.823l-5.533,5.533,5.533,5.533a1.289,1.289,0,0,1-1.823,1.823l-6.444-6.444a1.289,1.289,0,0,1,0-1.823Z" transform="translate(-181.689 -120.267)" fill="#2b7c85" />
                </g>
            </g>
        </svg>


    </button>
);

// Custom Right Arrow Component
const CustomRightArrow: React.FC<CustomArrowProps> = ({ onClick }) => (
    <button
        onClick={onClick}
        style={{
            position: "absolute",
            bottom: -40,
            right: "47%",
            cursor: "pointer",
            background: "transparent"
        }}
    >
        <svg id="Group_93" data-name="Group 93" xmlns="http://www.w3.org/2000/svg" width="30.931" height="30.931" viewBox="0 0 30.931 30.931">
            <g id="Group_92" data-name="Group 92">
                <path id="Path_69" data-name="Path 69" d="M15.466,0A15.466,15.466,0,1,0,30.931,15.466,15.466,15.466,0,0,0,15.466,0Zm0,28.354A12.888,12.888,0,1,1,28.354,15.466,12.888,12.888,0,0,1,15.466,28.354Z" fill="#2b7c85" />
                <path id="Path_70" data-name="Path 70" d="M194.2,128.377a1.289,1.289,0,0,0-1.823,1.823l5.533,5.533-5.533,5.533a1.289,1.289,0,0,0,1.823,1.823l6.444-6.444a1.289,1.289,0,0,0,0-1.823Z" transform="translate(-180.4 -120.267)" fill="#2b7c85" />
            </g>
        </svg>
    </button>
);



// Custom Button Group Component
const CustomButtonGroup: React.FC<CustomButtonGroupProps> = ({ next, previous }) => (
    <div style={{ position: 'relative' }}>
        <CustomLeftArrow onClick={previous} />
        <CustomRightArrow onClick={next} />
    </div>
);

const Aboutus: React.FC = () => {
    const [activeTab, setActiveTab] = useState<number>(1);
    const [activeTabButton, setActiveTabButton] = useState<number>(1);

    const handleTabClick = (index: number) => {
        setActiveTab(index);
    };

    //for button tab in testimonial
    const handleTabButtonClick = (index: number) => {
        setActiveTabButton(index);
    };

    //our teams teacher info 
    //array for teacher info section
    const TeacherInfo = [
        {
            title: "Kumar Karki",
            subtitle: "Chairman",
            img: ChairmanImage,
        },
        {
            title: "Junita Karki",
            subtitle: "Principal",
            img: PrincipalImage,
        },
        {
            title: "Shanta Ghimire",
            subtitle: "Teacher (Science)",
            img: TeacherImage,
        },
        {
            title: "Kumar Karki",
            subtitle: "Chairman",
            img: ChairmanImage,
        },
        {
            title: "Junita Karki",
            subtitle: "Principal",
            img: PrincipalImage,
        },
        {
            title: "Shanta Ghimire",
            subtitle: "Teacher (Science)",
            img: TeacherImage,
        },
        {
            title: "Kumar Karki",
            subtitle: "Chairman",
            img: ChairmanImage,
        },
        {
            title: "Junita Karki",
            subtitle: "Principal",
            img: PrincipalImage,
        },
        {
            title: "Shanta Ghimire",
            subtitle: "Teacher (Science)",
            img: TeacherImage,
        },

    ];

    //banner components BreadCrumbs
    const breadcrumbs = [
        { label: "Home", link: "/" },
        { label: "About Us" }
    ];

    const [slider, setSlider] = useState<Slider | null>(null);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false, // Disable default arrows
        nextArrow: <CustomRightArrow />,
        prevArrow: <CustomLeftArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div>
            <BannerComponent
                image={BannerPhoto}
                title="About Us"
                breadcrumbs={breadcrumbs}
            />
            <OffWhiteSection>
                <Container>
                    <FlexWrapper>
                        {/* Left Section */}
                        <LeftSection>
                            <ImageWrapper>
                                <img src={AboutUsWelcome} alt="A girl laughing with her teacher" />
                            </ImageWrapper>
                        </LeftSection>
                        <RightSection>
                            <HeadingTealSmall>ABOUT US</HeadingTealSmall>
                            <HeadingTeal>WELCOME TO IMPERIAL WORLD SCHOOL, NEPAL</HeadingTeal>
                            <ParagraphBlack>At Imperial World School, we believe in nurturing young minds to become compassionate, confident, and capable individuals. Situated in the heart of Nepal, our institution is dedicated to providing a holistic education that blends academic excellence with personal development. We aim to inspire students to reach their full potential, fostering skills that will serve them well both in the classroom and in life.</ParagraphBlack>
                            <ParagraphBlack>We aim to inspire students to reach their full potential, fostering skills that will serve them well both in the classroom and in life.</ParagraphBlack>
                            <PriButton>Schedule A Tour</PriButton>
                        </RightSection>
                    </FlexWrapper>

                </Container>
            </OffWhiteSection>

            {/* tabs section */}
            <MainTabWrapper>
                <TabWrapper>
                    {/* Tab Headers */}
                    <TabHeaderContainer>
                        <TabHeaders>
                            <Tab active={activeTab === 1} onClick={() => handleTabClick(1)}>
                                Messages
                            </Tab>
                            <Tab active={activeTab === 2} onClick={() => handleTabClick(2)}>
                                Disaster Prepared School
                            </Tab>
                            <Tab active={activeTab === 3} onClick={() => handleTabClick(3)}>
                                Global Citizenship Education
                            </Tab>
                            <Tab active={activeTab === 4} onClick={() => handleTabClick(4)}>
                                Testimonials
                            </Tab>
                            <Tab active={activeTab === 5} onClick={() => handleTabClick(5)}>
                                Our Team
                            </Tab>
                        </TabHeaders>
                        {/* Dividers */}
                        <DividerWrapper>
                            <GlowingDivider position={activeTab - 1} />
                            <BottomDivider />
                        </DividerWrapper>
                    </TabHeaderContainer>


                    {/* Tab Content */}

                    <TabContent>
                        <Content active={activeTab === 1}>

                            {/* Message from Chairman */}
                            <TealSection>
                                <MessageContainer>
                                    <Message>
                                        <HeadingWhite>Message from our Chairman</HeadingWhite>
                                        <ParagraphWhite>
                                            I look back to a journey of 12 years. It was 2010. The year Imperial World School rose with a dream, a vision. It was my personal dream. Having successfully facilitated higher studies abroad for students from Nepal, I was enabled to see some of the best academic institutions from around the world. I observed them scaling new heights to teach, empower and adorn young lives entrusted to their care. Sending out visionary leaders, contributors and innovators. I was inspired to be a part of this global mission. Which in the words of Martin Luther King was ‘to teach to think intensively and critically. To combine intelligence with character. Making it the goal of true education.’
                                            <LineBreak />
                                            Come 2010 and Imperial World School was born. But just 5 years down, the 2015 great earthquake burst into our lives causing tragic loss and uncertainty. We were made to seriously re-consider disaster preparedness and security for all in our care. I saw the need for a school building providing total safety for students and peace of mind for all guardians. IWS relocated. We began building an institution spread out on just one floor, completely seismic zone friendly and occupying center stage in some 80,000 square feet of space, with lush green and open courtyards, promising free reign to energetic minds and bodies.
                                            <LineBreak />

                                            But again, suddenly, a global pandemic tightened its grip worldwide and of course, Imperial World School was not immune. ‘Do not judge me by my success but judge me by how many times I fell down and got back up again.’ The spirit behind these words from Nelson Mandela kept us going. Finally, after the pandemic, when the world began rising up and coping with a new normal, IWS was ready. Students began pouring into a physical ambience designed for safety. But physical safety was just one of our commitments and concerns.
                                            <LineBreak />

                                            How do we prepare our students to be responsible and wise decision makers, visionary leaders propelled by a commitment to honesty, hard work and humanity, unafraid to embrace diversity and be different and finally, to be altruistic givers and contributors wherever they go? The IWS pedagogy is designed to meet this need and challenge. While academic achievement is supported and assigned a position of non-negotiable importance, our co-curriculum is woven with the richness of Global Citizenship Education. Something promoted strongly even by UNESCO. From learning the importance of spiritual quotient to participating in social inclusion, being informed of humanitarian catastrophes in history and becoming experts in the art of social conversation and etiquette, we believe students at IWS are well on the way to step out as empowered and responsible Global Citizens!
                                            <LineBreak />

                                            I invite you to join us as we march towards making our dream a reality. Our dream of seeing our children, our future, leading, with a rich legacy from the past, experiential learning from the present and a clear and bright hope for tomorrow!
                                        </ParagraphWhite>
                                    </Message>
                                    <PersonalDescriptionContainer>

                                        <img src={ChairmanPhoto} alt="Principal" />

                                        <MessageParagraph>
                                            <HeadingManrope>Kumar Karki, Chairman</HeadingManrope>
                                            <MessageDivider />
                                            <ParagraphWhite>I observed them scaling new heights to teach, empower and adorn young lives entrusted to their care. Sending out visionary leaders, contributors and innovators. I was inspired to be a part of this global mission. Which in the words of Martin Luther King was ‘to teach to think intensively and critically. To combine intelligence with character. Making it the goal of true education.</ParagraphWhite>
                                        </MessageParagraph>
                                    </PersonalDescriptionContainer>
                                </MessageContainer>
                            </TealSection>

                            {/* Message from our Principal section*/}
                            <OffWhiteSection>
                                <MessageContainer>
                                    <PersonalDescriptionContainer>
                                        <img src={PrincipalPhoto} alt="Principal" />
                                        <MessageParagraph>
                                            <HeadingManrope>Junita Karki, Principal</HeadingManrope>
                                            <MessageDivider />
                                            <ParagraphWhite>I observed them scaling new heights to teach, empower and adorn young lives entrusted to their care. Sending out visionary leaders, contributors and innovators. I was inspired to be a part of this global mission. Which in the words of Martin Luther King was ‘to teach to think intensively and critically. To combine intelligence with character. Making it the goal of true education.</ParagraphWhite>
                                        </MessageParagraph>
                                    </PersonalDescriptionContainer>
                                    <Message>
                                        <HeadingTeal>Message from our Principal</HeadingTeal>
                                        <ParagraphBlack>
                                            <b>“Educating the mind without educating the heart is no education at all.” - Aristotle</b>
                                            <LineBreak />

                                            Imperial World School is a progressive school. With the national curriculum as our initial learning platform, we moved forward and literally pioneered a partnership with the International Primary Curriculum and the International Middle Year Curriculum. All part of our mission to help our students learn and love learning, as Mahatma Gandhi said, ‘learn like we are going to live forever!
                                            <LineBreak />

                                            IWS is also mindful of UNESCO’s Global Citizenship Education focused on empowering learners of all ages to understand and accept individual responsibility to see the globe through ethical lens. Promoting respect for diversity, supporting multilingualism and being equipped with social skills to navigate as peacemakers in the 21st century.
                                            <LineBreak />

                                            Our students learn Signed Communication from a Deaf tutor as part of learning to respect and accept diversity. Shaping minds and hearts, very early on, to value tolerance and reject violence, we have history talks on subjects like the Holocaust and the Rwanda conflict. Structured training to develop soft and related social skills is also part of education at IWS.
                                            <LineBreak />

                                            Sports, they say, is not only about physical and mental fitness. It’s about accountability, leadership, facing challenges and being a team player. And at IWS sports is given uncompromising and high importance. All facilitated, not only by our experienced and passionate instructors, but also by the unusually spacious grounds occupying some 2.76 acres. And for those inclined to stretch beyond sports, we have classes in music, folk and contemporary dance.
                                            <LineBreak />

                                            Physical safety of children is a prime concern for every parent and guardian. All the more when we remember our country lies on a seismic belt. But at IWS, our sturdy buildings, all on the ground floor and surrounded by extensive and open spaces of green and concrete courts assure immediate security in case of sudden crisis.
                                            <LineBreak />

                                            Looking back, there is a deep sense of satisfaction as we see progressive IWS milestones through the years. Testimonials of students synergized with modern teaching-learning technology, each one contributing in a generation of well informed and emotionally sound members of society! We are energized to continue giving more and better to our students now and into the future, nurturing a growth holistic and vibrant.
                                            <LineBreak />

                                            I welcome you to be a part of our community, committed to learning for life!

                                        </ParagraphBlack>
                                    </Message>

                                </MessageContainer>
                            </OffWhiteSection>
                        </Content>
                        <Content active={activeTab === 2}>
                            <Section>
                                <StudentSafetyContainer>
                                    <HeadingTealSmall>STUDENT SAFETY</HeadingTealSmall>
                                    <HeadingTeal>A DISASTER PREPARED SCHOOL</HeadingTeal>
                                    <ParagraphBlack className='paragraphTab'>With disaster preparedness education becoming part of the co-curriculum, the Imperial World School stands poised to be a school of choice for all parents who want our children to live, to learn and thrive in a physical environment promising disaster preparedness and complete safety.</ParagraphBlack>

                                    <StudentSafetySection>
                                        <SS_Container>
                                            <img className='Image1' src={Tab2_VISUS} alt='vissus' />
                                            <SS_Card className='sscard' style={{ background: "#3BBAAC" }}>
                                                <img src={UNESCO} alt="UNESCO" />
                                                <HeadingManrope>UNESCO</HeadingManrope>
                                                <ParagraphWhite>‘Construction of schools safe during disasters … part of a nation’s long term planning.’ Deploying VISUS to assess school safety during disasters Visual Inspection for defining Safety Upgrading Strategies in Schools</ParagraphWhite>
                                            </SS_Card>
                                        </SS_Container>

                                        <SS_Container>
                                            <img src={Tab2_UNDRR} alt='UNDRR' />
                                            <SS_Card style={{ background: "#F9F0E7" }}>
                                                <img src={UN} alt="UNESCO" />
                                                <HeadingManrope style={{ color: "#2B7C85" }}>UN World Disaster Reduction Campaign</HeadingManrope>
                                                <ParagraphBlack>‘Disaster Reduction Begins at Schools’</ParagraphBlack>

                                            </SS_Card>

                                        </SS_Container>

                                        <SS_Container>
                                            <img src={Tab2_EdiKissling} alt='Edi Kissling' />
                                            <SS_Card style={{ background: "#F8CB3C" }}>
                                                <HeadingManrope style={{ color: "#2B7C85" }}>Edi Kissling, Professor of Seismology and Geodynamics ETH Zurich University:</HeadingManrope>
                                                <ParagraphBlack>‘No one can predict earthquakes. However, we can improve our understanding of the seismic hazard in a specific area and take appropriate precautions.’</ParagraphBlack>

                                            </SS_Card>
                                        </SS_Container>
                                    </StudentSafetySection>
                                    <StudentSafetySection2>
                                        <HeadingManrope style={{ fontSize: "1.2rem", color: "#2B7C85", fontWeight: "bold" }}>Disaster Preparedness: Not history. About our Today <br /> and Tomorrow. All Around Us.</HeadingManrope>
                                        <ParagraphBlack>Something we are all seeing and experiencing: Increased frequency and intensity of natural hazards with climate change as one trigger. Nepal 2015 earthquake safely happened on a Saturday. Our children were home. But disasters may not always be on a holiday. On earthquakes in Nepal, Myrepublica Nagariknetwork.com of January 19, 2019, referred to scientists of seismology and geodynamics from ETH Zurich University.
                                            Quote: “The country may still face the threat of a much stronger earthquake. Nepal sits at the point where 2 continental plates - Indian and Eurasian - meet. Researchers used a new computer model to study the collision zone close to the Himalayas. The simulations suggested current build-up of stress could be enough to cause a powerful earthquake of magnitude 8.1 or more in the near future.”</ParagraphBlack>
                                    </StudentSafetySection2>
                                </StudentSafetyContainer>
                            </Section>

                            <OffWhiteSection>
                                <Container>
                                    <FlexWrapper>
                                        {/* Left Section */}
                                        <LeftSection>
                                            <ImageWrapper>
                                                <img src={SafeHeaven} alt="Safe heaven img" />
                                            </ImageWrapper>
                                        </LeftSection>

                                        <RightSection>
                                            <HeadingTeal>Imperial World School A Disaster Prepared School Safe Haven for Children</HeadingTeal>
                                            <ParagraphBlack>Our structure, built on and around a framework of heavy steel, is deliberately assigned to sit at the center of open green spaces and flat courtyards of 120,472 square feet or 22 ropanis with compound walls at a very safe distance. Rising up to a sturdy and rooted one floor, just the ground floor, the sprawling school edifice covering 32 thousand square feet has 8 exit points conveniently leading out to free and open grounds outside.</ParagraphBlack>

                                            <ParagraphBlack>With disaster preparedness education becoming part of the co-curriculum, the Imperial World School stands poised to be a school of choice for all parents who want our children to live, to learn and thrive in a physical environment promising disaster preparedness and complete safety.</ParagraphBlack>


                                            <ParagraphBlack>“Humans have lived with natural hazards since the start of civilization. It’s lack of preparedness and exposed vulnerability that turn hazards into tragic catastrophes. Let’s stay prepared. Let us help save lives.”</ParagraphBlack>
                                        </RightSection>
                                    </FlexWrapper>

                                </Container>
                            </OffWhiteSection>
                        </Content>

                        {/* third tab  */}
                        <Content active={activeTab === 3}>
                            <SectionTab3>
                                <StudentSafetyContainer style={{ paddingBottom: "3rem" }}>
                                    <HeadingTealSmall>Global Citizenship Education</HeadingTealSmall>
                                    <HeadingTeal>Global Citizenship Education</HeadingTeal>
                                    <ParagraphBlack className='paragraphTab'>Imperial World School believes leading and leadership are about going the extra mile to create a difference. As Jeremy Bravo once said, it’s not about ranks or ‘roles but it’s about goals’! Yes, goals that take us, milestone by milestone, through the corridors of time to be, to see, to celebrate the change, the difference, we yearn for.</ParagraphBlack>
                                </StudentSafetyContainer>
                            </SectionTab3>
                            <OffWhiteSection>

                                <Container>
                                    <GlobalCitizenFlexWrapper>
                                        {/* Left Section */}
                                        <GCLeftSection>
                                            <HeadingTealSmall>GLOBAL CITIZENSHIP EDUCATION</HeadingTealSmall>
                                            <HeadingTeal>
                                                SO WHAT IS ONE GOAL, <br />
                                                ONE DIFFERENCE THAT <br />
                                                IWS IS MARCHING <br />
                                                TOWARDS?
                                            </HeadingTeal>
                                            <ParagraphBlack>
                                                Non-academic activities are widely considered to be a factor that influences a child's ability to excel. But
                                                more than that, we at IWS feel that offering students a variety of fun activities helps bring out their love of
                                                learning and makes them genuinely happy to go to school.
                                            </ParagraphBlack>
                                            <PhoneButton>
                                                01 - 4370331
                                            </PhoneButton>
                                            <PriButton>View Details</PriButton>
                                        </GCLeftSection>

                                        {/* Right Section */}
                                        <GCImageWrapper>
                                            <Image src={Map} alt="Global Map" />
                                        </GCImageWrapper>
                                    </GlobalCitizenFlexWrapper>
                                </Container>
                            </OffWhiteSection>

                            <StudentSafetySection2>
                                <Container>
                                    <DescriptionSection>
                                        <HeadingBlack>Technology has built a façade of worldwide interconnectedness in areas of mass communication and knowledge sharing.</HeadingBlack>
                                        <ParagraphBlack>The globe has shrunk under the skies of superfast travel modes. Astronomers and cosmologists make us stand on new heights, probing into outer space and the hidden mysteries therein. While all the time, around us, on our planet, inequality, aggression, poverty, hate and violence continue erupting, spewing out human misery and shame.</ParagraphBlack>
                                        <LineBreakBig />

                                        <HeadingBlack>Do we, the drivers of academic institutions and schools, have a role to discharge, a difference to make, in this period of time that threatens the very fabric of being a human?</HeadingBlack>
                                        <ParagraphBlack>Imperial World School’s answer is ‘Yes, there’s a role to fulfil, a goal to meet, a response to make.’ And how? IWS joins UNESCO in promoting Global Citizenship Education. What is Global Citizenship Education?
                                            According to UNESCO, Global Citizenship Education is about empowering students with knowledge to better understand world complexities, from the local to the global level. Global Citizenship Education develops socio-emotional fitness by inculcating social inclusion, learning to acknowledge, respect and work with diversity. Global Citizenship Education teaches globally acceptable conduct or behavior conducive to peaceful relationships.
                                        </ParagraphBlack>
                                    </DescriptionSection>
                                </Container>
                            </StudentSafetySection2>

                            <SectionGradientYellow>
                                <Container style={{ padding: "0 0 3rem 0" }}>
                                    <HeadingBlack style={{ padding: "0 0 2rem 0" }}>How is Imperial World School promoting Global Citizenship Education? We have worked out a 3 pronged approach, closely aligned with UNESCO recommendations.</HeadingBlack>
                                    <FlexWrapper>
                                        {/* Left Section */}
                                        <LeftSection>
                                            <ImageWrapper>
                                                <img src={PromotionDay} alt="A boy drawing" />
                                            </ImageWrapper>
                                        </LeftSection>

                                        <RightSectionPromotion>
                                            <RightSectionContainer>
                                                <HeadingManropeBig style={{ padding: "0 1rem 0 0" }}>1</HeadingManropeBig>
                                                <ParagraphBlack >Creating awareness of world complexities via talks on the Holocaust, the Rwanda Conflict, Human Rights and the Global Economic system as it exists. All of this to help students recognize and avoid every form of aggression, violence and hate, right from grassroots!</ParagraphBlack>
                                            </RightSectionContainer>
                                            <RightSectionContainer>
                                                <HeadingManropeBig>2</HeadingManropeBig>
                                                <ParagraphBlack>Teaching Social Inclusion by practice. IWS has taken up the Deaf Community as a case study. We are building a bridge of respect and inclusion by assigning time each week for an accomplished and energized young Deaf instructor to teach Sign Language to our students. Beginning with the basics of ASL and moving on to Nepali Sign Language. It’s a lifetime experience for our students as they learn to be taught by a Deaf teacher, in silence. All about cultivating love and respect for diversity! But there’s more. Sign Language enhances cognitive skills and spatial sense as recognized the world over.</ParagraphBlack>
                                            </RightSectionContainer>
                                            <RightSectionContainer>
                                                <HeadingManropeBig>3</HeadingManropeBig>
                                                <ParagraphBlack>Enhancing globally acceptable social skills conducive to successful networking and relationships, no matter where one is on the globe. We teach the importance and art of verbal communication. We discuss with our students the need to develop spiritual health, the fourth dimension of wellness, as once recommended by a WHO Executive Board.</ParagraphBlack>
                                            </RightSectionContainer>
                                        </RightSectionPromotion>

                                    </FlexWrapper>

                                </Container>
                            </SectionGradientYellow>
                        </Content>

                        {/* Fourth tab testimonial */}
                        <Content active={activeTab === 4}>

                            <SectionTab3>
                                <StudentSafetyContainer>
                                    <HeadingTealSmall>Reviews</HeadingTealSmall>
                                    <HeadingTeal>Testimonials</HeadingTeal>
                                    <ButtonTabHeaderContainer>
                                        <ButtonTabHeaders>
                                            <Tab className='ParentButton' active={activeTabButton === 1} onClick={() => handleTabButtonClick(1)}>
                                                Parents
                                            </Tab>
                                            <Tab className='StudentButton' active={activeTabButton === 2} onClick={() => handleTabButtonClick(2)}>
                                                Student
                                            </Tab>

                                        </ButtonTabHeaders>

                                    </ButtonTabHeaderContainer>


                                </StudentSafetyContainer>
                            </SectionTab3>
                            <TabContent>
                                <Content active={activeTabButton === 1}>
                                    <TestimonialCard>
                                        <ProfileContainer>
                                            <ProfileImage src={Testimonial1} alt="Parent 1" />

                                        </ProfileContainer>
                                        <Name>Dr. Gunakar Bhatta</Name>
                                        <Title>Parent</Title>
                                        <Position>Executive Director, and Spokesperson of Nepal Rastra Bank</Position>
                                        <Bio>Dr. Gunakar Bhatta has served as an Economic Advisor to the Finance Ministry of Nepal. He has long experience in teaching and research as well. He taught at Tribhuvan University and Kathmandu University. He holds an MBA with gold medal from Tribhuvan University, Master’s in economics from Williams College, US and PhD in economics from Wayne State University, US. He is honored with Nepal Bidhya Bhushan Padak “Ka” and Mahendra Bidhya Bhushan Padak “Kha”.</Bio>
                                        <Testimonial>Over the past four years, Imperial World School has played an important role in shaping the intellectual and extracurricular development of my child, Shatakshi Bhatta. Right before COVID-19 started in 2020, my wife and I were searching for a school that would provide personal attention and care to students. We were looking for a school that would provide our daughter with a personalized learning platform, helping her to succeed. One day, Shatakshi came up to me and showed the website of Imperial World School. After watching videos of all the activities and events organized by the school, our daughter was eager to learn more about it. This prompted us to visit the school in-person and talk to the administration. While at the school premises, witnessing the earthquake-resistant building and a well-thought-out curriculum led us to immediately enroll our daughter.
                                            <LineBreak />
                                            Shatakshi started her journey at IWS in 5th grade and we’ve witnessed her develop significantly both personally and academically in the last four years. Today, our daughter is in 9thgrade, and I am very happy with the progress she has made. Whether it has been about seeing her succeed academically, engage in presentations or debate during exit points, We are happy to witness many milestones she has achieved. This would not have been possible without the huge support of the IWS family and staff members who work so hard to ensure student success.</Testimonial>
                                    </TestimonialCard>

                                    <TestimonialCard>
                                        <ProfileContainer>
                                            <ProfileImage src={Testimonial2} alt="Parent 1" />

                                        </ProfileContainer>
                                        <Name>Dr. Nripesh and Uni</Name>
                                        <Title>Parent</Title>
                                        <Position>Paediatric Surgeon by Profession and Finance Manager in the developing sector</Position>
                                        <Bio>Dr. Gunakar Bhatta has served as an Economic Advisor to the Finance Ministry of Nepal. He has long experience in teaching and research as well. He taught at Tribhuvan University and Kathmandu University. He holds an MBA with gold medal from Tribhuvan University, Master’s in economics from Williams College, US and PhD in economics from Wayne State University, US. He is honored with Nepal Bidhya Bhushan Padak “Ka” and Mahendra Bidhya Bhushan Padak “Kha”.</Bio>
                                        <Testimonial>Namaste and greetings dear parents and friends.
                                            Myself Dr. Nripesh Rajbhandari and my wife Uni Rajbhandari, parents of Nur Udaya (Grade VII) and Nova Rajbhandari (Grade V) are delighted to share our exceptional experience with the school.
                                            <LineBreak />

                                            Both of our children have been studying in Imperial world school since grade Nursery.
                                            The academic program (IPC, IMYC and national curriculum) and holistic approach of IWS are truly commendable. These curriculums are designed to challenge students while nurturing their individual strengths and interests. My children are thriving academically, benefiting from the school’s emphasis on critical thinking, problem-solving, and creativity.
                                            They educate students on other important topics such as hygiene, nutrition, mental health, sexual health and the importance of regular physical activity.

                                            <LineBreak />

                                            There is availability of counseling services for students to address issues such as stress, anxiety, bullying, and peer pressure.
                                            The teachers and school management are extraordinary. Their dedication and passion for teaching and learning are evident. They foster an environment where learning is engaging and enjoyable, ensuring that the children are not only well-educated but also excited about their studies.
                                            <LineBreak />

                                            Every child is different, their mental thinking and learning capabilities are different needing well structured teaching techniques that can be ensured only through well trained and keen faculty personnel. The personalised attention each student receives is remarkable and has made a significant impact on my children’s academic journey.
                                            <LineBreak />

                                            As a paediatric surgeon, I appreciate the school’s commitment to health and well-being. The emphasis on physical education, mental health, and balanced nutrition aligns perfectly with my professional values. The school’s proactive approach to creating a safe and supportive environment gives us great peace of mind.
                                            <LineBreak />

                                            Extracurricular opportunities at IWS are diverse and enriching. My children have discovered new talents and passions through various clubs and activities, ranging from sports, music and arts to science and technology. These programs have greatly contributed to their personal development and social skills.
                                            <LineBreak />

                                            The school hosts numerous events that bring families together, fostering a strong network of support and friendship. There are regular PTMs and other sessions where parents can discuss their child’s progress with the faculty and device new strategies which can nullify miscommunication and solve problems; sharing the common interests together for the benefit of the child. This inclusive atmosphere has made our experience even more rewarding.
                                            <LineBreak />

                                            The overall organisational structure, architecture and environment of the school is safe and welcoming. The classrooms are well ventilated and well lit, in addition they are well prepared for emergency/disaster management creating a safe place away from home.
                                            In conclusion, Imperial world school has surpassed our expectations in every way. It provides an exceptional education while promoting the well-being and holistic development of its students.
                                            <LineBreak />

                                            As both parents and professionals, we are grateful for the positive impact this school has had on our children and our family.
                                            I We would like to personally thank the school’s principal Mrs. Junita Karki and chairman Mr. Kumar Karki for this opportunity to share our thoughts and experiences.
                                            <LineBreak />

                                            Have a good day
                                            <LineBreak />
                                        </Testimonial>
                                    </TestimonialCard>

                                    <TestimonialCard>
                                        <ProfileContainer>
                                            <ProfileImage src={Testimonial3} alt="Parent 1" />

                                        </ProfileContainer>
                                        <Name>Asst. Prof. Binod Prasad Pant</Name>
                                        <Title>Parent</Title>
                                        <Position>Head, Department of STEAM Education Kathmandu University School of Education</Position>
                                        <Testimonial>
                                            As a parent, one of my worries was finding the appropriate school in the Kathmandu Valley. Working in the field of education for several years, I had few expectations as a parent. I became satisfied when I enrolled my child in Imperial World School. The most important reason is the learning environment the school created, with enough open spaces, a resourceful library, labs, and a dedicated team of teachers and administrators. Next, the careful blend of academic activities and the exposure to several extracurricular activities while my child got enough exposure to sports and other activities. The incorporation of the International Primary Curriculum (IPC) added another value to integrated teaching and sustainable development. The frequent communication with parents and individual care for the students enhanced my child’s learning. At one of the family functions, one of my relatives asked my son, “Babu, do you want to study at other schools if your parents enroll you? He came to me and said, “Baba, please, I will study at IWS until grade 10. I don’t want to change the school.” This also shows my child’s emotional attachment to the school family.

                                            <LineBreak />
                                            In the coming days, I wish the school all the best.
                                        </Testimonial>

                                    </TestimonialCard>

                                    <TestimonialCard>
                                        <ProfileContainer>
                                            <ProfileImage src={Testimonial4} alt="Parent 1" />

                                        </ProfileContainer>
                                        <Name>Dr. Shovana Bajracharya</Name>
                                        <Title>Parent</Title>
                                        <Position>Senior political analyst of professional translator, Embassy of Japan in Nepal</Position>
                                        <Testimonial>

                                            I have known IWS since its inception and long before my son was born. The second time I encountered IWS was after the 2015 earthquake. The school seemed severely affected by the earthquake and was on the verge of a crisis. The third time was during its rebuilding phase. And they did indeed “build back better”. Both my husband and I were very impressed by the zeal, persistence, resilience and relentless determination of the founder - chairman and his team to establish a good school. As a result , we decided to enroll our son in this school.
                                            <LineBreak />

                                            It has been two years since our son enrolled in IWS and we are immensely grateful for the transformative impact the school environment and dedicated teachers have had on his growth and development. A testament to this progress is his newfound proficiency in Nepali writing. Just two years ago, he struggled to compose even a single paragraph in Nepali. However, we are now bursting with pride as he has independently crafted a captivating story in Nepali , which is published in the short story collection book of IWS this year.
                                            <LineBreak />

                                            This achievement is particularly meaningful to us, as it stems from his early exposure to bed time storytelling, which I shared with him as a part of our family’s history in “Nepal Bhasha”. Through the guidance of his teachers, he skillfully translated his imagination into words, weaving together elements from his greatgranduncle’s real life story into a compelling narrative. We couldn’t be prouder of his creativity and linguistic mastery. It is a true reflection of an exceptional teaching at IWS.
                                            <LineBreak />

                                            Unlike traditional method schools, which typically emphasize rote learning and adherence to established curriculum and rules, IWS focuses on student-centered learning an hands-on experiences that help foster creativity, critical thinking and adaptability. While there may be some challenges in standardization or assessments, the benefits of IWS’s approach are evident in the well-rounded development of its students. If the ambition of the school management, dedication and professionalism of teachers, and collaboration of parents come together, we are confident that IWS will undoubtedly become the alma mater that our children will proudly cherish for a life time !
                                            <LineBreak />

                                            Thank you and wish you all the best !
                                        </Testimonial>
                                    </TestimonialCard>
                                </Content>


                            </TabContent>

                        </Content>
                        {/* Fifth tab Our Teams */}
                        <Content active={activeTab === 5}>
                            <SectionTab3>

                                <StudentSafetyContainer>
                                    <HeadingTealSmall>Our team</HeadingTealSmall>
                                    <HeadingTeal>Meet our skilled & <br />
                                        Experienced tutors</HeadingTeal>
                                    <ParagraphBlack className='paragraphTab'>Meet our top-level faculties with prior experience in the educational field.</ParagraphBlack>

                                    <OurTeamContainer>
                                        <PhotoWrapper>
                                            <OurTeamSliderTrack>
                                                {TeacherInfo.map((info, index) => (
                                                    <Slide key={index}>

                                                        <PhotoContainer>
                                                            <img src={info.img} alt="" />
                                                        </PhotoContainer>
                                                        <SlideTitle>{info.title}</SlideTitle>
                                                        <SlideSubtitle>{info.subtitle}</SlideSubtitle>
                                                    </Slide>
                                                ))}
                                            </OurTeamSliderTrack>

                                        </PhotoWrapper>
                                    </OurTeamContainer>
                                </StudentSafetyContainer>
                            </SectionTab3>

                        </Content>
                    </TabContent>
                </TabWrapper>
            </MainTabWrapper>


            {/* Mission and vision section  */}
            <MVSection>
                <Container>
                    <HeadingWhiteSmall>Mission & Vision</HeadingWhiteSmall>
                    <HeadingWhite>Our Mission & Vision</HeadingWhite>
                    <ParagraphWhite>A holistic centre to produce a well informed and responsible citizen who can represent and establish themselves globally.</ParagraphWhite>
                    <MVWrapper>
                        <MVCard>
                            <MVContent>
                                <MVHeadingContainer>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="55.958" viewBox="0 0 56 55.958">
                                        <g id="mission" transform="translate(-4 -4.021)">
                                            <path id="Path_351" data-name="Path 351" d="M59.68,5.169A1,1,0,0,0,58.81,4.3a38.163,38.163,0,0,0-11.351.32A41.715,41.715,0,0,0,26.166,15.871c-.547.521-1.852,1.753-2.378,2.258a71.021,71.021,0,0,0-8.8,12.42c-.3.612,0,1.077.51,1.519.48.48,1.4,1.389,2.568,2.558,4.469,4.48,9.349,9.358,13.849,13.859l.34.34a1.236,1.236,0,0,0,1.639-.08,63.028,63.028,0,0,0,8.873-6C54.091,33.878,61.682,19.742,59.68,5.169Zm-13,23.482c-4.934,5.044-13.781,1.39-13.7-5.676a8.025,8.025,0,1,1,13.7,5.676Z" fill="#3bbaac" />
                                            <path id="Path_352" data-name="Path 352" d="M5.9,33.227a.486.486,0,0,0,.12-.01l7.164-.869a3.153,3.153,0,0,1,.28-3.187c.5-.919,1.319-2.328,2.458-4.057a79.2,79.2,0,0,1,6.455-8.383l1.439-1.369c-11.975-.119-18.1,10.407-18.895,16.727A.992.992,0,0,0,5.9,33.227Z" fill="#3bbaac" />
                                            <path id="Path_353" data-name="Path 353" d="M44.022,44.3a64.625,64.625,0,0,1-9.163,6.2,3.165,3.165,0,0,1-3.227.3l-.869,7.164a1,1,0,0,0,.989,1.119c1.888-.276,10.393-1.983,14.758-10.162a19.437,19.437,0,0,0,2.128-8.773A58.107,58.107,0,0,1,44.022,44.3Z" fill="#3bbaac" />
                                            <path id="Path_354" data-name="Path 354" d="M26.456,45.857c-2.977-2.985-6.4-6.409-9.333-9.343l-.679,1.379A3,3,0,0,0,17,41.341l5.636,5.636a2.98,2.98,0,0,0,3.447.56l1.379-.679c-.334-.332-.66-.663-1.009-1Z" fill="#3bbaac" />
                                            <path id="Path_355" data-name="Path 355" d="M17.113,46.906C10.209,40.763,4.1,52.879,4,58.917a1.009,1.009,0,0,0,1.059,1.059C11.083,59.875,23.17,53.8,17.113,46.906Z" fill="#3bbaac" />
                                            <path id="Path_356" data-name="Path 356" d="M41,16.95c-7.943.207-7.992,11.822,0,12.051C49,28.766,48.947,17.156,41,16.95Z" fill="#3bbaac" />
                                        </g>
                                    </svg>
                                    <HeadingManrope>Our Mission</HeadingManrope>
                                </MVHeadingContainer>
                                <ParagraphBlack>Our mission is to create a vibrant learning environment where students are encouraged to explore their interests, challenge their limits, and develop a love for lifelong learning. Through innovative teaching methods, a strong focus on character development, and an inclusive approach to education, we prepare our students to succeed in an ever-changing global world.</ParagraphBlack>
                            </MVContent>
                        </MVCard>

                        <MVCard>
                            <MVContent>
                                <MVHeadingContainer>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="73.758" height="43.567" viewBox="0 0 73.758 43.567">
                                        <g id="eye" transform="translate(0 -99.781)">
                                            <g id="XMLID_1992_" transform="translate(0 99.781)">
                                                <path id="XMLID_2003_" d="M183.178,171.415a10.953,10.953,0,1,0,10.953,10.953A10.95,10.95,0,0,0,183.178,171.415Zm0,7.383a3.577,3.577,0,0,0-3.57,3.57,1.634,1.634,0,0,1-3.268,0,6.85,6.85,0,0,1,6.838-6.838,1.634,1.634,0,0,1,0,3.268Z" transform="translate(-146.17 -160.578)" fill="#3bbaac" />
                                                <path id="XMLID_2012_" d="M72.816,119.1c-34.008-39.122-64.749-8.154-72.071.424a3.072,3.072,0,0,0-.015,3.994c33.569,39.182,64.053,9.773,72.086.56A3.759,3.759,0,0,0,72.816,119.1ZM37.007,137.123a15.552,15.552,0,1,1,15.552-15.552A15.547,15.547,0,0,1,37.007,137.123Z" transform="translate(0 -99.781)" fill="#3bbaac" />
                                            </g>
                                        </g>
                                    </svg>

                                    <HeadingManrope>Our Vision</HeadingManrope>
                                </MVHeadingContainer>
                                <ParagraphBlack>Imperial World School aspires to be a leading educational institution that transforms lives by offering a world-class education that is accessible to all. We envision a school where every child is given the opportunity to excel, explore, and lead in their chosen fields, while also contributing positively to society.</ParagraphBlack>
                            </MVContent>
                        </MVCard>

                    </MVWrapper>
                </Container>
            </MVSection>


            {/* Our Values Section */}
            <Section>
                <Container>
                    <FlexWrapper>
                        {/* Left Section */}
                        <LeftSection>
                            <ImageWrapper>
                                <img src={OurValues} alt="Students Reading" />
                            </ImageWrapper>
                        </LeftSection>

                        <RightSection>
                            <HeadingTealSmall>OUR VALUES</HeadingTealSmall>
                            <HeadingTeal>GROWING, CONNECTING, EXCELLING</HeadingTeal>
                            <ParagraphBlack>At Imperial world School, we are committed to fostering a supportive and dynamic environment where every student can thrive. Our values guide us in everything we do, helping to create a community built on respect, responsibility, and collaboration.</ParagraphBlack>
                            <PriButton>Schedule A Tour</PriButton>
                        </RightSection>
                    </FlexWrapper>

                    {/* Value of the schools */}
                    <FlexWrapper>
                        <ValuesContainer>

                            {/* Respect */}
                            <Values>
                                <ValueHeading>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <g id="Group_312" data-name="Group 312" transform="translate(-400 -5219)">
                                            <g id="Group_308" data-name="Group 308">
                                                <rect id="Rectangle_493" data-name="Rectangle 493" width="24" height="24" rx="5" transform="translate(400 5219)" fill="#2b7c85" />
                                                <path id="check" d="M3.96,8.258A.554.554,0,0,1,3.567,8.1l-3.4-3.4a.556.556,0,0,1,.786-.786L3.96,6.917,10.375.5a.556.556,0,0,1,.786.786L4.353,8.1A.554.554,0,0,1,3.96,8.258Zm0,0" transform="translate(406.339 5226.701)" fill="#fff" />
                                            </g>
                                        </g>
                                    </svg>
                                    <HeadingTealSmall>Respect</HeadingTealSmall>
                                </ValueHeading>
                                <ParagraphBlack>We treat everyone with kindness and respect, embracing differences for an inclusive school culture.</ParagraphBlack>
                            </Values>

                            {/* excellence */}
                            <Values>
                                <ValueHeading>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <g id="Group_312" data-name="Group 312" transform="translate(-400 -5219)">
                                            <g id="Group_308" data-name="Group 308">
                                                <rect id="Rectangle_493" data-name="Rectangle 493" width="24" height="24" rx="5" transform="translate(400 5219)" fill="#2b7c85" />
                                                <path id="check" d="M3.96,8.258A.554.554,0,0,1,3.567,8.1l-3.4-3.4a.556.556,0,0,1,.786-.786L3.96,6.917,10.375.5a.556.556,0,0,1,.786.786L4.353,8.1A.554.554,0,0,1,3.96,8.258Zm0,0" transform="translate(406.339 5226.701)" fill="#fff" />
                                            </g>
                                        </g>
                                    </svg>
                                    <HeadingTealSmall>Excellence</HeadingTealSmall>
                                </ValueHeading>
                                <ParagraphBlack>We strive for excellence in all we do, empowering students to reach their full potential through growth and effort.</ParagraphBlack>
                            </Values>

                            {/* responsibility */}
                            <Values>
                                <ValueHeading>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <g id="Group_312" data-name="Group 312" transform="translate(-400 -5219)">
                                            <g id="Group_308" data-name="Group 308">
                                                <rect id="Rectangle_493" data-name="Rectangle 493" width="24" height="24" rx="5" transform="translate(400 5219)" fill="#2b7c85" />
                                                <path id="check" d="M3.96,8.258A.554.554,0,0,1,3.567,8.1l-3.4-3.4a.556.556,0,0,1,.786-.786L3.96,6.917,10.375.5a.556.556,0,0,1,.786.786L4.353,8.1A.554.554,0,0,1,3.96,8.258Zm0,0" transform="translate(406.339 5226.701)" fill="#fff" />
                                            </g>
                                        </g>
                                    </svg>
                                    <HeadingTealSmall>Responsibility</HeadingTealSmall>
                                </ValueHeading>
                                <ParagraphBlack>We take responsibility for our actions, understanding their impact on ourselves, others, and the world.</ParagraphBlack>
                            </Values>

                            {/* Collaboration */}
                            <Values>
                                <ValueHeading>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <g id="Group_312" data-name="Group 312" transform="translate(-400 -5219)">
                                            <g id="Group_308" data-name="Group 308">
                                                <rect id="Rectangle_493" data-name="Rectangle 493" width="24" height="24" rx="5" transform="translate(400 5219)" fill="#2b7c85" />
                                                <path id="check" d="M3.96,8.258A.554.554,0,0,1,3.567,8.1l-3.4-3.4a.556.556,0,0,1,.786-.786L3.96,6.917,10.375.5a.556.556,0,0,1,.786.786L4.353,8.1A.554.554,0,0,1,3.96,8.258Zm0,0" transform="translate(406.339 5226.701)" fill="#fff" />
                                            </g>
                                        </g>
                                    </svg>
                                    <HeadingTealSmall>Collaboration</HeadingTealSmall>
                                </ValueHeading>
                                <ParagraphBlack>We believe in teamwork, supporting each other to strengthen our community and learning environment.</ParagraphBlack>
                            </Values>
                        </ValuesContainer>
                    </FlexWrapper>
                </Container>
            </Section>

            {/* Awards and certification section */}
            <AwardSection>
                <Container>
                    <HeadingTealSmall>AWARDS & CERTIFICATIONS</HeadingTealSmall>
                    <HeadingTeal>AWARDS & CERTIFICATIONS</HeadingTeal>
                    <div style={{ position: 'relative', padding: "2rem 0" }}>
                        <Slider
                            ref={(slideRef) => setSlider(slideRef)}
                            {...settings}
                        >
                            <div>
                                <img src={Certificate1} alt="Certificate1" />
                            </div>
                            <div>
                                <img src={Certificate2} alt="Certificate2" />
                            </div>
                            <div>
                                <img src={Certificate3} alt="Certificate3" />
                            </div>
                            {/* Add more slides as needed */}
                        </Slider>

                        {/* Alternative navigation */}
                        <CustomButtonGroup
                            previous={() => slider?.slickPrev()}
                            next={() => slider?.slickNext()}
                        />
                    </div>
                </Container>
            </AwardSection>
        </div>
    )
}

export default Aboutus