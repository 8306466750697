

//import styled components
import styled from 'styled-components';

//images
import { FaVideo } from "react-icons/fa";

//styled components
export const TopBarContainer = styled.div`
  font-family: 'Manrope', sans-serif;
  display: flex;
  padding: 0.2rem 3rem;
  background-color: #2B7C85;

  flex-direction: column;
`;

export const TopBarWrapper = styled.div`
  color: white;
  padding: 0.5rem 1rem;
`;

export const TopBarContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;

export const TopBarLink = styled.a`
  font-size: 0.875rem;
  color: white;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  
  &:hover {
    color: #B2F5EA;
  }

  svg{
    margin-right:0.5rem;
  }
`;

export const Divider = styled.span`
  background-color: #45B5AA;
  height: 1rem;
  width: 1px;
`;

export const SocialIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
`;

export const SocialIcon = styled.a`
  background-color: #45B5AA; /* Icon background color */
  border-radius: 50%; /* Circular design */
  text-decoration: none; /* Remove underline */
  transition: all 0.3s ease; /* Smooth hover effect */

 img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem; /* Standardized icon size */
  height: 1.5rem;
  }
`;

export const VideoIcon = styled(FaVideo)`
  color: #F6E05E;
  margin: 0 0.5rem;
`;

// Header Bar Styled Components
export const NavBar = styled.nav`
  background-color: #FFFCF2;
  padding: 0.75rem 3rem;
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
`;

export const NavContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0.5rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.img`
  @media (min-width: 768px) {
    height: 4rem;
  }
  width: auto;
`;

export const NavLinks = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
`;

export const NavLink = styled.a`
  color: black;
  text-decoration: none;
`;

export const PhoneButton = styled.button`
  background-color: #38B2AC;
  color: white;
  padding: 0.75rem;
  border-radius: 2rem;
  border: none;
  font-weight: bold;
  cursor: pointer;
  font-size:1.12rem;
  font-family:Manrope,sans-serif;
  &:hover {
    background-color: #319795;
  }
`;