import styled from "styled-components";

export const AdmissionSection = styled.section`
  flex: 1;
  display: flex;
  padding: 0.75rem 3rem;
  min-height: 40vh;
  align-items:center;
  background-color: #FFFCF2;
`;

export const TealSection = styled.section`
  flex: 1;
  padding: 0.75rem 3rem;
  max-height: 100vh;
  background-color: #2B7C85;
`;

export const Wrapper = styled.div`
display:flex;
flex-direction:row ;
padding:2rem 0;
gap:5rem;
`;

export const AdmissionSteps = styled.section`
align-items: left;
text-align: left;
h1{
    padding:0.5rem 0;
    text-align: left;
    
}
p{
    padding:0;
}
`;

export const Information=styled.div`

`
export const AdmissionSection2=styled.div`
  flex: 1;
  display:flex;
  align-items: center;
  padding: 0.75rem 3rem;
  min-height: 100vh;
  background-color: white;
`
export const InformationContainer=styled.div`
display: flex;
flex-direction: row;
gap:2rem;
`
export const WhenToApply=styled.div`
h1{
  text-align: left;
  color:#2B7C85;
}
p{
  text-align: left;
  padding:1rem 0;
  margin:0;
}
`


export const Contact=styled.div`
display: flex;
align-items: center;
text-align: left;
gap:1rem;
`

export const WrapperText=styled.div`
padding:2rem 0;
`
export const ThingsToKnow=styled.div`
h1{
    text-align: left;
    color:#2B7C85;
}
p{
    text-align:left;
    padding:0;
    margin:0;
}
`

export const ThingsToKnowContainer=styled.div`
display:flex;
flex-direction: column;
text-align: left;
`

export const Steps=styled.div`
display: flex;
gap:1rem;
flex:column;
align-items: start;
padding:1rem 0;

p{
  margin:0;
  padding: 0;
  text-align: left;
}
`

export const Table=styled.table`
font-family: "Manrope", sans-serif;
padding: 2rem 0;
th{
background-color:#F8CB3C;
padding:1rem 0.5rem ;

}
td{
  width: 25rem;
  background-color: #F9F0E7;
  padding:1rem 0.5rem ;
}
`

export const Email=styled.div`
p{
  text-align: left;
padding:0;
margin:0;
}
`