//art photos
import Art1 from './pages/Assets/Photo/Art1.jpg';
import Art2 from './pages/Assets/Photo/Art2.jpg';
import Art3 from './pages/Assets/Photo/Art3.jpg';
import Art4 from './pages/Assets/Photo/Art4.jpg';
import Art5 from './pages/Assets/Photo/Art5.jpg';
import Art6 from './pages/Assets/Photo/Art6.jpg';
import Art7 from './pages/Assets/Photo/Art7.jpg';
import Art8 from './pages/Assets/Photo/Art8.jpg';
import Art9 from './pages/Assets/Photo/Art9.jpg';

//Media Photo
import Media1 from './pages/Assets/Photo/Media1.jpg';
import Media2 from './pages/Assets/Photo/Media2.jpg';
import Media3 from './pages/Assets/Photo/Media3.jpg';
import Media4 from './pages/Assets/Photo/Media4.jpg';
import Media5 from './pages/Assets/Photo/Media5.jpg';
import Media6 from './pages/Assets/Photo/Media6.jpg';
import Media7 from './pages/Assets/Photo/Media7.jpg';
import Media8 from './pages/Assets/Photo/Media8.jpg';
import Media9 from './pages/Assets/Photo/Media9.jpg';


export const StudentCreativityInfo = [
    {
        title: "An Alien Vision",
        subtitle: "Kavya Nahata",
        src:Art1,
    },
    {
        title: "Minimalism Art",
        subtitle: "Paliza Shrestha",
        src:Art2,
    },
    {
        title: "The Village",
        subtitle: "Simon Khadka",
        src:Art3,
    },
    {
        title: "Symbolic Art",
        subtitle: "Yuvna Gurung",
        src:Art4,
    },
    {
        title: "Expressionism Art ",
        subtitle: "Shubran Khadka",
        src:Art5,
    },
    {
        title: "Night View",
        subtitle: "Ashna KC",
        src:Art6,
    },
    {
        title: "The Village",
        subtitle: "Simon Khadka",
        src:Art7,
    },
    {
        title: "Climate Change",
        subtitle: "Shreya Sakya",
        src:Art8,
    },
    {
        title: "The Village",
        subtitle: "Simon Khadka",
        src:Art9,
    },
]


//Media Info
export const MediaInfo = [
    {
        title: "Samriddhi Talk with Junita Karki, Principal, Imperial World School",
        src:Media1,
    },
    {
        title: "इम्पेरियल वल्र्ड स्कुल, फरक पहिचानका साथमा स्थापित विद्यालय Imperial World School",
        src:Media2,
    },
    {
        title: "Imperial World School’s Parents Day Report",
        src:Media3,
    },
    {
        title: "Questions, Curiosity and Experience Regarding Leadership and Politics",
        src:Media4,
    },
    {
        title: "Pi Day 2024 | Imperial World School",
    
        src:Media5,
    },
    {
        title: "वातावरणमा रमाउदै ईम्पेरियल स्कुलका वालबालिकाहरू",
        src:Media6,
    },
    {
        title: "इम्पेरियल वर्ल्ड स्कुलमा ‘कला र साहित्य महोत्सव’",
        src:Media7,
    },
    {
        title: "इम्पेरियल वल्र्ड स्कुलमा रक्तदान कार्यक्रम",
        src:Media8,
    },
    {
        title: "Imperial World School’s Kathmandu Art & Literature Festival 2080",
        src:Media9,
    },
]

//faq
//array for faq section
export const faqs = [
    {
        title: "How can I apply for admission?",
        content: "Applications can be submitted online through our admissions portal. Please check the deadlines for each grade level.",
    },
    {
        title: "Is there a registration fee?",
        content: "Applications can be submitted online through our admissions portal. Please check the deadlines for each grade level.",
    },
    {
        title: "What curriculum does the school follow?",
        content: "Applications can be submitted online through our admissions portal. Please check the deadlines for each grade level.",
    },
    {
        title: "What extracurricular activities are available?",
        content: "Applications can be submitted online through our admissions portal. Please check the deadlines for each grade level.",
    },
    {
        title: "What measures does the school take for student safety?",
        content: "Applications can be submitted online through our admissions portal. Please check the deadlines for each grade level.",
    },
    {
        title: "How can I contact my child’s teacher?",
        content: "Applications can be submitted online through our admissions portal. Please check the deadlines for each grade level.",
    },
];