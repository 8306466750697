import styled from 'styled-components';


export const PrimaryButton = styled.button`

  background-color: #F8CB3C; /*yellowAccent */
  color: #2B7C85; /*Dark teal */
  border-radius: 5rem;
  opacity: 1;
  padding: 1rem 1.5rem;
  font-weight: bold;
  margin: 1rem 0;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Manrope', sans-serif;
`;

export const SecondaryButton = styled.button`
  background-color: #2B7C85; /*Dark teal */
  color: #F8CB3C; /*yellowAccent */
  border-radius: 5rem;
  opacity: 1;
  
  padding: 1rem;
  font-weight: bold;
  margin: 1rem 0;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  font-family: 'Manrope', sans-serif;
`;

export const TealWhiteButton = styled.button`
  background-color: #3BBAAC; /*Dark teal */
  color: white;
  border-radius: 5rem;
  opacity: 1;
  padding: 1rem;
  font-weight: bold;
  margin: 1rem 0;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  font-family: 'Manrope', sans-serif;
`;

export const MonocromaticButtons = styled.button`
background-color: #F9F0E7; 
  color: black;
  display:block;
  border-radius: 0.6rem;
  opacity: 1;
  font-weight: bold;
  margin: 1rem 0;
  padding:0.5rem 0.5rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Manrope', sans-serif;
`


export const DarkTealButtons = styled.button`
background-color: #2B7C85; 
  color: white;
 border-radius: 5rem;
  opacity: 1;
  padding: 1rem 1.5rem;
  font-weight: bold;
  margin: 1rem 0;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  font-family: 'Manrope', sans-serif;
`
