import React, { useRef, useState } from 'react';

//components
import BannerComponent from '../../component/Banner/Banner';

//photos
import IPBanner from '../Assets/Photo/NationalCurriculumBanner.jpg';
import LearningImg1 from '../Assets/Photo/SliderPhoto/ExtraCurriculum.jpg';
import LearningImg2 from '../Assets/Photo/SliderPhoto/Facilities.jpg';
import LearningImg3 from '../Assets/Photo/SliderPhoto/Pedagogy.jpg';

//style components
import { HeadingTeal, HeadingTealSmall, Section } from '../../global/global';
import { ImageWrapperLearning, LearningContainer, LearningTab, Message } from './LIWSStyles';
import { LineBreak, ParagraphBlack } from '../Homepage/StyledComponents';

const LIWS: React.FC = () => {
    const [activeTab, setActiveTab] = useState<string>('Overview'); // State for the active tab

    // Refs for each section
    const overviewRef = useRef<HTMLDivElement>(null);
    const extracurricularRef = useRef<HTMLDivElement>(null);
    const facilitiesRef = useRef<HTMLDivElement>(null);
    const pedagogyRef = useRef<HTMLDivElement>(null);

    // Scroll to the target section and set active tab
    const handleTabClick = (tabName: string, ref: React.RefObject<HTMLDivElement>) => {
        setActiveTab(tabName); // Update the active tab state
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    // Banner components breadcrumbs
    const breadcrumbs = [
        { label: "Home", link: "/" },
        { label: "Life At IWS" }
    ];

    // Data for sections
    const sections = [
        {
            name: 'Extracurriculars',
            ref: extracurricularRef,
            title: 'Extra-curriculum',
            description: (
                <>
                    We encourage all students to get involved in cultural pursuits as well as sports.
                    Some of the extra-curricular activities at IWS include:
                    <ul style={{ listStyleType: "none" }}>
                        <li>{"> Swimming"}</li>
                        <li>{"> Futsal"}</li>
                        <li>{"> Cricsal"}</li>
                        <li>{"> Taekwondo"}</li>
                        <li>{"> Arts & Crafts"}</li>
                        <li>{"> Music"}</li>
                        <li>{"> Yoga"}</li>
                        <li>{"> Different forms of dance, as well as other sports and games"}</li>
                    </ul>
                </>
            ),
            image: LearningImg1,
        },
        {
            name: 'Facilities',
            ref: facilitiesRef,
            title: 'Facilities',
            description: (
                <>
                    Our facilities include:
                    <ul style={{ listStyleType: "none" }}>
                        <li>{"> Lush, green, open spaces for children to play outside"}</li>
                        <li>{"> Indoor and outdoor play options, ranging from educational toys to sports equipment"}</li>
                        <li>{"> An around-the-clock security guard"}</li>
                        <li>{"> School nurse and counselor"}</li>
                        <li>{"> Science laboratory"}</li>
                        <li>{"> A cafeteria that serves nutritious and delicious lunch & snacks, with a menu designed by a dietician"}</li>
                        <li>{"> Physical library with over 10,000 books staffed by a full-time librarian"}</li>
                        <li>{"> Transport to and from school with van escorts to ensure safe and smooth operations"}</li>
                        <li>{"> Brightly painted classrooms with large windows that provide plenty of natural light"}</li>
                        <li>{"> Classrooms equipped with child-sized furniture and reading corners kitted out with age-appropriate reading materials selected by a literacy expert"}</li>
                    </ul>
                </>
            ),
            image: LearningImg2,
        },
        {
            name: 'Pedagogy',
            ref: pedagogyRef,
            title: 'Pedagogy',
            description: (
                <>
                    At IWS we support senior students by implementing the most effective, research-based learning strategies that are recognized globally.
                    Our teachers, who regularly undergo training to stay up-to-date with the latest methods, aim to deliver the international curricula in such a way that each child is stimulated and given the personal care and attention they need to meet their learning objectives.
                </>
            ),
            image: LearningImg3,
        },
    ];

    return (
        <>
            <BannerComponent
                image={IPBanner}
                title="Life At IWS"
                breadcrumbs={breadcrumbs}
            />

            <Section>
                <LearningContainer>
                    <LearningTab>
                        <button
                            onClick={() => handleTabClick('Overview', overviewRef)}
                            style={{
                                backgroundColor: activeTab === 'Overview' ? '#2B7C85' : '#F9F0E7',
                                color: activeTab === 'Overview' ? 'white' : 'black',
                            }}
                        >
                            Overview
                        </button>
                        {sections.map((section) => (
                            <button
                                key={section.name}
                                onClick={() => handleTabClick(section.name, section.ref)}
                                style={{
                                    backgroundColor: activeTab === section.name ? '#2B7C85' : '#F9F0E7',
                                    color: activeTab === section.name ? 'white' : 'black',
                                }}
                            >
                                {section.name}
                            </button>
                        ))}
                    </LearningTab>
                    <Message>
                        {/* Overview Section */}
                        <div ref={overviewRef}>
                            <HeadingTealSmall>Life At IWS</HeadingTealSmall>
                            <HeadingTeal>Learning at Imperial World School</HeadingTeal>
                            <ParagraphBlack>
                                Non-academic activities are widely considered to be a factor that influences a child’s ability to excel. But more than that, we at IWS feel that offering students a variety of fun activities helps bring out their love of learning and makes them genuinely happy to go to school.
                                <LineBreak />
                                Younger students take part in these activities as regular classes, whereas the seniors participate in their chosen activities through clubs.
                            </ParagraphBlack>
                        </div>

                        {/* Dynamic Sections */}
                        {sections.map((section, index) => (
                            <div key={index} ref={section.ref}>
                                <HeadingTeal>{section.title}</HeadingTeal>
                                <ParagraphBlack>{section.description}</ParagraphBlack>
                                <ImageWrapperLearning>
                                    <img src={section.image} alt={section.title} />
                                </ImageWrapperLearning>
                            </div>
                        ))}
                    </Message>
                </LearningContainer>
            </Section>
        </>
    );
};

export default LIWS;
