import styled from "styled-components";

//style components css
export const Section = styled.section`
  flex: 1;

  padding: 0.75rem 3rem;
  min-height: 100vh;
  background-color: white;
`;

export const TealContainer = styled.p`
  background-color: #2B7C85;
  font-family: 'Manrope', sans-serif;
  padding:3rem 2rem;
  margin:2rem 0;
  color:white;
  border-radius:1.25rem;
`;

export const FAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  details {
    position: relative; 
  }
`;

export const Summary = styled.summary`
  display: flex;
  justify-content: space-between; /* Pushes text and arrow to opposite sides */
  align-items: center;
  cursor: pointer;
  list-style: none; /* Removes default marker */
  font-size: 1.25rem;
  font-weight: bold;
  padding: 0.5rem 1rem;

  &::after {
    content: "▼"; /* Down arrow symbol */
    font-size: 1rem;
    color: white;
    margin-left: auto; /* Pushes the arrow to the right */
    transition: transform 0.3s ease; /* Smooth arrow rotation */
  }

  &:focus {
    outline: none; /* Remove default focus outline */
  }

  details[open] &::after {
    transform: rotate(180deg); /* Rotate arrow when expanded */
  }
`;


export const Divider = styled.div`
  height: 2rem;
  width:5px;
  background-color: white;
  margin: 0rem 1rem 0 0;
  transition:0.3s ease;


/* Change color when the parent details element is open */
  details[open] & {
    background-color: #f8cb3c;
    transition: background-color 0.3s ease;
  }

`;

export const FAQContent = styled.div`
  font-size: 1rem;
  text-align:left;
  padding: 0.5rem 1rem;

  p {
    margin: 0;
  }
`;

// Rotate the arrow when the FAQ is expanded
FAQContainer.defaultProps = {
  className: "faq-container",
};

Summary.defaultProps = {
  className: "faq-summary",
}


export const BottomDivider = styled.div`
  height: 0.5px;
  width:auto ;
  margin:0 1rem;
  
  background-color: #FFFFFF;
`;

