import React from 'react'
import { Container, HeadingTeal, HeadingTealSmall, ParagraphBlack } from '../../global/global'
import {Section, BottomDivider, Divider, FAQContainer, FAQContent, Summary, TealContainer } from './FaqStyles'
import { faqs } from '../../data'

const Faq = () => {
    return (
        <>
            <Section>
                <Container>
                    <HeadingTealSmall>FAQ</HeadingTealSmall>
                    <HeadingTeal>Frequently Asked Questions</HeadingTeal>
                    <ParagraphBlack>Common questions that we get.</ParagraphBlack>

                    <TealContainer>
                        <FAQContainer>

                            {faqs.map((info, index) => (
                                <>
                                    <details>
                                        <Summary>
                                            <Divider />{info.title}
                                        </Summary>
                                        <FAQContent>
                                            <p>
                                                {info.content}
                                            </p>
                                        </FAQContent>
                                    </details>
                                    <BottomDivider />
                                </>
                            ))}
                        </FAQContainer>
                    </TealContainer>
                </Container>
            </Section>
        </>
    )
}

export default Faq