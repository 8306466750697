import styled from 'styled-components'


export const CreativitySection = styled.section`
display:flex;
flex-direction:column;
padding: 0.75rem 3rem;
background-color: #FFFCF2;
`

export const CreativityContainer=styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

export const PhotoWrapper = styled.div`
  position: flex;
  width: 100%;
  padding: 1rem 0;
  flex-direction: row;
`;



export const Slide = styled.div`
  flex:1;  // Makes each slide take up roughly 1/3 of the container
  padding:1rem 0;
  `;


export const PhotoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: transform 0.2s;
  overflow: hidden; /* Prevents child elements or ::before from overflowing the container */
`

  export const SlideTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  text-align:left;
  margin-bottom: 0.5rem;
  font-family: 'Manrope', sans-serif;

`;

export const SlideSubtitle = styled.p`
  color: #666;
  text-align:left;
  font-family: 'Manrope', sans-serif;
  font-size: 1rem;
`;

export const SliderTrack = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns by default */
  grid-gap: 1rem; /* Space between rows and columns */
  justify-content: center; /* Center the grid horizontally */
  align-items: start; /* Align items to the top of each cell */

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for medium screens */
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr); /* 1 column for small screens */
  }
`;
