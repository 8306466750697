
import styled from 'styled-components';
//style components css
export const Section = styled.section`
  flex: 1;
  display:flex;
  align-items: center;
  padding: 0.75rem 3rem;
  min-height: 100vh;
  background-color: white;
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 0rem;
`;

export const Heading = styled.h1`
  font-size: 2.25rem;
  font-family: 'Luxenta', sans-serif;
  font-weight: bold;
  color: #2B7C85;
  line-height: 1.2;

  @media (min-width: 768px) {
    font-size: 3rem;
  }

  @media (min-width: 1024px) {
    font-size: 3.75rem;
  }
`;

export const HeadingTealSmall = styled.h1`
  color: #2B7C85;
  font-size: 18px;
  line-height: 45px;
  font-family: 'luxenta', sans-serif;
  font-weight: bold;
`;


export const HeadingTeal = styled.h1`
  color: #2B7C85;
  font-size: 40px;
  font-family: 'luxenta', sans-serif;
  font-weight: bold;
text-transform: uppercase;
  margin-bottom: 1rem;
`;

export const OffWhiteSection = styled(Section)`
  background-color: #FFFCF2;
  display:flex;
  align-items:center;
  min-height: 100vh;
  width:100%;
`;


export const ParagraphBlack = styled.p`
  color: #080808;
  font-size: 17.5px;
  text-align: center;
  font-family: 'Manrope', sans-serif;
  margin: 1rem auto 1rem auto;
  padding:0 3rem;
`;


export const HeadingManrope = styled.h1`
color: white;
  font-size: 1.6rem;
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
  text-align:center;
`

export const LineBreak = styled.span`
margin:1rem 0;
display:block;
`

export const ParagraphWhite = styled.p`
  font-size: 17.5px;
  color: #FFFFFF;
  text-align: left;
  padding:1rem 0;
  font-family: 'Manrope', sans-serif;
`;


export const HeadingWhiteSmall = styled.h1`
  color: #FFFFFF;
  font-size: 18px;
  font-family: 'luxenta', sans-serif;
  font-weight: bold;
`;

export const HeadingWhite = styled.h1`
  color: #FFFFFF;
  font-size: 40px;
  padding:0.5rem 0;
  font-family: 'luxenta', sans-serif;
  font-weight: bold;
  line-height:1.2;
  text-transform:uppercase;
`;


export const Image = styled.img`
  width: 100%;
  height: auto;
`;


export const PhotoContainer = styled.div`
  position: relative; /* Ensures that the ::before pseudo-element is positioned relative to this container */
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: transform 0.2s;
  overflow: hidden; /* Prevents child elements or ::before from overflowing the container */

  &::before {
    content: "";
    position: absolute;
    inset: 0; /* Fills the container completely */
   background: transparent linear-gradient(0deg, #F8CB3D 0%, #FFFFFF22 100%) 0% 0% no-repeat padding-box;
    z-index: 1; /* Ensures gradient overlay is above the image */
    opacity: 0.39;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative; /* Ensures image respects container's bounds */
    z-index: 0; /* Ensures the image is below the gradient */
  }

  &:hover {
    transform: scale(1.02); /* Adds a subtle scaling effect on hover */
  }
`;


//Our team section styles
export const PhotoWrapper = styled.div`
  position: flex;
  width: 100%;
  padding: 1rem 0;
  flex-direction: row;
`;

export const SliderTrack = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  transition: transform 500ms ease-out;
`;

export const Slide = styled.div`
  flex:1;  // Makes each slide take up roughly 1/3 of the container
  text-align: center;
`;

export const TealSection = styled(Section)`
  background-color: #2B7C85;
  display:flex;
  align-items:center;
`;



export const SlideTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  text-align:left;
  margin-bottom: 0.5rem;
  font-family: 'Manrope', sans-serif;

`;

export const SlideSubtitle = styled.p`
  color: #666;
  text-align:left;
  font-family: 'Manrope', sans-serif;
  font-size: 1rem;
`;
