import React from "react";

// Styled Components
import {
  BannerContainer,
  BackgroundImage,
  Overlay,
  ContentContainer,
  Heading,
  BreadcrumbNav
} from "./BannerStyles";

// Define the types for props
interface Breadcrumb {
  label: string;
  link?: string; // `link` is optional
}

interface BannerProps {
  image: string; // URL of the background image
  title: string; // Title of the banner
  breadcrumbs: Breadcrumb[]; // Array of breadcrumbs
}

const Banner: React.FC<BannerProps> = ({ image, title, breadcrumbs }) => {
  return (
    <BannerContainer>
    
      {/* Background Image */}
      <BackgroundImage src={image} alt={title} />

      <Overlay />

      <ContentContainer>
        <Heading>{title}</Heading>

        {/* Breadcrumb Navigation */}
        <BreadcrumbNav>
          <ol>
            {breadcrumbs.map((crumb, index) => (
              <React.Fragment key={index}>
                <li>
                  {crumb.link ? (
                    <a href={crumb.link}>{crumb.label}</a>
                  ) : (
                    <span>{crumb.label}</span>
                  )}
                </li>
                {index < breadcrumbs.length - 1 && <span>›</span>}
              </React.Fragment>
            ))}
          </ol>
        </BreadcrumbNav>
      </ContentContainer>
    </BannerContainer>
  );
};

export default Banner;
