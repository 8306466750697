import React from 'react'
import { Container, HeadingManrope, HeadingTeal, Section } from '../Homepage/StyledComponents'
import { HeadingBlack } from '../AboutUs/AboutusStyles'

const NotFound = () => {
  return (
    <Section style={{display:"flex",alignItems:"center",minHeight:"60vh"}}>
        <Container>
            <HeadingTeal >Page<br/>Not Found ......</HeadingTeal>
        </Container>
    </Section>
  )
}

export default NotFound