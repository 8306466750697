import React from 'react'
//components banner
import BannerComponent from '../../../component/Banner/Banner'


//photos
import BannerPhoto from '../../../pages/Assets/Photo/NationalCurriculumBanner.jpg' //banner photo
import NationalCurrFeature from '../../../pages/Assets/Photo/NationalCurFeature.png' //national curriculum feature photo

//styles imported from curricula styles
import { Container, CurriculaSection } from '../CurriculaStyles';
import { HeadingTeal, HeadingTealSmall, OffWhiteSection } from '../../../global/global';
import { HeadingManrope, LineBreak, ParagraphBlack } from '../../Homepage/StyledComponents';
import { FlexWrapper, ImageWrapper, LeftSection, RightSection } from '../../AboutUs/AboutusStyles';
import { SectionYellow, TextWrapper } from './NationalCurriculumStyles';

const NationalCurriculum = () => {

    //banner components BreadCrumbs
    const breadcrumbs = [
        { label: "Home", link: "/" },
        { label: "Curricula", link: "/curricula" },
        { label: "National Curriculum" }
    ];

    return (
        <>
            <BannerComponent
                image={BannerPhoto}
                title="National Curriculum"
                breadcrumbs={breadcrumbs}
            />

            <CurriculaSection>
                <Container>
                    <HeadingTealSmall>CURRICULUM</HeadingTealSmall>
                    <HeadingTeal>National Curriculum</HeadingTeal>
                    <ParagraphBlack>Imperial World School’s academic programs are built upon the solid foundation provided by the national curriculum of Nepal.</ParagraphBlack>
                    <HeadingManrope style={{ fontSize: "1.25rem", color: "#2B7C85", padding: "2rem 0" }}>This curriculum, developed by the esteemed Curriculum Development Center, Ministry of Education, Science & Technology, Government of Nepal, serves as the cornerstone of our educational approach, guiding our teaching practices and shaping the learning experiences of our students.</HeadingManrope>
                </Container>
            </CurriculaSection>

            <SectionYellow>
                <Container>
                    <FlexWrapper>


                        <RightSection>
                            <HeadingTeal>Key Features of the National Curriculum</HeadingTeal>

                            <TextWrapper>
                                <HeadingManrope style={{ color: "#080808" }}>Comprehensive Subject Coverage</HeadingManrope>
                                <ParagraphBlack>Imperial World School’s curriculum encompasses a diverse array of subjects including language and literature, mathematics, science, social studies, ICT and physical education. This comprehensive approach ensures that students receive a well-rounded education that addresses all facets of their development.</ParagraphBlack>

                                <HeadingManrope style={{ color: "#080808", padding: "1rem 0 0 0" }}>
                                    Progressive Learning Outcomes
                                </HeadingManrope>
                                <ParagraphBlack>We set clear and attainable learning goals for each grade level ensuring that students progress steadily and develop essential skills and competencies across all subject areas.</ParagraphBlack>

                                <HeadingManrope style={{ color: "#080808", padding: "1rem 0 0 0" }}>
                                    Inquiry—Based Learning
                                </HeadingManrope>
                                <ParagraphBlack>
                                    Our curriculum promote active engagement and critical inquiry, encouraging students to explore concepts, ask questions, and make connections between their learning and the world around them.
                                    <LineBreak />
                                    Our foundation lies in the robust framework provided by The national curriculum ensuring that students receive a strong grounding in essential subjects and skills as mandated by educational authorities. We adhere to the standards set forth by the Curriculum Development Center, Nepal ensuring that our students are well-prepared to excel in local and national examinations.
                                </ParagraphBlack>
                            </TextWrapper>
                        </RightSection>

                        {/* Left Section */}
                        <LeftSection>
                            <ImageWrapper>
                                <img src={NationalCurrFeature} alt="A girl reading a book" />
                            </ImageWrapper>
                        </LeftSection>
                    </FlexWrapper>

                </Container>
            </SectionYellow>

        </>
    )
}

export default NationalCurriculum