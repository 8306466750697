import styled from "styled-components";

export const LIWSFlexWrapper = styled.div`
`;

export const LearningContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  padding: 5rem 0;
  max-width: 1200px;
  margin: 0 auto;

  /* Prevent children from growing beyond their flex-basis */
  flex-wrap: nowrap;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items on smaller screens */
    gap: 1rem;
  }
`;

export const Message = styled.div`
  flex: 1; 
  padding: 2rem;
  border-radius: 8px;

  h1,p{
    text-align: left;
    padding:0;
  }
  
  @media (max-width: 768px) {
    padding: 1rem; /* Reduce padding on smaller screens */
  }
`;

export const LearningTab = styled.div`
  flex: 0 0 20%;
  width: 100%; /* Ensure it takes up the remaining space */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;

  button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    background-color:#F9F0E7;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #2B7C85; /* Darker blue on hover */
      color:white;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Focus ring for accessibility */
    }
    

    @media (max-width: 768px) {
      padding: 0.5rem 1rem; /* Adjust padding for smaller screens */
      font-size: 0.9rem; /* Slightly smaller font size */
    }
  }
`;

export const ImageWrapperLearning=styled.div`
    width: 100%;
    height: 30rem;
    padding:2rem 0;

    img{
        width: 100%;
        height:100%;
        object-fit: cover;
        border-radius:8px;
    }
`
