

import styled from 'styled-components';
// Define interfaces for custom props
interface SliderTrackProps {
    $currentIndex: number;
  }
  
  interface NavButtonProps {
    $position: 'left' | 'right';
  }

// Styles for the component
export const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 2rem 0;
`;

export const SliderTrack = styled.div<SliderTrackProps>`
  display: flex;
  justify-content: center;
  gap: 2rem;
  transition: transform 500ms ease-out;
  transform: translateX(-${props => props.$currentIndex * 33.33}%);
`;

export const Slide = styled.div`
  flex: 0 0 30%;  // Makes each slide take up roughly 1/3 of the container
  text-align: center;
`;

export const VideoContainer = styled.div`
  background: #A8D3CE;  // Mint green color
  border-radius: 1rem;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.02);
  }
`;

export const PlayButton = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    width: 3rem;
    height: 3rem;
    fill: #A8D3CE;
  }
`;

export const SlideTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  text-align:left;
  margin-bottom: 0.5rem;
  font-family: 'Manrope', sans-serif;

`;

export const SlideSubtitle = styled.p`
  color: #666;
  text-align:left;
  font-family: 'Manrope', sans-serif;
  font-size: 1rem;
`;

export const NavButton = styled.button<NavButtonProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
  ${props => props.$position === 'left' ? 'left: 1rem;' : 'right: 1rem;'}
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: none;
  background: #45B4A1;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background: #3a9886;
  }
`;

