
import styled from 'styled-components';

//interface
// Define the type of the `position` prop
interface SlideItemProps {
  position: number; 
}

//styled component
export const Section = styled.section`
  background-color: #2b7c85;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem auto;
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items vertically on small screens */
    text-align: center;
  }
`;

export const LeftSection = styled.div`
  flex: 0.7;
  text-align: left;
  padding: 1rem 0;

  @media (max-width: 768px) {
    text-align: center; /* Center align text for smaller screens */
  }
`;
export const CarouselWrapper = styled.div`
  flex: 2;
  position: relative;
  height: 600px;
  display: flex;
  gap: 2rem;
  overflow: hidden;
`;

export const SlideItem = styled.div<SlideItemProps>`
  position: relative;
  flex: 1;
  height: 100%;
  opacity: 1;
  transform: translateX(${props => props.position}%);
  transition: all 0.5s ease-in-out;
  border-radius: 0.5rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0; /* Cover the entire image */
  left: 0;
  right: 0;
  bottom: 0; /* Ensure overlay covers the whole image */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent); /* Dark gradient */
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Position text at the bottom */
  z-index: 2; /* Ensure overlay is on top of the image */
  padding: 1rem;
  border-radius:0.5rem;
  
  h3 {
  font-family:luxenta,sans serif;
    text-align: left;
    color: #fff;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  p {
  font-family:'Manrope', sans-serif;
    color: #fff;
    font-size: 17.5px;
    text-align: left;
  }
`;

export const Group = styled.div`
  display: flex;
  gap:2rem;
  align-items: center;

`;


export const ButtonGroup = styled.div`
  display: flex;
  gap: 0.7rem;
`;

export const CarouselButton = styled.button`
  color: #fff;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;


  svg {
    
    height: 2rem;
    width: 2rem;
  }
`;
