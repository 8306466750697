import styled from "styled-components";

export const FlexWrapperIMYC=styled.div`
 display: grid;
 grid-template-columns: auto auto; /* Three columns */
 text-align:left;
`;
export const CapabilitiesIMYC=styled.div`
padding:1rem 0;
p{
    text-align: left;
}
`;
export const Wrapper=styled.div`
display: flex;
text-align:left;
align-items: center;
`;