import React from 'react'
import { Container, Form, HeadingTealSmall, ParagraphWhite, RightSection, ScheduleHeadingManrope, ScheduleImageWrapper, ScheduleLeftSection, Section } from '../Homepage/StyledComponents'
import { PriButton } from '../../component/Buttons/Buttons'
import { HeadingTeal, OffWhiteSection } from '../../global/global'
import { Contact, ContactContainer, ParagraphBlack, LeftSection, ScheduleFlexWrapper, SocialIcon, SocialIconsWrapper } from './ContactusStyles'
import BannerComponent from '../../component/Banner/Banner'

//svgs
import Twitter from '../../pages/Assets/Photo/Svg/twitter.svg'
import Facebook from '../../pages/Assets/Photo/Svg/facebook.svg'
import Instagram from '../../pages/Assets/Photo/Svg/instagram.svg'
import Linkedin from '../../pages/Assets/Photo/Svg/linkedin.svg'

//photos
import IPBanner from '../Assets/Photo/Contactus.jpg'

const Contactus = () => {
    const breadcrumbs = [
        { label: "Home", link: "/" },
        { label: "Contact us" }
    ];
    return (
        <>
            <BannerComponent
                image={IPBanner}
                title="Contact us"
                breadcrumbs={breadcrumbs}
            />
            <OffWhiteSection>
                <Container>
                    <ScheduleFlexWrapper>
                        {/* Left Section */}
                        <LeftSection>
                            <HeadingTealSmall>Contact us</HeadingTealSmall>
                            <HeadingTeal>For inquiries, please use the contact details below.</HeadingTeal>
                            <ParagraphBlack style={{ textAlign: "left", padding: "0" }}>For inquiries, we encourage you to reach out using the contact details provided below. Our team is here to assist you with any questions, guide you through the process, or offer additional information about our offerings. </ParagraphBlack>

                            <ContactContainer>
                                <Contact>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27.254" height="19.163" viewBox="0 0 27.254 19.163">
                                        <path id="Path_357" data-name="Path 357" d="M24.859,76H2.4A2.4,2.4,0,0,0,0,78.4V92.768a2.4,2.4,0,0,0,2.4,2.4H24.859a2.4,2.4,0,0,0,2.4-2.4V78.4A2.4,2.4,0,0,0,24.859,76Zm-.368,1.6L14.246,87.875a.932.932,0,0,1-1.237,0L2.763,77.6ZM1.6,92.474V78.689l6.87,6.893Zm1.166,1.092,6.831-6.854L11.877,89a2.527,2.527,0,0,0,3.5,0l2.283-2.29,6.831,6.853Zm22.894-1.092-6.87-6.893,6.87-6.893Z" transform="translate(0 -76)" fill="#2b7c85" />
                                    </svg>
                                   <a href="https://mail.google.com/mail/u/0/?view=cm&fs=1&to=info@iwschool.edu.np" target='_blank'><ParagraphBlack>info@iwschool.edu.np</ParagraphBlack></a> 
                                </Contact>
                                <Contact>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27.253" height="27.293" viewBox="0 0 27.253 27.293">
                                        <g id="phone-call" transform="translate(-0.344 0)">
                                            <g id="Group_289" data-name="Group 289" transform="translate(0.344 0)">
                                                <path id="Path_358" data-name="Path 358" d="M21.895,42.734a2.684,2.684,0,0,0-1.947-.893,2.776,2.776,0,0,0-1.97.887l-1.82,1.815c-.15-.081-.3-.156-.444-.23-.207-.1-.4-.2-.57-.305a19.784,19.784,0,0,1-4.741-4.32,11.672,11.672,0,0,1-1.555-2.454c.472-.432.91-.881,1.336-1.313.161-.161.323-.328.484-.49a2.643,2.643,0,0,0,0-3.986L9.1,29.871c-.179-.179-.363-.363-.536-.547-.346-.357-.709-.726-1.083-1.071a2.727,2.727,0,0,0-1.93-.847,2.822,2.822,0,0,0-1.959.847l-.012.012L1.618,30.24a4.214,4.214,0,0,0-1.25,2.679,10.1,10.1,0,0,0,.737,4.274,24.8,24.8,0,0,0,4.407,7.35,27.108,27.108,0,0,0,9.026,7.068,14.064,14.064,0,0,0,5.069,1.5c.121.006.248.012.363.012a4.341,4.341,0,0,0,3.324-1.429c.006-.012.017-.017.023-.029a13.1,13.1,0,0,1,1.008-1.043c.248-.236.5-.484.749-.743a2.874,2.874,0,0,0,.87-1.993,2.768,2.768,0,0,0-.887-1.976ZM23.957,48.8c-.006,0-.006.006,0,0-.225.242-.455.461-.7.7a15.147,15.147,0,0,0-1.112,1.152,2.777,2.777,0,0,1-2.166.916c-.086,0-.179,0-.265-.006a12.494,12.494,0,0,1-4.493-1.348,25.588,25.588,0,0,1-8.5-6.659,23.386,23.386,0,0,1-4.147-6.907,8.225,8.225,0,0,1-.645-3.606,2.644,2.644,0,0,1,.795-1.711L4.683,29.37a1.309,1.309,0,0,1,.876-.409,1.232,1.232,0,0,1,.841.4l.017.017c.351.328.685.668,1.037,1.031.179.184.363.369.547.559l1.573,1.573a1.1,1.1,0,0,1,0,1.786c-.167.167-.328.334-.5.5-.484.5-.945.956-1.446,1.406-.012.012-.023.017-.029.029a1.175,1.175,0,0,0-.3,1.308l.017.052a12.625,12.625,0,0,0,1.861,3.036l.006.006A21.142,21.142,0,0,0,14.3,45.315a7.864,7.864,0,0,0,.709.386c.207.1.4.2.57.305.023.012.046.029.069.04a1.249,1.249,0,0,0,.57.144,1.232,1.232,0,0,0,.876-.4l1.97-1.97a1.3,1.3,0,0,1,.87-.432,1.173,1.173,0,0,1,.829.42l.012.012L23.951,47A1.139,1.139,0,0,1,23.957,48.8Z" transform="translate(-0.344 -25.827)" fill="#2b7c85" />
                                                <path id="Path_359" data-name="Path 359" d="M245.584,87.468a7.413,7.413,0,0,1,6.037,6.037.773.773,0,0,0,.766.645,1.03,1.03,0,0,0,.132-.012.778.778,0,0,0,.639-.9,8.964,8.964,0,0,0-7.3-7.3.782.782,0,0,0-.9.634A.769.769,0,0,0,245.584,87.468Z" transform="translate(-230.854 -80.976)" fill="#2b7c85" />
                                                <path id="Path_360" data-name="Path 360" d="M261.706,12.04A14.761,14.761,0,0,0,249.679.012a.777.777,0,1,0-.253,1.532,13.184,13.184,0,0,1,10.749,10.749.773.773,0,0,0,.766.645,1.028,1.028,0,0,0,.132-.012A.763.763,0,0,0,261.706,12.04Z" transform="translate(-234.465 0)" fill="#2b7c85" />
                                            </g>
                                        </g>
                                    </svg>


                                    <ParagraphBlack>01 - 4370331</ParagraphBlack>

                                </Contact>
                                <Contact>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27.254" height="31.009" viewBox="0 0 27.254 31.009">
                                        <g id="location_1_" data-name="location (1)" transform="translate(-31)">
                                            <g id="Group_291" data-name="Group 291" transform="translate(31)">
                                                <g id="Group_290" data-name="Group 290" transform="translate(0)">
                                                    <path id="Path_361" data-name="Path 361" d="M49.8,20.488c3.3-5.178,2.885-4.532,2.98-4.667a9.919,9.919,0,0,0,1.836-5.767,9.993,9.993,0,1,0-18.1,5.841l2.927,4.593C36.32,20.969,31,22.4,31,25.558c0,1.15.751,2.79,4.328,4.067a28.674,28.674,0,0,0,9.3,1.383c6.542,0,13.627-1.846,13.627-5.451C58.254,22.4,52.94,20.97,49.8,20.488ZM38.041,14.9l-.031-.046a8.176,8.176,0,1,1,13.3-.092c-.088.115.369-.594-6.684,10.473Zm6.586,14.3c-7.146,0-11.81-2.1-11.81-3.634,0-1.031,2.4-2.725,7.707-3.386l3.337,5.237a.908.908,0,0,0,1.532,0l3.337-5.237c5.31.661,7.707,2.355,7.707,3.386C56.437,27.078,51.815,29.192,44.627,29.192Z" transform="translate(-31)" fill="#2b7c85" />
                                                </g>
                                            </g>
                                            <g id="Group_293" data-name="Group 293" transform="translate(40.085 5.511)">
                                                <g id="Group_292" data-name="Group 292">
                                                    <path id="Path_362" data-name="Path 362" d="M185.542,91a4.542,4.542,0,1,0,4.542,4.542A4.547,4.547,0,0,0,185.542,91Zm0,7.268a2.725,2.725,0,1,1,2.725-2.725A2.728,2.728,0,0,1,185.542,98.268Z" transform="translate(-181 -91)" fill="#2b7c85" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>

                                    <a target="_blank" href='https://maps.app.goo.gl/5Qe7x2R2qbWX7ACL6'><ParagraphBlack>Hattigauda, Budhanilkantha,
                                        Kathmandu Nepal 44600</ParagraphBlack></a>

                                </Contact>

                                <Contact>
                                    <SocialIconsWrapper>
                                        <SocialIcon href="#">
                                            <img src={Linkedin} alt="LinkedIn" />
                                        </SocialIcon>
                                        <SocialIcon href="#">
                                            <img src={Twitter} alt="Twitter" />
                                        </SocialIcon>
                                        <SocialIcon href="#">
                                            <img src={Facebook} alt="Facebook" />
                                        </SocialIcon>
                                        <SocialIcon href="#">
                                            <img src={Instagram} alt="Instagram" />
                                        </SocialIcon>
                                    </SocialIconsWrapper>
                                </Contact>
                            </ContactContainer>
                        </LeftSection>

                        {/* Right Section */}
                        <RightSection>
                            <ScheduleHeadingManrope>Schedule a Tour</ScheduleHeadingManrope>
                            <ParagraphWhite>
                                Visit the school perimeters and know everything that you want to know for your child.
                            </ParagraphWhite>
                            <Form>
                                <span>
                                    <input type="text" placeholder="Name" />
                                    <input type="text" placeholder="Name" />
                                </span>
                                <input type="email" placeholder="Email" />
                                <textarea style={{ fontFamily: "Manrope",fontWeight:500 }} placeholder="Message" />
                                <PriButton>Submit Now</PriButton>
                            </Form>
                        </RightSection>
                    </ScheduleFlexWrapper>
                </Container>
            </OffWhiteSection>
            <Section style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Container>
                    <div>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d801.6512701511793!2d85.34899192598571!3d27.758808172995405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb197332f8815b%3A0xf7f4ca9a817bf987!2sImperial%20World%20School!5e0!3m2!1sen!2snp!4v1732598845784!5m2!1sen!2snp"
                            width="1200"
                            height="550"
                            style={{ border: 0, borderRadius: '1rem' }}
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </Container>
            </Section>
        </>
    )
}

export default Contactus