import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link } from "react-router-dom";
import FooterLogo from "../../pages/Assets/Photo/SliderPhoto/ExtraCurriculum.jpg";

import { ParagraphWhite } from "../../pages/Homepage/StyledComponents";
import {
  Copyright,
  Email,
  EmailGroup,
  FooterColumn,
  FooterContainer,
  FooterContent,
  FooterLink,
  FooterSection,
  Location,
  LocationGroup,
  LogoSection,
  ParagraphYellow,
  Phone,
  PhoneGroup,
  PhotoGallery,
  PhotoSection,
  PhotoTitle,
  SocialIcon,
  SocialIconsWrapper,
  LogoImageContainer,
} from "./FooterStyles";

// slider images
import Slider1 from "../../pages/Assets/Photo/SliderPhoto/ExtraCurriculum.jpg";
import Slider2 from "../../pages/Assets/Photo/SliderPhoto/Facilities.jpg";
import Slider3 from "../../pages/Assets/Photo/SliderPhoto/Pedagogy.jpg";
import Slider4 from "../../pages/Assets/Photo/StudentBanner.jpg";
import Slider5 from "../../pages/Assets/Photo/Contactus.jpg";
import Slider6 from "../../pages/Assets/Photo/PlaceAtIws1.png";

// footer logo
import footerImg from "../../pages/Assets/Photo/FooterLogo.png";

const photos = [
  { src: Slider1, alt: "School Memory 1" },
  { src: Slider2, alt: "School Memory 2" },
  { src: Slider3, alt: "School Memory 3" },
  { src: Slider4, alt: "School Memory 4" },
  { src: Slider5, alt: "School Memory 5" },
  { src: Slider6, alt: "School Memory 5" },
];

//svgs
const Facebook = () => {
  return (
    <svg
      id="facebook-app-symbol"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-5 -3 15 15"
    >
      <path
        id="f_1_"
        d="M40.312,8.966V4.876h1.372l.206-1.594H40.312V2.265c0-.461.128-.776.79-.776h.843V.062A11.437,11.437,0,0,0,40.716,0a1.92,1.92,0,0,0-2.05,2.107V3.282H37.29V4.876h1.376v4.09Z"
        transform="translate(-37.29)"
        fill="black"
      />
    </svg>
  );
};

const Twitter = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-6 -5 20 20">
      <path
        id="twitter"
        d="M18.47,3.843,21.777,0h-.783L18.122,3.337,15.83,0H13.185l3.467,5.046-3.467,4.03h.784L17,5.552l2.421,3.524h2.645l-3.6-5.233ZM17.4,5.091l-.351-.5-2.8-4h1.2L17.71,3.817l.351.5,2.932,4.194h-1.2L17.4,5.091Z"
        transform="translate(-13.185)"
        fill="black"
      />
    </svg>
  );
};

const Instagram = () => {
  return (
    <svg
      id="instagram"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-5 -5 20 20"
    >
      <path
        id="Path_303"
        data-name="Path 303"
        d="M9.909,2.789a3.466,3.466,0,0,0-.22-1.151A2.43,2.43,0,0,0,8.3.25,3.475,3.475,0,0,0,7.15.03C6.642.006,6.481,0,5.193,0S3.744.006,3.238.028a3.467,3.467,0,0,0-1.151.22A2.315,2.315,0,0,0,1.246.8,2.335,2.335,0,0,0,.7,1.636a3.475,3.475,0,0,0-.22,1.151c-.024.508-.03.669-.03,1.957S.455,6.193.477,6.7A3.466,3.466,0,0,0,.7,7.85,2.43,2.43,0,0,0,2.086,9.238a3.476,3.476,0,0,0,1.151.22c.506.022.667.028,1.955.028S6.64,9.48,7.146,9.458A3.465,3.465,0,0,0,8.3,9.238,2.426,2.426,0,0,0,9.685,7.85,3.478,3.478,0,0,0,9.906,6.7c.022-.506.028-.667.028-1.955S9.931,3.295,9.909,2.789ZM9.055,6.662a2.6,2.6,0,0,1-.163.88,1.574,1.574,0,0,1-.9.9,2.609,2.609,0,0,1-.88.163c-.5.022-.65.028-1.916.028s-1.418-.006-1.916-.028a2.6,2.6,0,0,1-.88-.163,1.46,1.46,0,0,1-.545-.354A1.475,1.475,0,0,1,1.5,7.544a2.61,2.61,0,0,1-.163-.88c-.022-.5-.028-.65-.028-1.916s.006-1.418.028-1.916a2.6,2.6,0,0,1,.163-.88,1.442,1.442,0,0,1,.356-.545A1.473,1.473,0,0,1,2.4,1.053a2.611,2.611,0,0,1,.88-.163c.5-.022.65-.028,1.916-.028S6.614.867,7.113.889a2.6,2.6,0,0,1,.88.163,1.459,1.459,0,0,1,.545.354,1.474,1.474,0,0,1,.354.545,2.611,2.611,0,0,1,.163.88c.022.5.028.65.028,1.916S9.077,6.162,9.055,6.662Zm0,0"
        transform="translate(-0.449 0)"
        fill="black"
      />
      <path
        id="Path_304"
        data-name="Path 304"
        d="M127.386,124.5a2.437,2.437,0,1,0,2.437,2.437A2.437,2.437,0,0,0,127.386,124.5Zm0,4.018a1.581,1.581,0,1,1,1.581-1.581A1.581,1.581,0,0,1,127.386,128.518Zm0,0"
        transform="translate(-122.642 -122.193)"
        fill="black"
      />
      <path
        id="Path_305"
        data-name="Path 305"
        d="M363.587,89.17a.569.569,0,1,1-.569-.569A.569.569,0,0,1,363.587,89.17Zm0,0"
        transform="translate(-355.741 -86.96)"
        fill="black"
      />
    </svg>
  );
};
const LinkedIn = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-7 -6 25 25">
      <path
        id="Path_300"
        data-name="Path 300"
        d="M45.38,42.79V38.778c0-1.972-.425-3.478-2.725-3.478a2.378,2.378,0,0,0-2.15,1.178h-.027v-1H38.3V42.79h2.273V39.162c0-.959.178-1.876,1.356-1.876s1.178,1.082,1.178,1.931v3.56H45.38Z"
        transform="translate(-34.425 -31.836)"
        fill="black"
      />
      <path
        id="Path_301"
        data-name="Path 301"
        d="M11.3,36.6h2.273v7.312H11.3Z"
        transform="translate(-11.122 -32.957)"
        fill="black"
      />
      <path
        id="Path_302"
        data-name="Path 302"
        d="M11.315,10a1.321,1.321,0,1,0,1.315,1.315A1.315,1.315,0,0,0,11.315,10Z"
        transform="translate(-10 -10)"
        fill="black"
      />
    </svg>
  );
};

// Footer Component

const Footer = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 1000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <FooterSection>
      <FooterContainer>
        <PhotoSection>
          <PhotoTitle>OUR FEATURED PHOTOS</PhotoTitle>
          <ParagraphWhite>IWS Memories Gallery</ParagraphWhite>
          <PhotoGallery {...settings}>
            {photos.map((photo, index) => (
              <div key={index}>
                <img src={photo.src} alt={photo.alt} />
              </div>
            ))}
          </PhotoGallery>
        </PhotoSection>

        <FooterContent>
          <LogoSection>
            <LogoImageContainer>
              <img src={footerImg} alt="imperial school " />
            </LogoImageContainer>

            <PhoneGroup>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 27.253 27.293"
              >
                <g id="phone-call" transform="translate(-0.344 0)">
                  <g
                    id="Group_289"
                    data-name="Group 289"
                    transform="translate(0.344 0)"
                  >
                    <path
                      id="Path_358"
                      data-name="Path 358"
                      d="M21.895,42.734a2.684,2.684,0,0,0-1.947-.893,2.776,2.776,0,0,0-1.97.887l-1.82,1.815c-.15-.081-.3-.156-.444-.23-.207-.1-.4-.2-.57-.305a19.784,19.784,0,0,1-4.741-4.32,11.672,11.672,0,0,1-1.555-2.454c.472-.432.91-.881,1.336-1.313.161-.161.323-.328.484-.49a2.643,2.643,0,0,0,0-3.986L9.1,29.871c-.179-.179-.363-.363-.536-.547-.346-.357-.709-.726-1.083-1.071a2.727,2.727,0,0,0-1.93-.847,2.822,2.822,0,0,0-1.959.847l-.012.012L1.618,30.24a4.214,4.214,0,0,0-1.25,2.679,10.1,10.1,0,0,0,.737,4.274,24.8,24.8,0,0,0,4.407,7.35,27.108,27.108,0,0,0,9.026,7.068,14.064,14.064,0,0,0,5.069,1.5c.121.006.248.012.363.012a4.341,4.341,0,0,0,3.324-1.429c.006-.012.017-.017.023-.029a13.1,13.1,0,0,1,1.008-1.043c.248-.236.5-.484.749-.743a2.874,2.874,0,0,0,.87-1.993,2.768,2.768,0,0,0-.887-1.976ZM23.957,48.8c-.006,0-.006.006,0,0-.225.242-.455.461-.7.7a15.147,15.147,0,0,0-1.112,1.152,2.777,2.777,0,0,1-2.166.916c-.086,0-.179,0-.265-.006a12.494,12.494,0,0,1-4.493-1.348,25.588,25.588,0,0,1-8.5-6.659,23.386,23.386,0,0,1-4.147-6.907,8.225,8.225,0,0,1-.645-3.606,2.644,2.644,0,0,1,.795-1.711L4.683,29.37a1.309,1.309,0,0,1,.876-.409,1.232,1.232,0,0,1,.841.4l.017.017c.351.328.685.668,1.037,1.031.179.184.363.369.547.559l1.573,1.573a1.1,1.1,0,0,1,0,1.786c-.167.167-.328.334-.5.5-.484.5-.945.956-1.446,1.406-.012.012-.023.017-.029.029a1.175,1.175,0,0,0-.3,1.308l.017.052a12.625,12.625,0,0,0,1.861,3.036l.006.006A21.142,21.142,0,0,0,14.3,45.315a7.864,7.864,0,0,0,.709.386c.207.1.4.2.57.305.023.012.046.029.069.04a1.249,1.249,0,0,0,.57.144,1.232,1.232,0,0,0,.876-.4l1.97-1.97a1.3,1.3,0,0,1,.87-.432,1.173,1.173,0,0,1,.829.42l.012.012L23.951,47A1.139,1.139,0,0,1,23.957,48.8Z"
                      transform="translate(-0.344 -25.827)"
                      fill="#F8CB3C"
                    />
                    <path
                      id="Path_359"
                      data-name="Path 359"
                      d="M245.584,87.468a7.413,7.413,0,0,1,6.037,6.037.773.773,0,0,0,.766.645,1.03,1.03,0,0,0,.132-.012.778.778,0,0,0,.639-.9,8.964,8.964,0,0,0-7.3-7.3.782.782,0,0,0-.9.634A.769.769,0,0,0,245.584,87.468Z"
                      transform="translate(-230.854 -80.976)"
                      fill="#F8CB3C"
                    />
                    <path
                      id="Path_360"
                      data-name="Path 360"
                      d="M261.706,12.04A14.761,14.761,0,0,0,249.679.012a.777.777,0,1,0-.253,1.532,13.184,13.184,0,0,1,10.749,10.749.773.773,0,0,0,.766.645,1.028,1.028,0,0,0,.132-.012A.763.763,0,0,0,261.706,12.04Z"
                      transform="translate(-234.465 0)"
                      fill="#F8CB3C"
                    />
                  </g>
                </g>
              </svg>
              <Phone>
                <ParagraphWhite>01- 4470331</ParagraphWhite>
                <ParagraphWhite>01- 4472669</ParagraphWhite>
              </Phone>
            </PhoneGroup>

            <EmailGroup>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 27.254 19.163"
              >
                <path
                  id="Path_357"
                  data-name="Path 357"
                  d="M24.859,76H2.4A2.4,2.4,0,0,0,0,78.4V92.768a2.4,2.4,0,0,0,2.4,2.4H24.859a2.4,2.4,0,0,0,2.4-2.4V78.4A2.4,2.4,0,0,0,24.859,76Zm-.368,1.6L14.246,87.875a.932.932,0,0,1-1.237,0L2.763,77.6ZM1.6,92.474V78.689l6.87,6.893Zm1.166,1.092,6.831-6.854L11.877,89a2.527,2.527,0,0,0,3.5,0l2.283-2.29,6.831,6.853Zm22.894-1.092-6.87-6.893,6.87-6.893Z"
                  transform="translate(0 -76)"
                  fill="#F8CB3C"
                />
              </svg>
              <Email>
                <ParagraphWhite>info@iwschool.edu.np</ParagraphWhite>
                <ParagraphWhite>support@iwschool.edu.np</ParagraphWhite>
              </Email>
            </EmailGroup>

            <LocationGroup>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 27.254 31.009"
              >
                <g
                  id="location_1_"
                  data-name="location (1)"
                  transform="translate(-31)"
                >
                  <g
                    id="Group_291"
                    data-name="Group 291"
                    transform="translate(31)"
                  >
                    <g
                      id="Group_290"
                      data-name="Group 290"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_361"
                        data-name="Path 361"
                        d="M49.8,20.488c3.3-5.178,2.885-4.532,2.98-4.667a9.919,9.919,0,0,0,1.836-5.767,9.993,9.993,0,1,0-18.1,5.841l2.927,4.593C36.32,20.969,31,22.4,31,25.558c0,1.15.751,2.79,4.328,4.067a28.674,28.674,0,0,0,9.3,1.383c6.542,0,13.627-1.846,13.627-5.451C58.254,22.4,52.94,20.97,49.8,20.488ZM38.041,14.9l-.031-.046a8.176,8.176,0,1,1,13.3-.092c-.088.115.369-.594-6.684,10.473Zm6.586,14.3c-7.146,0-11.81-2.1-11.81-3.634,0-1.031,2.4-2.725,7.707-3.386l3.337,5.237a.908.908,0,0,0,1.532,0l3.337-5.237c5.31.661,7.707,2.355,7.707,3.386C56.437,27.078,51.815,29.192,44.627,29.192Z"
                        transform="translate(-31)"
                        fill="#F8CB3C"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_293"
                    data-name="Group 293"
                    transform="translate(40.085 5.511)"
                  >
                    <g id="Group_292" data-name="Group 292">
                      <path
                        id="Path_362"
                        data-name="Path 362"
                        d="M185.542,91a4.542,4.542,0,1,0,4.542,4.542A4.547,4.547,0,0,0,185.542,91Zm0,7.268a2.725,2.725,0,1,1,2.725-2.725A2.728,2.728,0,0,1,185.542,98.268Z"
                        transform="translate(-181 -91)"
                        fill="#F8CB3C"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              <Location>
                <ParagraphWhite>Harisiddhi, Kothdada marga,</ParagraphWhite>
                <ParagraphWhite>Kathmandu Nepal-44600</ParagraphWhite>
              </Location>
            </LocationGroup>
          </LogoSection>

          <FooterColumn>
            <ParagraphYellow>SCHOOL</ParagraphYellow>
            <FooterLink to="/about">About Us</FooterLink>
            <FooterLink to="/curriculum">Curricula</FooterLink>
            <FooterLink to="/life">Life at IWS</FooterLink>
            <FooterLink to="/student">Student</FooterLink>
            <FooterLink to="/media">Media</FooterLink>
            <FooterLink to="/contact">Contact</FooterLink>
          </FooterColumn>

          <FooterColumn>
            <ParagraphYellow>USEFUL LINKS</ParagraphYellow>
            <FooterLink to="/registrations">Registrations</FooterLink>
            <FooterLink to="/help">Help Center</FooterLink>
            <FooterLink to="/faqs">FAQs</FooterLink>
            <FooterLink to="/sitemap">Sitemap</FooterLink>
            <FooterLink to="/privacy">Privacy</FooterLink>
          </FooterColumn>

          <FooterColumn>
            <ParagraphYellow>FACILITIES</ParagraphYellow>
            <FooterLink to="/classrooms">Classrooms</FooterLink>
            <FooterLink to="/reading">Reading</FooterLink>
            <FooterLink to="/digital-lab">Digital Lab</FooterLink>
            <FooterLink to="/playground">Playground</FooterLink>
            <FooterLink to="/learning-lab">Learning Lab</FooterLink>
            <FooterLink to="/hall">Multipurpose Hall</FooterLink>
          </FooterColumn>

          <FooterColumn>
            <ParagraphYellow>ADMISSIONS</ParagraphYellow>
            <FooterLink to="/admission-process">Admission Process</FooterLink>
            <FooterLink to="/documents">Required Documents</FooterLink>
          </FooterColumn>
        </FooterContent>
        <SocialIconsWrapper>
          <SocialIcon href="#">
            <LinkedIn />
          </SocialIcon>
          <SocialIcon href="#">
            <Twitter />
          </SocialIcon>
          <SocialIcon href="#">
            <Facebook />
          </SocialIcon>
          <SocialIcon href="#">
            <Instagram />
          </SocialIcon>
        </SocialIconsWrapper>
        <Copyright>
          <ParagraphWhite style={{ textAlign: "center" }}>
            ©2023 Imperial World School. All Right Reserved
          </ParagraphWhite>
        </Copyright>
      </FooterContainer>
    </FooterSection>
  );
};

export default Footer;
