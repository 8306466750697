import React, { useState } from 'react'


//banner component
import BannerComponent from '../../component/Banner/Banner'
import { TWButton } from '../../component/Buttons/Buttons'; //button component

//styled components
//global
import { Container, HeadingTeal, HeadingTealSmall, OffWhiteSection, Section } from '../../global/global';
import { BottomDivider, Content, DividerWrapper, GlowingDivider, HeadingManrope, MainTabWrapper, ParagraphBlack, Tab, TabContent, TabHeaderContainer, TabHeaders, TabWrapper } from '../AboutUs/AboutusStyles';
import { CurriculaBottomDivider, CurriculaContainer, CurriculaDividerWrapper, CurriculaGlowingDivider, CurriculaSection, CurriculaTabContainer, CurriculaTabHeaderContainer, ImageContainer, MaxSection, TextWrapper } from './CurriculaStyles';

//photos 
import BannerPhoto from '../../pages/Assets/Photo/CurriculaBanner.jpg' //banner photo
import NationalCurr from '../../pages/Assets/Photo/StudentSafety1.png' //national curriculum
import InternationalPriCurr from '../../pages/Assets/Photo/StudentSafety2.png' //national curriculum
import InternationalMidCurr from '../../pages/Assets/Photo/StudentSafety3.png' //national curriculum

//Link for the pages (react-router-dom)
import { Link } from 'react-router-dom';

const Curricula: React.FC = () => {

    //banner components BreadCrumbs
    const breadcrumbs = [
        { label: "Home", link: "/" },
        { label: "Curricula" }
    ];

    //tabs 
    const [activeTab, setActiveTab] = useState<number>(1);
    const handleTabClick = (index: number) => {
        setActiveTab(index);
    };



    return (
        <>
            <BannerComponent
                image={BannerPhoto}
                title="Curricula"
                breadcrumbs={breadcrumbs}
            />

            <CurriculaSection>
                <Container>
                    <HeadingTealSmall>Curriculum</HeadingTealSmall>
                    <HeadingTeal>Curriculum</HeadingTeal>
                    <ParagraphBlack>At Imperial world school, we pride ourselves on delivering a comprehensive academic program that combines the richness of the national curriculum prescribed by the Curriculum Development Center, Ministry of Education, Science and Technology, Givernment of Nepal, with the global perspectives offered by renowned International Curricula.</ParagraphBlack>

                </Container>
            </CurriculaSection>

            <MainTabWrapper>
                <TabWrapper>
                    {/* Tab Headers */}
                    <CurriculaTabHeaderContainer>
                        <TabHeaders>
                            <Tab active={activeTab === 1} onClick={() => handleTabClick(1)}>
                                National
                                Curriculum
                            </Tab>
                            <Tab active={activeTab === 2} onClick={() => handleTabClick(2)}>
                                International Primary Curriculum                            </Tab>
                            <Tab active={activeTab === 3} onClick={() => handleTabClick(3)}>
                                International Primary Curriculum                            </Tab>

                        </TabHeaders>
                        {/* Dividers */}
                        <CurriculaDividerWrapper>
                            <CurriculaGlowingDivider position={activeTab - 1} />
                            <CurriculaBottomDivider />
                        </CurriculaDividerWrapper>

                    </CurriculaTabHeaderContainer>

                    {/* Tab Content */}
                    <TabContent>
                        <Content active={activeTab === 1}>
                            <MaxSection>
                                <Container>
                                    <CurriculaContainer>
                                        <ImageContainer>
                                            <img
                                                src={NationalCurr}
                                                alt="National Curriculum"
                                            />
                                        </ImageContainer>
                                        <TextWrapper>
                                            <HeadingManrope>National Curriculum</HeadingManrope>
                                            <ParagraphBlack>From the KG to Class VIII academic program socially upon the solid foundation provided by the National curriculum of Nepal</ParagraphBlack>
                                            <ParagraphBlack>The curriculum, developed by the government Curriculum Development Center, Ministry of Education, incorporates the most modern educational philosophy, guiding our teaching practice and shaping the learning experiences of our students.</ParagraphBlack>
                                            <Link to="/curricula/national-curriculum"><TWButton>EXPLORE MORE</TWButton></Link>
                                        </TextWrapper>
                                    </CurriculaContainer>
                                </Container>
                            </MaxSection>
                        </Content>

                        <Content active={activeTab === 2}>
                            <Section>
                                <Container>
                                    <CurriculaContainer>
                                        <ImageContainer>
                                            <img
                                                src={InternationalPriCurr}
                                                alt="National Curriculum"
                                            />
                                        </ImageContainer>
                                        <TextWrapper>
                                            <HeadingManrope>National Curriculum</HeadingManrope>
                                            <ParagraphBlack>The IPC is now the curriculum of choice for over 1000 national and international schools spanning across more than 90 countries.
</ParagraphBlack>
<ParagraphBlack>
                                                The IPC is one of the fastest-growing curricula in the world today
                                                This curriculum is a comprehensive, theme-based, creative curriculum that teaches 5 to 12-year-olds how to think with an international mindset.
                                                </ParagraphBlack>
                                                <ParagraphBlack>
                                                With a clear process of learning and specific learning goals for each subject, the IPC allows students to develop a personal sense of responsibility for their own learning journey.
                                                </ParagraphBlack>
                                                <Link to="/curricula/ipc"><TWButton>EXPLORE MORE</TWButton></Link>
                                        </TextWrapper>
                                    </CurriculaContainer>
                                </Container>
                            </Section>
                        </Content>


                        <Content active={activeTab === 3}>
                            <Section>
                                <Container>
                                    <CurriculaContainer>
                                        <ImageContainer>
                                            <img
                                                src={InternationalMidCurr}
                                                alt="National Curriculum"
                                            />
                                        </ImageContainer>
                                        <TextWrapper>
                                            <HeadingManrope>International Primary Curriculum</HeadingManrope>
                                            <ParagraphBlack>Besides dealing with topics in a way that sparks curiosity, the curriculum coaches students to be both independent thinkers and interdependent learners who can work in a team.<br />
                                                <b>The IMYC has been carefully curated with the learning needs of young teens in mind.</b><br />
                                                A selection of highly topical and engaging themes encourages students to make connections between what they learn at school and what they see happening in their own lives, communities and the rest of the world. <br />
                                                The IMYC involves active, skills-based learning, while promoting self-reflection that gives students the opportunity to make sense of what they’ve learnt.</ParagraphBlack>
                                                <Link to="/curricula/imyc"><TWButton>EXPLORE MORE</TWButton></Link>
                                        </TextWrapper>
                                    </CurriculaContainer>
                                </Container>
                            </Section>
                        </Content>
                    </TabContent>
                </TabWrapper>
            </MainTabWrapper>
        </>
    )
}

export default Curricula