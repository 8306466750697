import React, { useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { PriButton } from "../../../../component/Buttons/Buttons";
import PlaceAtIws1 from "../../../Assets/Photo/PlaceAtIws1.png";
import PlaceAtIws2 from "../../../Assets/Photo/PlaceAtIws2.png";
import PlaceAtIws3 from "../../../Assets/Photo/PlaceAtIws3.png";
import PlaceAtIws4 from "../../../Assets/Photo/PlaceAtIws4.png";
import { Container, HeadingWhite, HeadingWhiteSmall, LeftSection, ParagraphWhite, Section, TealSection } from "../../StyledComponents";
import { FlexWrapper, CarouselWrapper, SlideItem, Overlay, ButtonGroup, CarouselButton, Group } from "./ExploreOurSchoolStyles";

export default function ExploreOurSchool() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const slides = [
    {
      title: "MIND",
      description: "A strong academic foundation for independent thinking and innovation.",
      image: PlaceAtIws1,
      imageAlt: "Student in science lab",
    },
    {
      title: "BODY",
      description: "Athletic opportunities to develop physical skills and teamwork.",
      image: PlaceAtIws2,
      imageAlt: "Student playing volleyball",
    },
    {
      title: "SPIRIT",
      description: "Comprehensive development of character and values.",
      image: PlaceAtIws3,
      imageAlt: "Students in activity",
    },
    {
      title: "CHARACTER",
      description: "Building integrity and holding each other to the highest standards.",
      image: PlaceAtIws4,
      imageAlt: "Teacher Student",
    },
  ];

  const nextSlide = () => {
    setCurrentIndex(prev => (prev + 1) % (slides.length - 1));
  };

  const prevSlide = () => {
    setCurrentIndex(prev =>
      prev === 0 ? slides.length - 2 : prev - 1
    );
  };

  useEffect(() => {
    if (isHovered) return;
    const autoSlide = setInterval(nextSlide, 5000);
    return () => clearInterval(autoSlide);
  }, [isHovered]);

  const getVisibleSlides = () => {
    // Make sure we don't try to access beyond array bounds
    const firstIndex = currentIndex;
    const secondIndex = (currentIndex + 1) % slides.length;

    return [
      slides[firstIndex],
      slides[secondIndex]
    ];
  };

  return (
    <TealSection>
      <Container>
        <FlexWrapper>
          <LeftSection>
            <HeadingWhiteSmall>EXPLORE OUR SCHOOL</HeadingWhiteSmall>
            <HeadingWhite>
              FIND YOUR
              <br />PLACE AT IWS
            </HeadingWhite>
            <ParagraphWhite>
              Non-academic activities are widely considered to be a factor that
              influences a child's ability to excel. But more than that, we at
              IWS feel that offering students a variety of fun activities helps
              bring out their love of learning and makes them genuinely happy to
              go to school.
            </ParagraphWhite>
            <Group>
              <PriButton src="#">EXPLORE MORE</PriButton>
              <ButtonGroup>
                <CarouselButton onClick={prevSlide} >
                  <svg  id="next" xmlns="http://www.w3.org/2000/svg" width="30.931" height="30.931" viewBox="0 0 30.931 30.931">
                    <g id="Group_93" data-name="Group 93">
                      <g id="Group_92" data-name="Group 92">
                        <path id="Path_69" data-name="Path 69" d="M15.466,0A15.466,15.466,0,1,1,0,15.466,15.466,15.466,0,0,1,15.466,0Zm0,28.354A12.888,12.888,0,1,0,2.578,15.466,12.888,12.888,0,0,0,15.466,28.354Z" fill="#3BBAAC" />
                        <path id="Path_70" data-name="Path 70" d="M198.821,128.377a1.289,1.289,0,0,1,1.823,1.823l-5.533,5.533,5.533,5.533a1.289,1.289,0,0,1-1.823,1.823l-6.444-6.444a1.289,1.289,0,0,1,0-1.823Z" transform="translate(-181.689 -120.267)" fill="#3BBAAC" />
                      </g>
                    </g>
                  </svg>
                  
                </CarouselButton>
                <CarouselButton onClick={nextSlide}>
                <svg id="Group_93" data-name="Group 93" xmlns="http://www.w3.org/2000/svg" width="30.931" height="30.931" viewBox="0 0 30.931 30.931">
            <g id="Group_92" data-name="Group 92">
                <path id="Path_69" data-name="Path 69" d="M15.466,0A15.466,15.466,0,1,0,30.931,15.466,15.466,15.466,0,0,0,15.466,0Zm0,28.354A12.888,12.888,0,1,1,28.354,15.466,12.888,12.888,0,0,1,15.466,28.354Z" fill="#3BBAAC" />
                <path id="Path_70" data-name="Path 70" d="M194.2,128.377a1.289,1.289,0,0,0-1.823,1.823l5.533,5.533-5.533,5.533a1.289,1.289,0,0,0,1.823,1.823l6.444-6.444a1.289,1.289,0,0,0,0-1.823Z" transform="translate(-180.4 -120.267)" fill="#3BBAAC" />
            </g>
        </svg>
                </CarouselButton>
              </ButtonGroup>
            </Group>
          </LeftSection>
          <CarouselWrapper
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {getVisibleSlides().map((slide, index) => (

              <SlideItem
                key={currentIndex + index}
                position={0}
              >
                <img src={slide.image} alt={slide.imageAlt} />
                <Overlay>
                  <h3>{slide.title}</h3>
                  <p>{slide.description}</p>
                </Overlay>
              </SlideItem>
            ))}
          </CarouselWrapper>
        </FlexWrapper>
      </Container>
    </TealSection>
  );
}
