
import styled from "styled-components";

export const MediaSection = styled.section`
display:flex;
flex-direction:column;
padding:2rem 0;
background-color: #FFFCF2;

p{
    text-align:center;
}
`

export const PhotoWrapper = styled.div`
  position: flex;
  flex-direction: row;
`;

export const Slide = styled.div`
  flex:1;  // Makes each slide take up roughly 1/3 of the container
  padding:1rem 0;
  `;


export const PhotoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  object-fit: cover;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: transform 0.2s;
  overflow: hidden; /* Prevents child elements or ::before from overflowing the container */
`

export const SlideTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  text-align:left;
  margin-bottom: 0.5rem;
  font-family: 'Manrope', sans-serif;

`;

export const ContainerWrapper = styled.div`
width: 100%;
margin: 0 auto;
`;


export const SliderTrack = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns by default */
  grid-gap: 1rem; /* Space between rows and columns */
  justify-content: center; /* Center the grid horizontally */
  align-items: start; /* Align items to the top of each cell */

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for medium screens */
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr); /* 1 column for small screens */
  }
`;



// Styled Components
export const MainTabWrapper = styled.div`
width: 100%;
margin: 0 auto;
padding:0 3rem;

`

export const TabHeaderContainer = styled.div`
 padding: 1rem 0rem;
 
`;

export const TabWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  
`;

interface TabProps {
  active: boolean;
}

export const TabContent = styled.div`
  margin-top: 1.5rem;
  width: 100%;
`;

interface ContentProps {
  active: boolean;
}

export const Content = styled.div<ContentProps>`
  display: ${({ active }) => (active ? "block" : "none")};
  color: #555;
  font-size: 1rem;
`;


export const TabHeaders = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between; // Evenly space the tabs
`;

export const Tab = styled.div<TabProps>`
  background: #2B7C85;
  border-radius: 0.5rem;
  padding:1rem 3rem;

  font-weight: bold;
  font-family: "Luxenta", sans-serif;
  color: ${({ active }) => (active ? "#F9F0E7" : "#2B7C85")};
  background-color: ${({ active }) => (active ? "#2B7C85 " : "#F9F0E7")};
  transition: color 0.3s;

`;


export const ButtonTabHeaderContainer = styled.div`
    padding: 3rem 0;
    width: 100%; /* Ensure full width */
    display: flex; /* Ensures alignment can be applied */
    justify-content: center; /* Centers content horizontally */
`;

export const ButtonTabHeaders = styled.div`
 flex-direction: row;
 width:100%;
 display: flex;
align-items: center;
 justify-content: center; 
 gap:1rem;
`;