
import styled from "styled-components";

//import background images 
import MessagePhoto from "../Assets/Photo/MessagePhoto.png";
import BGMissionVision from "../Assets/Photo/BG_MissionAndVision.jpg";

export const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
  gap: 3rem; /* Spacing between the sections */
  justify-content: space-between;
`;

export const LeftSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  max-width: 500px; /* Optional: Limit the image size */
  border-radius: 0.5rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 20rem;
    object-fit: cover;
    border-radius: 0.5rem;
  }
`;

export const RightSection = styled.div`
  flex: 1 1 20%; /* Takes 50% width of the container */
  display: flex;
  flex-direction: column;

  h1{
  text-align :left;
  }
  button{
  display:block;
  align-items:left;
  }
  button{
    text-transform: uppercase;
    width:30%;
    margin:2rem 0;
    font-weight: bold;
  }
`;

export const Form = styled.form`
  margin: 2rem 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Spacing between form elements */

  input {
    padding: 0.75rem;
    border-radius: 0.375rem;
    border: 1px solid #ddd;
    font-size: 1rem;
    flex: 1; /* Ensures inputs take up equal width inside span */

    &:focus {
      outline: none;
      border-color: #1d4ed8;
      box-shadow: 0 0 0 2px rgba(29, 78, 216, 0.5);
    }
  }
textarea{
 border-radius: 0.375rem;
    padding: 0.75rem;
    height:6.5rem;
    font-size: 1rem;
    resize: none; /* Prevents resizing for a cleaner design */

    &:focus {
      outline: none;
    }
  }
  span {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column; /* Stack inputs vertically on smaller screens */
      gap: 1rem; /* Adjust spacing for stacked inputs */
    }
  }

  button {
    padding: 0.75rem;
    border-radius: 0.375rem;
    color: black  ;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
`;

export const HeadingManrope = styled.h1`
color: white;
  font-size: 1.7rem;
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
  text-align:left;
`

export const ParagraphBlack = styled.p`
  color: #080808;
  font-size: 17.5px;
  text-align: left;
  font-family: 'Manrope', sans-serif;
  margin: 1rem auto 0 auto;
`;

//Styles for tab section

// Styled Components
export const MainTabWrapper = styled.div`
width: 100%;
  margin: 0 auto;

`

export const TabHeaderContainer = styled.div`
 padding: 3rem 4rem;
 
`;

export const TabWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

interface TabProps {
  active: boolean;
}

export const TabContent = styled.div`
  margin-top: 1.5rem;
  width: 100%;
`;

interface ContentProps {
  active: boolean;
}

export const Content = styled.div<ContentProps>`
  display: ${({ active }) => (active ? "block" : "none")};
  font-family: "Signika", sans-serif;
  color: #555;
  font-size: 1rem;
`;


export const DividerWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 0.5rem; // Reduced margin for better alignment
`;

export const BottomDivider = styled.div`
  height: 1px; // Thinner base line
  width: 100%;
  background-color: #e5e5e5; // Lighter color for better contrast
  position: absolute;
  bottom: 0;
`;

export const GlowingDivider = styled.div<{ position: number }>`
  height: 3px;
  width: 20%; // Fixed width for each tab section
  background-color: #f8cb3c;
  box-shadow: 0 0 4px rgba(248, 203, 60, 0.4); // Added subtle glow effect
  position: absolute;
  bottom: 0;
  left: ${({ position }) => `${position * 20}%`}; // Simplified position calculation
  transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1); // Smoother transition
`;

export const TabHeaders = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between; // Evenly space the tabs
`;

export const Tab = styled.div<TabProps>`
  flex: 1; 
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  font-size: 1.2rem;
  font-family: "Manrope", sans-serif;
  color: ${({ active }) => (active ? "#080808" : "#767676")};
  transition: color 0.3s;

`;

//message section of Chairman in about us page
export const MessageContainerChairman = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  padding: 2rem 0;
  max-width: 1200px;
  margin: 0 auto;
`;

export const MessageChairman = styled.div`
  flex: 1; /* Will take the remaining space */
  padding: 1rem;
  border-radius: 8px;

  h1 {
    text-align: left;
  }
`;


export const PersonalDescriptionContainer = styled.div`
  flex: 0 0 30%; /* Fixed at 30% width */

  h1{
  text-transform: uppercase;
  padding:1rem 0;
  }
`;

export const MessageParagraph = styled.p`
  margin-top:-1rem;
  padding:1rem;
  background-image: url(${MessagePhoto});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
p{
font-weight:600;
font-size:1.3rem;
}

`
export const MessageDivider = styled.div`
  height: 1px; // Thinner base line
  width: 100%;
  background-color: white; // Lighter color for better contrast
  margin-bottom:1rem;
`;


//message section of Principal in about us page
export const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  padding: 5rem 0;
  max-width: 1200px;
  margin: 0 auto;
`;

export const Message = styled.div`
  flex: 1; 
  padding: 1rem;
  border-radius: 8px;

  h1 {
    text-align: left;
  }
`;

export const MVSection = styled.section`
  background-image: url(${BGMissionVision});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  flex: 1;
  display:flex;
  padding: 0.75rem 3rem;
  min-height: 100vh;
  align-items:center;
  p{
  text-align:center
  }
`

export const MVWrapper = styled.div`
  display:flex;
  align-items:center;
  gap:1.5rem;
  padding:2rem 0;
  flex-direction:row;

  @media (max-width: 768px) {
    flex-direction:column;
  
  }
`;


export const MVCard = styled.div`
  width:38rem;
  height:23.3rem;
  background-color: #FFFCF2;
  border-radius: 0.5rem;
  padding:2rem 1.5rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  
`;

export const MVContent = styled.div`
margin-bottom:2rem;

`;

export const CardTitle = styled.h5`
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  color: #080808;
  text-align:left;
  font-family: 'Manrope', sans-serif;
`;

export const MVHeadingContainer = styled.h1`

  h1{
  text-align:center;
  text-transform: uppercase;
  padding:1rem 0;
  font-weight:bold;
  color:#2B7C85;
  }
`

export const ValuesContainer = styled.div`
  display:flex;
  flex-direction:row;
  padding:3rem 0 0 0;
  gap:2.5rem;

`
export const Values = styled.div`
display:flex;
flex-direction:column;

p{
color:#767676;
font-size:1rem
}

`
export const ValueHeading = styled.div`
display:flex;
flex-direction:row;
align-items:center;
gap:1rem;

h1{
text-align:left;
font-family:"Manrope",sans-serif;
}
`

//Tab section 2

export const StudentSafetyContainer = styled.div`
 max-width: 1200px;
  margin: 0 auto;

  .paragraphTab{
    text-align: center;
  }

`

export const StudentSafetySection = styled.div`
display:flex;
flex-direction:column;
padding:3rem 0 0 0;
gap:1rem;
`

export const StudentSafetySection2 = styled.div`
display:flex;
flex-direction:column;
padding:5rem 0;
`
export const SS_Container = styled.div`
display:flex;
height:21rem;
flex-direction:row;
gap:1rem;
.Image1{
  flex: 0 0 20%; /* Fixed at 30% width */
  }
h1{
  text-align:left;
  font-size: 1.2rem;
  font-weight: bold;
}
`

export const SS_Card = styled.div`
padding:3rem 4rem;
width:100%;
align-content:center;

img{
padding:0 0 1rem 0;
display:flex;
justify-content:left;
}
`

export const SectionTab3 = styled.div`
  flex: 1;
  background-color: white;
`

//tab 3 global citizen

export const GlobalCitizenFlexWrapper = styled.div`
  display: flex;
  justify-content: space-between; /* Space between the text and image */
  align-items: center;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items vertically on small screens */
  }
`;

export const GCLeftSection = styled.div`
  flex: 1.2; /* Take up proportional space */
  text-align: left;
  padding: 1rem 0;
`;


export const GCImageWrapper = styled.div`
  flex: 2; /* Image takes up more space than text */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;


export const PhoneButton = styled.button`
  background-color: #38B2AC;
  color: white;
  margin:0 1rem 0 0;
  border-radius: 5rem;
  opacity: 1;
  padding: 0.75rem 1.5rem;
  font-weight: bold;
  cursor: pointer;
  font-size:1rem;
  font-family:Manrope,sans-serif;
  &:hover {
    background-color: #319795;
  }
`;

//How is our school section 
export const HeadingBlack = styled.h1`
  color: black;
  font-size: 1.2rem;
  font-family: 'Manrope', sans-serif;
  font-weight: bold;

`

export const DescriptionSection = styled.h1`
  text-align:left;
  p{
    font-weight: normal;
  }
`

export const LineBreakBig = styled.span`
  margin:1rem 0;
  display:block;
  padding:1rem;
`
export const SectionGradientYellow = styled.section`
background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 50%, #F8CB3C 100%);


h1{
  text-align:left;
}

p{
  text-align:left;
}
`

export const HeadingManropeBig = styled.h1`
  font-size: 5rem;
  font-family: "Manrope",sans-serif;
  font-weight: normal;
  display: inline-flex;
`

export const RightSectionPromotion = styled.h1`
  flex: 1 1 20%; /* Takes 50% width of the container */
  display: flex;
  flex-direction: column;


`

export const RightSectionContainer = styled.div`
display: flex;
flex-direction: row;
padding: 1rem 0;

  h1{
    font-weight: normal;
    color:#2B7C85;
  }
  p{
    padding:0 0 0 2rem ;
    font-weight: normal;
  }
`
export const ButtonTabHeaderContainer = styled.div`
    padding: 3rem 0;
    width: 100%; /* Ensure full width */
    display: flex; /* Ensures alignment can be applied */
    justify-content: center; /* Centers content horizontally */
`;

export const ButtonTabHeaders = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem; /* Optional: Adds space between buttons */
    align-items: center;

    .ParentButton{
      background: #2B7C85;
      padding:1rem 0;
      border-radius: 0.5rem;
      color:#FFFFFF;
      font-weight: bold;
    }
   
`;
export const TestimonialCard = styled.div`
  max-width: 1200px;
  margin: 8rem auto;
  padding: 5rem 5rem;
  text-align: center;
  background-color: #FFFCF2;
  position: relative; // Add this
  font-family: "Manrope",sans-serif;

`;

export const ProfileContainer = styled.div`
  position: absolute; // Change to absolute
  width: 100%;
  left: 0; // Add this
  top: 0; // Add this
  height: 5rem;
`;

export const ProfileImage = styled.img`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  position: absolute;
  top: 0; // Change this
  left: 50%;
  transform: translate(-50%, -50%); // Change this to move in both X and Y
  z-index: 1; // Add this to ensure image stays on top
`;

// Add margin-top to the Name component to create space for the image
export const Name = styled.h3`
  color: #1e7b85;
  font-size: 24px;
  margin: 1rem 0 0; // Adjust this value as needed
  padding: 10px 0;
`;



export const Title = styled.p`
  color: #767676;
  font-size: 1rem;
  padding:0.5rem 0;
  
`;

export const Position = styled.p`
  color: #333;
  font-weight: 500;
  font-size: 1.12rem;
  margin: 5px 0 20px;
`;

export const Bio = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
  color: #2B7C85;
  font-weight: bold;

`;

export const Testimonial = styled.p`
  color: #333;
  font-size: 16px;
  line-height: 1.6;
`;

//our teams tab 
export const OurTeamContainer = styled.div`
  padding:2rem 0;
`

export const OurTeamSliderTrack = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates 3 equal-width columns */
  grid-gap: 2rem; /* Space between rows and columns */
  justify-content: center; /* Center the grid horizontally */
  align-items: start; /* Align items to the top of each cell */
  width: 100%;
  padding: 1rem;
`;


//awards section
export const AwardSection = styled.section`
  flex: 1;
  padding: 0.75rem 3rem;
  max-height: 100vh;
  display:flex;
  align-items: center;
  padding:2rem 0;
  background-color: #FFFCF2;
`